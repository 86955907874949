import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedFlowChartDataAccessFlowChartModule } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { SharedWorkflowDataAccessWorkflowModule } from '@workflow-admin/shared/workflow/data-access/workflow';

import { ActionsEffects } from './+state/actions/actions.effects';
import { ActionsFacade } from './+state/actions/actions.facade';
import * as fromActions from './+state/actions/actions.reducer';
import { FormsEffects } from './+state/forms/forms.effects';
import { FormsFacade } from './+state/forms/forms.facade';
import * as fromForms from './+state/forms/forms.reducer';
import { InputsEffects } from './+state/inputs/inputs.effects';
import { FormsApiService } from './services';
import { ActionsApiService } from './services/actions-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromForms.FORMS_FEATURE_KEY, fromForms.reducer),
    EffectsModule.forFeature([FormsEffects]),
    StoreModule.forFeature(fromActions.ACTIONS_FEATURE_KEY, fromActions.reducer),
    EffectsModule.forFeature([ActionsEffects]),
    EffectsModule.forFeature([InputsEffects]),
    SharedFlowChartDataAccessFlowChartModule,
    SharedWorkflowDataAccessWorkflowModule
  ],
  providers: [FormsFacade, ActionsFacade, FormsApiService, ActionsApiService],
})
export class SharedInputDataAccessInputModule {}
