<form [formGroup]="questionEditForm" *ngIf="isQuestionSet; else singleQuestion">
  <mat-tab-group>
    <mat-tab label="Algemeen">
      <div class="edit-question">
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Tekst vraag</mat-label>
          <input matInput [formControl]="questionEditForm.get('label').get('text')">
        </mat-form-field>
        <ng-container *ngTemplateOutlet="questionType; context: {form: questionEditForm}"></ng-container>
      </div>
    </mat-tab>
    <mat-tab label="Antwoorden">
      <ng-container *ngTemplateOutlet="valueOptions; context: {form: questionEditForm}"></ng-container>
      <ng-container *ngTemplateOutlet="fieldAttributes; context: {form: questionEditForm}"></ng-container>
    </mat-tab>
  </mat-tab-group>
</form>

<ng-template #singleQuestion>

  <ng-container *ngTemplateOutlet="questionType; context: {form: questionEditForm}"></ng-container>
  <ng-container *ngTemplateOutlet="valueOptions; context: {form: questionEditForm}"></ng-container>
<!--  <mat-tab label="Veldopties">-->
    <ng-container *ngTemplateOutlet="fieldAttributes; context: {form: questionEditForm}"></ng-container>
  <mat-divider style="margin:5px 0;"></mat-divider>
<!--  </mat-tab>-->
</ng-template>


<ng-template #questionType let-form=form>
  <div [formGroup]="form" class="edit-question">
    <mat-form-field appearance="fill" class="edit-question__input">
      <mat-label>Vraagtype</mat-label>
      <mat-select value="option" formControlName="type">
        <mat-option  *ngFor="let questionType of questionTypes" [value]="questionType">{{ questionType | questionTypePipe }}</mat-option>
      </mat-select>
      <fa-icon [icon]="faChevronDown" matSuffix class="edit-question__type-chevron"></fa-icon>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #valueOptions let-form=form>
  <div [formGroup]="form" *ngIf="showAnswersControls; else message">
    <ng-container formArrayName="valueOptions">
      <div class="edit-question" *ngFor="let answer of answersControl.controls; index as i">
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Antwoord</mat-label>
          <input matInput [formControl]="answersControl.controls[i]">
          <fa-icon *ngIf="canAddAnswer" [icon]="faTrashAlt" matSuffix class="edit-question__delete" (click)="deleteAnswer(i)"></fa-icon>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <div class="edit-question__add-answer-container" *ngIf="canAddAnswer">
    <button
      mat-stroked-button
      class="edit-question__add-answer-button "
      (click)="addAnswer()"
      color="primary">
      Nog een antwoord toevoegen
    </button>
  </div>
</ng-template>

<ng-template #fieldAttributes let-form=form>
  <div *ngIf="attributeControl.controls.length">
  <h4 class="attributes-header">Attributen</h4>
  <mat-divider style="margin:5px 0;"></mat-divider>
  </div>

  <div [formGroup]="form" >
    <ng-container formArrayName="fieldAttributes">
      <div class="edit-question" *ngFor="let answer of attributeControl.controls; index as i" style="    flex-direction: row;">
        <input hidden [formControl]="attributeControl.controls[i].controls.uuid">
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Attribuut</mat-label>
          <input matInput [formControl]="attributeControl.controls[i].controls.name">
        </mat-form-field>
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Waarde</mat-label>
          <input matInput [formControl]="attributeControl.controls[i].controls.value">
        </mat-form-field>
          <fa-icon [icon]="faTrashAlt" matSuffix class="edit-question__delete" (click)="deleteFieldAttribute(i)"></fa-icon>

      </div>
    </ng-container>
  </div>

  <div class="edit-question__add-answer-container" style="    justify-content: end;">

    <button *ngIf="!attributeControl.controls.length"
      mat-button (click)="addFieldAttribute()"
      color="primary" class="loadAdvancedButton">
      Geavanceerd
    </button>
    <button *ngIf="attributeControl.controls.length"
      mat-button
      class=""
      style="font-size: 13px;"
      (click)="addFieldAttribute()"
      color="primary">

      Attribuut toevoegen
    </button>
  </div>
</ng-template>

<ng-template #message>
  <div class="edit-question__alternative-message" *ngIf="isQuestionSet">{{ alternativeMessage}}</div>
</ng-template>
