<form [formGroup]="actionEditForm" *ngIf="isQuestionSet; else singleAction">
<!--  <mat-tab-group>-->
<!--    <mat-tab label="Algemeen">-->
<!--      <div class="edit-question">-->
<!--        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">-->
<!--          <mat-label>Tekst vraag</mat-label>-->
<!--          <input matInput [formControl]="actionEditForm.get('label').get('text')">-->
<!--        </mat-form-field>-->
<!--        <ng-container *ngTemplateOutlet="actionType; context: {form: actionEditForm}"></ng-container>-->
<!--      </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Antwoorden">-->
<!--      <ng-container *ngTemplateOutlet="valueOptions; context: {form: actionEditForm}"></ng-container>-->
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->
</form>

<ng-template #singleAction>

  <ng-container *ngTemplateOutlet="actionType; context: {form: actionEditForm}"></ng-container>
<!--  <ng-container *ngTemplateOutlet="valueOptions; context: {form: actionEditForm}"></ng-container>-->
<!--  <mat-tab label="Veldopties">-->
    <ng-container *ngTemplateOutlet="actionAttributes; context: {form: actionEditForm}"></ng-container>
<!--  </mat-tab>-->
</ng-template>


<ng-template #actionType let-form=form>
  <div [formGroup]="form" class="edit-question">
    <mat-form-field appearance="fill" class="edit-question__input">
      <mat-label>Type actie</mat-label>
      <mat-select value="option" formControlName="actionType">
        <mat-option  *ngFor="let questionType of questionTypes" [value]="questionType">{{ questionType | actionTypePipe }}</mat-option>
      </mat-select>
      <fa-icon [icon]="faChevronDown" matSuffix class="edit-question__type-chevron"></fa-icon>
    </mat-form-field>
  </div>
</ng-template>


<ng-template #actionAttributes let-form=form>
  <div *ngIf="attributeControl.controls.length">
    <h4 class="from-attributes-header" style="color: #444;
    font-weight: 400;
    font-size: 13px;
    margin: 10px 0 0 0;">Attributen</h4>
    <mat-divider style="margin:5px 0;"></mat-divider>
  </div>
  <div [formGroup]="form" >
    <ng-container formArrayName="actionAttributes">
      <div class="edit-question" *ngFor="let answer of attributeControl.controls; index as i" style="    flex-direction: row;">
<!--        <input  [formControl]="attributeControl.controls[i].controls.id">-->
        <input hidden [formControl]="attributeControl.controls[i].controls.uuid">
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Attribuut</mat-label>
          <input matInput [formControl]="attributeControl.controls[i].controls.name">
        </mat-form-field>
        <mat-form-field appearance="fill" class="edit-question__input edit-question__answer-input">
          <mat-label>Waarde</mat-label>
          <input matInput [formControl]="attributeControl.controls[i].controls.value">
        </mat-form-field>
          <fa-icon [icon]="faTrashAlt" matSuffix class="edit-question__delete" (click)="deleteFieldAttribute(i)"></fa-icon>

      </div>
    </ng-container>
  </div>

  <div class="edit-question__add-answer-container" style="    justify-content: end;">
    <button *ngIf="!attributeControl.controls.length"
            mat-button
            style="font-weight: 400;
    font-size: 13px;
    color: #ccc;"
            (click)="addFieldAttribute()"
            color="primary">
      Geavanceerd
    </button>
    <button *ngIf="attributeControl.controls.length"
            mat-button
            class=""
            style="font-size: 13px;"
            (click)="addFieldAttribute()"
            color="primary">

      Attribuut toevoegen
    </button>
  </div>
</ng-template>

<ng-template #message>
  <div class="edit-question__alternative-message" *ngIf="is">{{ alternativeMessage}}</div>
</ng-template>
