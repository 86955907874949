import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { flatten, isEqual }  from 'lodash-es';
import { undo } from 'ngrx-undo';
import { forkJoin } from 'rxjs';
import {  map, switchMap } from 'rxjs/operators';

import * as WorkflowActions from '@workflow-admin/shared/workflow/data-access/workflow';
import { DecisionCondition, DecisionConditionSet } from '@workflow-admin/shared/workflow/utils/workflow';

import { StepApiService, StepEditService } from '../../services';
import * as StepsActions from './steps.actions';

@Injectable()
export class StepsEffects {
  loadSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActions.getWorkflowSuccess),
      map(action => StepsActions.loadStepsSuccess({steps: action.workflow.steps}))
    )
  );

  createFirstStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActions.createFirstStep),
      switchMap(action => {
        const newStep = this.stepEditService.generateStep(action.workflowId);
        return this.stepApiService.createStep(newStep).pipe(
          switchMap(step => [
            StepsActions.createStepSuccess({ step }),
            WorkflowActions.editFirstStep({ workflowUuid: action.workflowId, firstStepUuid: step.uuid })
          ])
        )
      })
    )
  )

  createStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.createStep),
      switchMap(action => {
        return this.stepApiService.createStep(action.step).pipe(
          map(step => StepsActions.createStepSuccess({ step }))
        )
      })
    )
  )

  clearSteps$ = createEffect(() =>
  this.actions$.pipe(
    ofType(WorkflowActions.clearSelectedWorkflow),
    map(() => StepsActions.clearSteps())
  ))

  addStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.addStepAndUpdateParent),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.createStep(action.step).pipe(
            switchMap((response) => [
              StepsActions.createStepSuccess({ step: response }),
              StepsActions.createDecision({stepId: response.uuid,  decision: action.newParentStepDecision})
            ])
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  getStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.getStep),
      fetch({
        run: (action) => {
          return this.stepApiService.getStep(action.stepId).pipe(
            map(response => StepsActions.getStepSuccess({step: response}))
          )
        },
        onError: (action, error) => {
          console.error(error)
        }
      })
    )
  )

  editStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.editStep),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.editStep(action.id, action.request).pipe(
            map(response => StepsActions.editStepSuccess({step: response}))
          )
        },
        undoAction: (action, error) => {
          return undo(action)
        }
      })
    )
  )

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.deleteStep),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.deleteStep(action.id).pipe(
            map(response => StepsActions.deleteStepSuccess({id: action.id}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  addStepInBetween$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.addStepInBetween),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.createStep(action.step).pipe(
            switchMap((response) => [
              StepsActions.addStepInBetweenSuccess({ step: response }),
              StepsActions.createDecision({stepId: action.parentStepId, decision: action.newParentStepDecision}),
              StepsActions.createDecision({stepId: response.uuid, decision: this.stepEditService.toDecisionRequest(action.newStepDecision)}),
              StepsActions.deleteDecision({stepId: action.parentStepId, decisionId: action.oldDecisionId})
            ])
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  createDecision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.createDecision),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.addDecision(action.decision).pipe(
            map(response => StepsActions.createDecisionSuccess({stepId: action.stepId}))
          )
        },
        undoAction: (action, error) => {
          return undo(action)
        }
      })
    )
  )

  // reloadStep$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(StepsActions.createDecisionSuccess, StepsActions.deleteDecisionSuccess, StepsActions.updateDecisionsSuccess),
  //     map(action => StepsActions.getStep({stepId: action.stepId}))
  //   )
  // )

  updateDecisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.updateDecisions),
      switchMap(action => {
        const addedDecisions = action.dialogDecisions.filter(dialDec => !action.currentDecisions.some(curDec => curDec.uuid === dialDec.uuid));
        const editedDecisions = action.dialogDecisions.filter(dialDec => action.currentDecisions.some(curDec => ((curDec.uuid === dialDec.uuid) && (!isEqual(curDec, dialDec)))));
        const deletedDecision = action.currentDecisions.filter(curDec => !action.dialogDecisions.some(dialDec => dialDec.uuid === curDec.uuid));

        const addedDecisions$ = addedDecisions.map(decision => this.stepApiService.addDecision(this.stepEditService.toDecisionRequest(decision)))
        const editedDecisions$ = editedDecisions.map(decision => this.stepApiService.editDecision(this.stepEditService.toDecisionRequest(decision)));
        const deletedDecisions$ = deletedDecision.map(decision => this.stepApiService.deleteDecision(decision.uuid))

        return forkJoin([...addedDecisions$, ...editedDecisions$, ...deletedDecisions$]).pipe(
          switchMap(_ => [
            StepsActions.updateDecisionsSuccess({stepId: action.stepId}),
            StepsActions.updateDecisionConditionSets({stepId: action.stepId, dialogDecisions: action.dialogDecisions, currentDecisions: action.currentDecisions  })
          ])
        )
      })
    )
  )
  updateDecisionNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.updateDecisionNextStep),
      switchMap(action => {
               // const editedDecisions$ = this.stepApiService.editDecision(this.stepEditService.toDecisionRequest(action.decision));
               return this.stepApiService.editDecision(this.stepEditService.toDecisionRequest(action.decision)).pipe(
                 map(response => StepsActions.createDecisionSuccess({stepId: action.decision.nextStepUuid}))
               )

        // return forkJoin([...editedDecisions$]).pipe(
        //   switchMap(_ => [
        //     // StepsActions.updateDecisionsSuccess({stepId: action.stepId}),
        //     // StepsActions.updateDecisionConditionSets({stepId: action.stepId, dialogDecisions: action.dialogDecisions, currentDecisions: action.currentDecisions  })
        //   ])
        // )
      })
    )
  )

  updateDecisionConditionSets = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.updateDecisionConditionSets),
      switchMap(action => {

        const currentConditionSets: DecisionConditionSet[] = flatten(action.currentDecisions.map(decision => decision.conditionSets)).filter(e => !!e)
        const dialogConditionSets: DecisionConditionSet[] = flatten(action.dialogDecisions.map(decision => decision.conditionSets.map(conSet => ({...conSet, decision: decision['@id'] ?? `/decisions/${decision.uuid}`})))).filter(e => !!e)

        const addedConditionSets = dialogConditionSets.filter(dialConSet => !currentConditionSets.some(curConSet => curConSet.uuid === dialConSet.uuid));
        const editedConditionSets = dialogConditionSets.filter(dialConSet => currentConditionSets.some(curConSet => ((curConSet.uuid === dialConSet.uuid) && (!isEqual(curConSet, dialConSet)))));
        const deletedConditionSets = currentConditionSets.filter(curConSet => !dialogConditionSets.some(dialDec => dialDec.uuid === curConSet.uuid));

        const addedConditionSets$ = addedConditionSets.map(conSet => this.stepApiService.addDecisionConditionSet({uuid: conSet.uuid, decision: conSet.decision}))
        const editedConditionSets$ = editedConditionSets.map(conSet => this.stepApiService.editDecisionConditionSet({uuid: conSet.uuid, decision: conSet.decision}));
        const deletedConditionSets$ = deletedConditionSets.map(conSet => this.stepApiService.deleteDecisionConditionSet(conSet.uuid))

        return forkJoin([...addedConditionSets$, ...editedConditionSets$, ...deletedConditionSets$]).pipe(
          switchMap(_ => [
            StepsActions.updateDecisionConditionSetsSuccess({stepId: action.stepId}),
            StepsActions.updateDecisionConditions({stepId: action.stepId, dialogConditionSets, currentConditionSets  })
          ])
        )
      })
    )
  )

  updateDecisionConditions = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.updateDecisionConditions),
      switchMap(action => {

        const currentConditions: DecisionCondition[] = flatten(action.currentConditionSets.map(conSet => conSet.conditions))
        const dialogConditions: DecisionCondition[] = flatten(action.dialogConditionSets.map(conSet => conSet.conditions.map(condition => (
          {
            ...condition,
            conditionSet: conSet['@id'] ?? `/decision_condition_sets/${conSet.uuid}`,
            input: condition.input['@id'] ?? condition.input
          }
        ))))

        const addedConditions = dialogConditions.filter(dialCon => !currentConditions.some(curCon => curCon.uuid === dialCon.uuid));
        const editedConditions = dialogConditions.filter(dialCon => currentConditions.some(curCon => ((curCon.uuid === dialCon.uuid) && (!isEqual(curCon, dialCon)))));
        const deletedConditions = currentConditions.filter(curCon => !dialogConditions.some(dialDec => dialDec.uuid === curCon.uuid));

        const addedConditions$ = addedConditions.map(condition => this.stepApiService.addDecisionCondition(condition))
        const editedConditions$ = editedConditions.map(condition => this.stepApiService.editDecisionCondition(condition));
        const deletedConditions$ = deletedConditions.map(condition => this.stepApiService.deleteDecisionCondition(condition))

        return forkJoin([...addedConditions$, ...editedConditions$, ...deletedConditions$]).pipe(
          map(_ => StepsActions.updateDecisionConditionsSuccess({stepId: action.stepId}))
        )
      })
    )
  )

  deleteDecision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StepsActions.deleteDecision),
      optimisticUpdate({
        run: (action) => {
          return this.stepApiService.deleteDecision(action.decisionId).pipe(
            map(response => StepsActions.deleteDecisionSuccess({stepId: action.stepId, decisionId: action.decisionId}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )


  constructor(
    private actions$: Actions,
    private stepEditService: StepEditService,
    private stepApiService: StepApiService,
  ) {}
}
