import { Injectable } from '@angular/core';

// This method generates a Universal Unique Identifier using a 13 hex number random seed from Math.random,
// which is offset by a hex portion the timestamp gotten from `new Date().getTime() in millisecond,
// which is, once depleted, offset by a hex portion of the time in microseconds since page load gotten by `perfomance.now()`.
// While this does not strictly generate uniqueness, the only way the same uuid could be generated would be if:
// the same random seed (0-16) is generated AND the uuid is generated at the *exact* same millisecond AND at the *exact* same microseconds since page load.
// This means that the chance of generating the same uuid twice is, well, very small.

@Injectable({
  providedIn: 'root'
})
export class GenerateUuidService {

  constructor(
  ) { }

  // tslint:disable:no-bitwise

  generateUuid(): string {
    let d = new Date().getTime();//Timestamp
    let d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
        r = (d + r)%16 | 0;
        d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r)%16 | 0;
        d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
