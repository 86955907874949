import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { FormsFacade } from '@workflow-admin/shared/input/data-access/input';
import { WorkflowsFacade } from '@workflow-admin/shared/workflow/data-access/workflow';
import {
  DecisionCondition,
  Field,
  InputType,
  InputValueType,
  Operators,
  QuestionType,
  StepType,
  Workflow,
  WorkflowStep
} from '@workflow-admin/shared/workflow/utils/workflow';

export interface ConditionFormResult {
  uuid: string
  type: InputType,
  input: string
  property: string
  operator: Operators,
  value: string,
  valueType: InputValueType
}

@Component({
  selector: 'workflow-admin-edit-condition',
  templateUrl: './edit-condition-container.component.html',
  styleUrls: ['./edit-condition-container.component.scss']
})
export class EditConditionContainerComponent implements OnInit, OnDestroy{
  @Input() condition: DecisionCondition;
  @Output() conditionChanged = new EventEmitter<ConditionFormResult>();


  public conditionForm: FormGroup;
  public inputType = InputType;
  public operators = Operators;
  public inputTypes = Object.values(InputType)
  public inputValueType = InputValueType;
  public stepType = StepType;
  public questionType = QuestionType;
  public selectedOperators = new BehaviorSubject<Operators[]>(Object.values(this.operators))

  public faChevronDown = faChevronDown;

  private unsubscribe$ = new Subject();

  constructor(
    private stepsFacade: StepsFacade,
    private workflowFacade: WorkflowsFacade,
    private formsFacade: FormsFacade
  ) { }

  ngOnInit(): void {
    this.conditionForm = new FormGroup({
      uuid: new FormControl(''),
      type: new FormControl(''),
      input: new FormControl(''),
      property: new FormControl(''),
      operator: new FormControl(''),
      value: new FormControl('', {updateOn: 'blur'}),
      valueType: new FormControl(''),
    })

    this.initializeConditionForm();
    this.listenForConditionFormChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initializeConditionForm() {
    if(this.condition) {
      this.conditionForm.patchValue(this.condition);
    }

    if(this.condition.input && this.condition.input['@id']) {
      this.conditionForm.patchValue({input: this.condition.input['@id']})
    }

    if(this.condition?.type){
      this.getOperators(this.condition.type as InputType)
    }

    if(this.condition.type && !this.condition.valueType) {
      this.setValueType();
    }
  }

  public listenForConditionFormChanges() {
    this.conditionForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(formValue => {
      this.conditionChanged.emit(formValue);
    })

    this.conditionForm.get('type').valueChanges.pipe(
      filter(type => !!type),
      takeUntil(this.unsubscribe$)
    ).subscribe(type => {
      this.conditionForm.get('input').reset();
      this.conditionForm.get('property').reset();
      this.conditionForm.get('operator').reset();
      this.conditionForm.get('value').reset();
      this.conditionForm.get('valueType').reset();
      this.getOperators(type);
    })

    this.conditionForm.get('input').valueChanges.pipe(
      filter(input => !!input),
      takeUntil(this.unsubscribe$)
    ).subscribe(input => {
      this.conditionForm.get('property').reset();
      this.conditionForm.get('operator').reset();
      this.conditionForm.get('value').reset();
      this.conditionForm.get('valueType').reset();


      if(this.conditionForm.get('type').value === this.inputType.Question) {
        this.formsFacade.getFormQuestions(input.split("/").pop()).pipe(
          filter(questions => !!questions && !!questions.length),
          take(1)
        ).subscribe(questions => {
          this.conditionForm.patchValue({property: questions[0].property})
        })
      }
    })

    this.conditionForm.get('property').valueChanges.pipe(
      filter(property => !!property),
      takeUntil(this.unsubscribe$)
    ).subscribe(property => {
      if(property && property?.length) {
        this.getOperatorsForSelectedQuestion();
      } else {
        this.selectedOperators.next(Object.values(this.operators))
      }
    })

    this.conditionForm.get('operator').valueChanges.pipe(
      filter(operator => !!operator),
      takeUntil(this.unsubscribe$)
    ).subscribe(_ => {
      this.setValueType();
      this.setValue();
    })
  }

  public getInputs(typeOfInput: InputType): Observable<WorkflowStep[] | Workflow[]> {
    switch (typeOfInput) {
      case this.inputType.Info:
        return this.stepsFacade.getSelectedWorkflowStepsByType(this.stepType.Info)
      case this.inputType.QuestionSet:
        return this.stepsFacade.getSelectedWorkflowStepsByType(this.stepType.QuestionSet)
      case this.inputType.Question:
        return this.stepsFacade.getSelectedWorkflowStepsByType(this.stepType.Question)
      case this.inputType.Action:
        return this.stepsFacade.getSelectedWorkflowStepsByType(this.stepType.Action)
      case this.inputType.Workflow:
        return this.workflowFacade.allWorkflows$
      default:
        return of([]);
    }
  }

  public getOperators(typeOfInput: InputType): void {
    switch (typeOfInput) {
      case this.inputType.Action:
      case this.inputType.Workflow:
        this.selectedOperators.next([this.operators.IsSet, this.operators.IsNotSet, this.operators.IsValid]);
        break;
      case this.inputType.Question:
      case this.inputType.QuestionSet:
        const property = this.conditionForm.get('property').value;
        if(property && property.length) {
          this.getOperatorsForSelectedQuestion()
        } else {
          this.selectedOperators.next(Object.values(this.operators))
        }
        break;
      default:
        this.selectedOperators.next(Object.values(this.operators))
    }
  }

  private getOperatorsForSelectedQuestion(): void {
    this.getSelectedQuestion().pipe(
      filter(question => !!question),
      take(1)
    ).subscribe( question => {
        switch(question.type) {
          case this.questionType.Boolean:
          case this.questionType.Radio:
          case this.questionType.CheckboxSingle:
            this.selectedOperators.next([Operators.Equal, Operators.NotEqual, Operators.IsSet, Operators.IsNotSet, Operators.IsValid])
            break;
          case this.questionType.Text:
          case this.questionType.CheckboxMultiple:
            this.selectedOperators.next([Operators.Equal, Operators.NotEqual, Operators.Contains, Operators.NotContains, Operators.IsSet, Operators.IsNotSet, Operators.IsValid])
            break;
          case this.questionType.Integer:
          case this.questionType.Float:
          case this.questionType.Date:
          case this.questionType.Time:
          case this.questionType.DateTime:
            this.selectedOperators.next([Operators.Equal, Operators.NotEqual, Operators.GreaterThan, Operators.LessThan, Operators.GreaterThanOrEqual, Operators.LessThanOrEqual, Operators.IsSet, Operators.IsNotSet, Operators.IsValid])
            break;
          case this.questionType.UploadFile:
          case this.questionType.UploadImage:
            this.selectedOperators.next([Operators.IsSet, Operators.IsNotSet, Operators.IsValid])
            break;
          default:
            this.selectedOperators.next(Object.values(Operators))
        }
      }
    )
  }

  private setValueType() {
    const formValue = this.conditionForm.value;
    switch (formValue.type as InputType) {
      case this.inputType.Action:
      case this.inputType.Workflow:
        this.conditionForm.controls.valueType.setValue(InputValueType.Boolean)
        break;
      case this.inputType.Question:
      case this.inputType.QuestionSet:
        this.setValueTypeForSelectedQuestion();
        break;
      default:
        this.conditionForm.controls.valueType.reset()
    }
  }

  private setValue() {
    const operator = this.conditionForm.get('operator').value as Operators;
    if(operator && [Operators.IsSet, Operators.IsNotSet, Operators.IsValid].includes(operator)) {
      this.conditionForm.controls.value.setValue('true')
    } else {
      this.conditionForm.controls.value.reset()
    }
  }

  private setValueTypeForSelectedQuestion(): void {
    const operator = this.conditionForm.get('operator').value as Operators;
    const property = this.conditionForm.get('property').value;

    if(!operator && (!property || !property.length)) {
      return;
    }

    if(operator && [Operators.IsSet, Operators.IsNotSet, Operators.IsValid].includes(operator)) {
      this.conditionForm.controls.valueType.setValue(InputValueType.Boolean)
      return;
    }

    this.getSelectedQuestion().pipe(
      filter(question => !!question),
      take(1)
    ).subscribe( question => {
        switch(question.type) {
          case this.questionType.Boolean:
            this.conditionForm.controls.valueType.setValue(InputValueType.Boolean);
            break;
          case this.questionType.Text:

          case this.questionType.Radio:
          case this.questionType.Html:
          case this.questionType.CheckboxSingle:
            this.conditionForm.controls.valueType.setValue(InputValueType.String);

            break;
          case this.questionType.CheckboxMultiple:
            case this.questionType.Address:
            this.conditionForm.controls.valueType.setValue(InputValueType.Array);
            break;
          case this.questionType.Integer:
            this.conditionForm.controls.valueType.setValue(InputValueType.Integer);
            break;
          case this.questionType.Float:
            this.conditionForm.controls.valueType.setValue(InputValueType.Floats);
            break;
          case this.questionType.Date:
          case this.questionType.Time:
          case this.questionType.DateTime:
            this.conditionForm.controls.valueType.setValue(InputValueType.Datetime);
            break;
          default:
            this.conditionForm.controls.valueType.reset();
        }
      }
    )
  }

  public getQuestionSetQuestions() {
    const selectedQuestionSet = this.conditionForm.get('input').value;
    if(selectedQuestionSet) {
      return this.formsFacade.getFormQuestions(selectedQuestionSet.split("/").pop())
    } else return of([])
  }

  public getSelectedQuestion(): Observable<Field> {
    const selectedQuestionProperty = this.conditionForm.get('property').value;
    return this.formsFacade.getSelectedQuestion(selectedQuestionProperty)
  }

  public isClosedOptionsQuestion(question: Field): boolean {
    return [this.questionType.Boolean, this.questionType.Radio, this.questionType.CheckboxSingle, this.questionType.CheckboxMultiple].includes(question.type);
  }

  get showAnswers(): boolean {
    const operator = this.conditionForm.get('operator').value as Operators;
    if(!operator) return false;
    return ![Operators.IsSet, Operators.IsNotSet, Operators.IsValid].includes(operator)
  }

  get selectedInputType(): InputType {
    return this.conditionForm.get('type').value as InputType
  }

  // getNoInputOfTypeFoundMessage(typeOfInput: InputType): string {
  //   switch (typeOfInput) {
  //     case this.inputType.QuestionSet:
  //       return 'Geen vragensets gevonden';
  //     case this.inputType.Question:
  //       return 'Geen vragen gevonden';
  //     case this.inputType.Action:
  //       return 'Geen acties gevonden';
  //     case this.inputType.Workflow:
  //       return 'Geen workflows gevonden';
  //     default:
  //       return 'Geen velden gevonden';
  //   }
  // }
}
