<mat-toolbar class="demo-container__header"></mat-toolbar>
<div class="workflows-overview-container">
  <div class="workflows-overview-container__content">
    <h2 class="workflows-overview-container__header">Groepen</h2>
    <div class="workflows-overview-container__flows" cdkDropListGroup>
      <ng-container *ngIf="workflowGroups$ | async; let workflowGroups">
        <div *ngFor="let workflowGroup of workflowGroups" class="workflows-overview-container__flow">
          <workflow-admin-workflow-group-folder [workflowGroup]="workflowGroup"></workflow-admin-workflow-group-folder>
        </div>


      </ng-container>
    </div>

    <div *ngIf="showWorkflowNameEdit | async" class="workflows-overview-container__input-container">
      <mat-form-field appearance="fill" class="workflows-overview-container__input-field">
        <input matInput [formControl]="newWorkflowName" placeholder="Nieuwe naam">
      </mat-form-field>
      <div class="workflows-overview-container__input-buttons">
        <button
          class="workflows-overview-container__input-cancel-buttons"
          mat-flat-button
          color="secondary"
          (click)="cancelCreate()">
          Annuleren
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="createNewWorkflowGroup()">
          Toevoegen
        </button>
      </div>
    </div>

    <button
      *ngIf="!(showWorkflowNameEdit | async)"
      mat-flat-button
      color="primary"
      class="workflows-overview-container__add-flow-button"
      (click)="addWorkflowGroup()">
      Nieuwe groep maken
    </button>
  </div>
</div>
