import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, select, Store} from '@ngrx/store';

import {EditStepDialogResult} from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import {WorkflowsFacade} from '@workflow-admin/shared/workflow/data-access/workflow';
import {
  Decision,
  EditWorkflowStepRequest,
  StepType,
  WorkflowStep
} from '@workflow-admin/shared/workflow/utils/workflow';
import {flatten, isEqual} from 'lodash-es';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';

import {StepEditService} from '../../services';
import * as StepActions from './steps.actions';
import * as fromSteps from './steps.reducer';
import * as StepsSelectors from './steps.selectors';

@Injectable()
export class StepsFacade {
  loaded$ = this.store.pipe(select(StepsSelectors.getStepsLoaded));
  selectedWorkflowSteps$ = this.store.pipe(select(StepsSelectors.getAllSteps));
  // selectedWorkflowSteps$ = this.store.pipe(select(StepsSelectors.getAllSteps));
  selectedId$ = this.store.pipe(select(StepsSelectors.getSelectedId));
  currentStep$ = this.store.pipe(select(StepsSelectors.getSelected));

  stepType = StepType;
  constructor(
    private store: Store<fromSteps.StepsPartialState>,
    private stepEditService: StepEditService,
    private workflowFacade: WorkflowsFacade,
    private router: Router) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  getStep(id: string): Observable<WorkflowStep> {
    return this.store.pipe(select(StepsSelectors.getStepById, id))
  }

  getSelectedWorkflowStepsByType(type: StepType) {
    return this.store.pipe(select(StepsSelectors.getStepsByType, type))
  }

  selectStep(stepUuid: string) {
    console.log('selectStep',stepUuid);
    return this.store.dispatch(StepActions.selectStep({stepUuid}))
  }

  clearSelectedStep() {
    console.log('clear');
    return this.store.dispatch(StepActions.clearSelectedStep())
  }
  addStepAndUpdateParent(parentStepId: string, workflowId: string): Observable<WorkflowStep> {
    const step = this.stepEditService.generateStep(workflowId);
    const newParentStepDecision = this.stepEditService.generateDecisionRequest(parentStepId, step.uuid)
    this.store.dispatch(StepActions.addStepAndUpdateParent({ step, newParentStepDecision }))
    return of({ ...step, decisions: [], input: undefined, info:'', workflow: workflowId });
  }


  addStepInBetween(parentStepId: string, nextStepId: string, workflowId: string, oldDecisionId: string): Observable<WorkflowStep> {
    const step = this.stepEditService.generateStep(workflowId);
    const newParentStepDecision = this.stepEditService.generateDecisionRequest(parentStepId, step.uuid)
    const newStepDecision = this.stepEditService.generateDecision(step.uuid, nextStepId);
    this.store.dispatch(StepActions.addStepInBetween({step, parentStepId, oldDecisionId, newParentStepDecision, newStepDecision}))
    return of({ ...step, decisions: [newStepDecision], input: undefined, info:'', workflow: workflowId });
  }

  addNewNextStep(name: string): Observable<string> {
    return this.workflowFacade.selectedWorkflowId$.pipe(
      filter(id => !!id),
      switchMap(id => {
        const step = this.stepEditService.generateStep(id, name);
        this.store.dispatch(StepActions.createStep({ step }))
        return of(step.uuid)
      })
    )
  }

  editStep(dialogResult: EditStepDialogResult) {
    // this.getStep(dialogResult.uuid);
    this.editDecisions(dialogResult);

    this.getStep(dialogResult.uuid).pipe(
      filter(step => !!step),
      take(1)
    ).subscribe(currentStep => {
      const stepEditRequest: EditWorkflowStepRequest = {
        ...(dialogResult.type && dialogResult.type.length && dialogResult.type !== currentStep.type) && {type: dialogResult.type},
        ...(dialogResult.name && dialogResult.name.length && dialogResult.name !== currentStep.name) && {name: dialogResult.name},
        ...(dialogResult.info && dialogResult.info.length && dialogResult.info !== currentStep.info) && {info: dialogResult.info},
        ...(dialogResult.input && dialogResult.input.length && dialogResult.input !== currentStep.input && [this.stepType.Info, this.stepType.Question, this.stepType.QuestionSet, this.stepType.Action].includes(dialogResult.type as StepType)) && {input: dialogResult.input}
      }

      const hasPayload = Object.keys(stepEditRequest).length > 0
      if(hasPayload) {
        this.store.dispatch(StepActions.editStep({ id: dialogResult.uuid, request: stepEditRequest }));
      }
    })
  }

  editStepInputReference(stepUuid: string, inputId: string) {
    this.store.dispatch(StepActions.editStep({id: stepUuid, request: {input: inputId} as EditWorkflowStepRequest}))
  }
  updateDecisionNextStep(nextStepUuid, decision){
    const newDecision = {...decision, nextStep: nextStepUuid, nextStepUuid: nextStepUuid,};
    this.store.dispatch(StepActions.updateDecisionNextStep({
      nextStepUuid: nextStepUuid,
      decision: newDecision
    }))
  }
  deleteStep(step: WorkflowStep): void {
    combineLatest([this.selectedWorkflowSteps$, this.workflowFacade.selectedWorkflowFirstStepUuid$]).pipe(
      filter(([steps, _]) => !!steps),
      take(1)
    ).subscribe(([steps, selectedFirstStepId]) => {
      if(steps.length === 1){
        this.workflowFacade.deleteSelectedWorkflow();
        this.router.navigate(['workflows'])
        return;
      }
      if (step.uuid === selectedFirstStepId) {
        // TODO: show error in toast.
        console.error("Kan stap niet verwijderen - deze stap is geselecteerd als eerste stap van de workflow. Kies eerst een andere eerste stap.")
        return;
      }

            if(step.decisions.length === 1)
            {
              if (step.decisions[0].nextStepUuid)
              {
                console.log('update pointing step to nextUuid');
                this.replaceDecisionNextStep(step.uuid,step.decisions[0].nextStepUuid)

              }
            }
            else {  // maybe do something else here to make new connection
              console.log('delete all decisions');

            }
        //race condition -> await deleteDecisions... //@todo
        //@todo use reducer
      setTimeout(() => this.store.dispatch(StepActions.deleteStep({ id: step.uuid })),100);
      setTimeout(() =>   this.refreshWorkflow(),200);



    })
  }

  editDecisions(dialogResult: EditStepDialogResult) {
    const dialogDecisions = dialogResult?.decisions;
    // this.store.pipe(select(StepsSelectors.getStepById, dialogResult.uuid))
    this.store.pipe(select(StepsSelectors.getDecisionsForStep, dialogResult.uuid)).pipe(
      filter(decisions => !!decisions),
      take(1)
    ).subscribe(async (currentDecisions: Decision[]) => {
      // console.log('editDecisions', dialogDecisions);
      for (const d of dialogDecisions) { // create additional steps (race condition warning)!
        if (!d.nextStepUuid) {
          d.nextStepUuid = await this.addNewNextStep(d.name).pipe(first()).toPromise();
        }
      }
      // console.log(dialogDecisions);

      if (!currentDecisions?.length && !dialogDecisions?.length) return;

      if (isEqual(currentDecisions, dialogDecisions)) return;
//@todo race condition issue
      setTimeout(() => {
        this.store.dispatch(StepActions.updateDecisions({stepId: dialogResult.uuid, currentDecisions, dialogDecisions}));
      },300);

    })
  }

  public getReferringDecisions(stepId: string): Observable<Decision[]> {
    return this.selectedWorkflowSteps$.pipe(
      filter(steps => !!steps && !!steps.length),
      take(1),
      map(steps => {
        const currentDecisions: Decision[] = flatten(steps.map(step => step.decisions)).filter(e => !!e)
        return currentDecisions.filter(decision => decision.nextStepUuid === stepId)
      }
    )
    )
  }
  // functies om volgorde te bepalen
  public getStepPosition(step:WorkflowStep):number{

      let referringSteps =
        null;
      this.getReferringSteps(step.uuid).pipe(take(1)).subscribe((r) => {
        referringSteps = r});
      let currentPosition = 1;
      if (referringSteps.length === 0)
        currentPosition = 0;
      while(referringSteps.length && currentPosition<10)
      {

         this.getReferringSteps(referringSteps[0].uuid).pipe(take(1)).subscribe((r) => {
           referringSteps = r});
        currentPosition++
      }

        let startWorkflowId = '';
        this.workflowFacade.selectedWorkflowFirstStepUuid$.pipe(take(1)).subscribe((firstWorkflowId) => {
          startWorkflowId = firstWorkflowId;
        });
        if (startWorkflowId === step.uuid)
          currentPosition = 1;

      return currentPosition;
  }
  public sortedStepsByUsage(): Observable<any> {
    return this.selectedWorkflowSteps$.pipe(
      filter(steps => !!steps && !!steps.length),
      take(1),
      map(steps => { //@todo: juiste eruit filteren
        return steps.sort( (a,b) => this.getStepPosition(a) > this.getStepPosition(b) ? 1 : -1 );
        }
      ),
      map(steps => {
        return steps.map( (step) => {
          return { position: this.getStepPosition(step), isFirstStep: false, step: step }
        });
      })
    )

  }
  // public hasReferringSteps(stepId): Promise<boolean> {
  //   return
  // }
  public getReferringSteps(stepId: string): Observable<WorkflowStep[]> {
    return this.selectedWorkflowSteps$.pipe(
      filter(steps => !!steps && !!steps.length),
      take(1),
      map(steps => { //@todo: juiste eruit filteren
        // console.log('steps',steps);
          return steps.filter(step => step.decisions.filter(e => !!e).filter(decision => decision.nextStepUuid === stepId).length>0);
        }
      )
    )
  }
  public refreshWorkflow()
  {
    //@todo after dispatch refresh workflow
    setTimeout( () => {   this.workflowFacade.selectedWorkflowId$.
    subscribe(id => {
      this.workflowFacade.selectWorkflow( id);
    });
    },100);
  }
  public replaceDecisionNextStep(stepId: string,nextStepUuid: string) {
    return this.getReferringSteps(stepId).subscribe(steps => {
      steps.forEach(step => {
        let newDecisions = [];

        step.decisions.forEach(decision => {
            if (decision.nextStepUuid === stepId) {
              newDecisions = [...newDecisions, {...decision, nextStepUuid: nextStepUuid}];
            } else {
              newDecisions = [...newDecisions, decision];
            }
          });
        this.store.dispatch(StepActions.updateDecisions({ stepId: stepId, currentDecisions: step.decisions, dialogDecisions: newDecisions }));
      });
    });
  }
}
