import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {Workflow, WorkflowAction, WorkflowFull, WorkflowGroup} from '@workflow-admin/shared/workflow/utils/workflow';
import { buyComputer, demoFlow, miniFlowDakkapel } from '@workflow-admin/testdata';
import * as ActionsActions from "../../../../../../../input/data-access/input/src/lib/+state/actions/actions.actions";
import {actionsAdapter} from "../../../../../../../input/data-access/input/src/lib/+state/actions/actions.reducer";

import * as WorkflowGroupActions from './workflowGroups.actions';

export const WORKFLOW_GROUPS_FEATURE_KEY = 'workflowGroups';

export interface State extends EntityState<WorkflowGroup> {
  selectedId?: string; // which Workflows record has been selected
  loaded: boolean; // has the Workflows list been loaded
  error?: string | null; // last known error (if any)
  selectedWorkflowGroup?: WorkflowGroup;
}

export interface WorkflowGroupsPartialState {
  readonly [WORKFLOW_GROUPS_FEATURE_KEY]: State;
}

//TODO: consider using @id
export const workflowGroupsAdapter: EntityAdapter<WorkflowGroup> = createEntityAdapter<
  WorkflowGroup
>({selectId: workflowGroup => workflowGroup.uuid});

export const initialState: State = workflowGroupsAdapter.getInitialState({
  loaded: false,
  selectedId: null,
  selectedWorkflowGroup: null,
})

console.log('reducer',initialState)
const workflowGroupsReducer = createReducer(
  initialState,
  on(WorkflowGroupActions.loadWorkflowGroups, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(WorkflowGroupActions.loadWorkflowGroupsSuccess, (state, { workflowGroups }) =>
    // maybe this should be setAll on ...state to replace all
    workflowGroupsAdapter.setAll(workflowGroups, {
      ...state,
      loaded: true,
      entities: initialState.entities
    })
  ),
  on(WorkflowGroupActions.loadWorkflowGroupsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(WorkflowGroupActions.createWorkflowGroup, (state, { request}) =>
    workflowGroupsAdapter.addOne({ ...request, workflows: [] }, state)
  ),
  on(WorkflowGroupActions.createWorkflowGroupSuccess, (state, { workflowGroup}) =>
    workflowGroupsAdapter.setOne(workflowGroup, state)
  ),
  on(WorkflowGroupActions.deleteWorkflowGroup, WorkflowGroupActions.deleteWorkflowGroupSuccess, (state, {id}) =>
    workflowGroupsAdapter.removeOne(id, state)
  ),
  on(WorkflowGroupActions.editWorkflowGroup, WorkflowGroupActions.editWorkflowGroupSuccess, (state, { workflowGroup }) => {
    const update: Update<WorkflowGroup> = {
      id: workflowGroup.uuid,
      changes: workflowGroup
    }
    return workflowGroupsAdapter.updateOne(update, state)
  }),
  // on(WorkflowsActions.selectWorkflow, (state, {workflowId}) => {
  //     return {...state, selectedId: workflowId}
  //   }
  // ),
  // on(WorkflowsActions.clearSelectedWorkflow, (state) => ({
  //   ...state,
  //   selectedId: null,
  //   selectedWorkflow: null,
  //   selectedWorkflowLoaded: false
  // })),
  // on(WorkflowsActions.getWorkflow, (state) => ({
  //   ...state,
  //   selectedWorkflow: null,
  //   error: null,
  //   selectedWorkflowLoaded: false,
  // })),
  // on(WorkflowGroupActions.getWorkflowGroupSuccess, (state, {workflowGroup}) => ({
  //   ...state,
  //   selectedWorkflowGroup: workflowGroup,
  //   selectedWorkflowGroupLoaded: true
  // })),
  // on(WorkflowsActions.editFirstStep, (state, {workflowUuid, firstStepUuid}) => {
  //   const workflowUpdate: Update<Workflow> = {
  //     id: workflowUuid,
  //     changes: {
  //       firstStepUuid
  //     }
  //   }
  //   return workflowsAdapter.updateOne(workflowUpdate, state)
  // }),
  // on(WorkflowsActions.editFirstStepSuccess, (state, {workflow}) =>
  //   workflowsAdapter.setOne(workflow, state)
  // )
);

// TODO: add reducerfunctions for createWorkflowFailure

export function reducer(state: State | undefined, action: Action) {
  return workflowGroupsReducer(state, action);
}
