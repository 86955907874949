import { Workflow, WorkflowFull } from '../shared/workflow/utils/workflow/src/lib/models';

export const buyComputer: WorkflowFull = {
  "@context":"\/contexts\/Workflow",
  "@id":"\/workflows\/def57ced-8e87-488c-8a58-fa5db6de81ff",
  "@type":"Workflow",
  "steps": [],
  // "steps":[
  //   {
  //     "@id":"\/steps\/f25f4809-73ea-40cd-b9d6-5b972b2a8df7",
  //     "@type":"Step",
  //     "uuid":"f25f4809-73ea-40cd-b9d6-5b972b2a8df7",
  //     "name":"fill_out_computer_specifications",
  //     "decisions":[
  //       {
  //         "@id":"\/decisions\/a3fe3cf2-a832-447a-ae6c-94c25d006f34",
  //         "@type":"Decision",
  //         "uuid":"a3fe3cf2-a832-447a-ae6c-94c25d006f34",
  //         "position":0,
  //         "nextStepUuid":"1208bc41-ab1a-4a4f-99d2-82269c3d0612",
  //         "conditionSets":[
  //           {
  //             "@id":"\/conditionSets\/e2ae13a6-1332-40cd-981e-1a77d8488e8a",
  //             "@type":"ConditionSet",
  //             "uuid":"e2ae13a6-1332-40cd-981e-1a77d8488e8a",
  //             "conditions":[
  //               {
  //                 "@id":"\/conditions\/954c2de9-4b1a-41d3-ae07-083b981ba06a",
  //                 "@type":"Condition",
  //                 "uuid":"954c2de9-4b1a-41d3-ae07-083b981ba06a",
  //                 "type":"boolean",
  //                 "operator":"is_valid",
  //                 "modifier":null,
  //                 "property":"form",
  //                 "value":"1",
  //                 "input":{
  //                   "@id":"\/forms\/94a31e49-488f-4846-a2ff-365f01e9eaa5",
  //                   "@type":"Form",
  //                   "questions":[
  //                     {
  //                       "@id":"\/questions\/f9f45f47-79a4-403c-86df-bfaeda1cc1b6",
  //                       "@type":"Question",
  //                       "uuid":"f9f45f47-79a4-403c-86df-bfaeda1cc1b6",
  //                       "position":0,
  //                       "name":"windows_or_apple",
  //                       "label":{
  //                         "show":true,
  //                         "text":"operating_system"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "apple":"apple",
  //                         "windows":"windows"
  //                       }
  //                     },
  //                     {
  //                       "@id":"\/questions\/1f25889f-91b0-4aa8-9172-9a8936ee6927",
  //                       "@type":"Question",
  //                       "uuid":"1f25889f-91b0-4aa8-9172-9a8936ee6927",
  //                       "position":1,
  //                       "name":"16gb_or_32gb",
  //                       "label":{
  //                         "show":true,
  //                         "text":"memory_size"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "16":"16GB",
  //                         "32":"32GB"
  //                       }
  //                     },
  //                     {
  //                       "@id":"\/questions\/90404ada-e0bc-4cb7-9bf6-23fe59af4db2",
  //                       "@type":"Question",
  //                       "uuid":"90404ada-e0bc-4cb7-9bf6-23fe59af4db2",
  //                       "position":2,
  //                       "name":"keyboard_needed",
  //                       "label":{
  //                         "show":true,
  //                         "text":"keyboard_needed"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     },
  //                     {
  //                       "@id":"\/questions\/7ed93adb-3209-471c-a07e-8857eb4ebffa",
  //                       "@type":"Question",
  //                       "uuid":"7ed93adb-3209-471c-a07e-8857eb4ebffa",
  //                       "position":3,
  //                       "name":"mouse_needed",
  //                       "label":{
  //                         "show":true,
  //                         "text":"mouse_needed"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     },
  //                     {
  //                       "@id":"\/questions\/8fab46ff-f629-4023-baa3-a3cae87eeeda",
  //                       "@type":"Question",
  //                       "uuid":"8fab46ff-f629-4023-baa3-a3cae87eeeda",
  //                       "position":4,
  //                       "name":"dual_or_triple_screen",
  //                       "label":{
  //                         "show":true,
  //                         "text":"dual_or_triple"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     }
  //                   ],
  //                   "uuid":"94a31e49-488f-4846-a2ff-365f01e9eaa5",
  //                   "name":"computer_specification_requirements",
  //                   "discr":"form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     "@id":"\/steps\/1208bc41-ab1a-4a4f-99d2-82269c3d0612",
  //     "@type":"Step",
  //     "uuid":"1208bc41-ab1a-4a4f-99d2-82269c3d0612",
  //     "name":"place_the_order",
  //     "decisions":[
  //       {
  //         "@id":"\/decisions\/6957e1f0-41fa-47b5-8085-5d6a631f786b",
  //         "@type":"Decision",
  //         "uuid":"6957e1f0-41fa-47b5-8085-5d6a631f786b",
  //         "position":0,
  //         "nextStepUuid":"d06d7e84-249d-4307-9f5e-8cb0cdbc35c0",
  //         "conditionSets":[
  //           {
  //             "@id":"\/conditionSets\/5425b512-40ec-42c5-a233-fe39d3057bee",
  //             "@type":"ConditionSet",
  //             "uuid":"5425b512-40ec-42c5-a233-fe39d3057bee",
  //             "conditions":[
  //               {
  //                 "@id":"\/conditions\/c5af65cb-8e99-4bcf-9dda-a9f3bc3b2fab",
  //                 "@type":"Condition",
  //                 "uuid":"c5af65cb-8e99-4bcf-9dda-a9f3bc3b2fab",
  //                 "type":"boolean",
  //                 "operator":"equal",
  //                 "modifier":null,
  //                 "property":"questions.have_you_ordered_the_pc",
  //                 "value":"1",
  //                 "input":{
  //                   "@id":"\/forms\/f4923ac5-1766-471c-ba92-19021f4cb8af",
  //                   "@type":"Form",
  //                   "questions":[
  //                     {
  //                       "@id":"\/questions\/ffc57b99-4113-42e4-b9a9-04467ed6e92a",
  //                       "@type":"Question",
  //                       "uuid":"ffc57b99-4113-42e4-b9a9-04467ed6e92a",
  //                       "position":0,
  //                       "name":"have_you_ordered_the_pc",
  //                       "label":{
  //                         "show":true,
  //                         "text":"ordered_pc"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     }
  //                   ],
  //                   "uuid":"f4923ac5-1766-471c-ba92-19021f4cb8af",
  //                   "name":"order_the_computer",
  //                   "discr":"form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   },
  // {
  //   "@id":"\/steps\/6ec5a52a-329f-41e6-ac55-c52a3d944e9c",
  //   "@type":"Step",
  //   "uuid":"6ec5a52a-329f-41e6-ac55-c52a3d944e9c",
  //   "name":"has_the_order_come_in_yet",
  //   "decisions":[
  //     {
  //       "@id":"\/decisions\/1ac782dd-8c2c-49e7-b748-b27a5adce65f",
  //       "@type":"Decision",
  //       "uuid":"1ac782dd-8c2c-49e7-b748-b27a5adce65f",
  //       "position":0,
  //       "nextStepUuid":null,
  //       "conditionSets":[
  //         {
  //           "@id":"\/conditionSets\/6b60de52-3728-4895-b8f8-87e12987a861",
  //           "@type":"ConditionSet",
  //           "uuid":"6b60de52-3728-4895-b8f8-87e12987a861",
  //           "conditions":[
  //             {
  //               "@id":"\/conditions\/9124a4b0-3107-4bb0-b16c-2e196035130b",
  //               "@type":"Condition",
  //               "uuid":"9124a4b0-3107-4bb0-b16c-2e196035130b",
  //               "type":"boolean",
  //               "operator":"equal",
  //               "modifier":null,
  //               "property":"questions.has_the_order_been_delivered",
  //               "value":"1",
  //               "input":{
  //                 "@id":"\/forms\/7be25c64-aa94-4df7-b316-b579558c1be1",
  //                 "@type":"Form",
  //                 "questions":[
  //                   {
  //                     "@id":"\/questions\/3f83f700-bd56-45b9-997e-9f9ee7866509",
  //                     "@type":"Question",
  //                     "uuid":"3f83f700-bd56-45b9-997e-9f9ee7866509",
  //                     "position":0,
  //                     "name":"has_the_order_been_delivered",
  //                     "label":{
  //                       "show":true,
  //                       "text":"ordered_pc"
  //                     },
  //                     "placeholder":{
  //                       "show":false,
  //                       "text":null
  //                     },
  //                     "questionType":"radio",
  //                     "can_have_notes":false,
  //                     "valueOptions":{
  //                       "no":"no",
  //                       "yes":"yes"
  //                     }
  //                   }
  //                 ],
  //                 "uuid":"7be25c64-aa94-4df7-b316-b579558c1be1",
  //                 "name":"is_order_delivered",
  //                 "discr":"form"
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       "@id":"\/decisions\/c1889540-3249-4523-9e21-c4b166a02f5c",
  //       "@type":"Decision",
  //       "uuid":"c1889540-3249-4523-9e21-c4b166a02f5c",
  //       "position":1,
  //       "nextStepUuid":"6ec5a52a-329f-41e6-ac55-c52a3d944e9c",
  //       "conditionSets":[
  //         {
  //           "@id":"\/conditionSets\/8e8006aa-495d-4335-b768-06a0f9030992",
  //           "@type":"ConditionSet",
  //           "uuid":"8e8006aa-495d-4335-b768-06a0f9030992",
  //           "conditions":[
  //             {
  //               "@id":"\/conditions\/a66dd11a-beb9-4806-a73c-68211837db37",
  //               "@type":"Condition",
  //               "uuid":"a66dd11a-beb9-4806-a73c-68211837db37",
  //               "type":"boolean",
  //               "operator":"equal",
  //               "modifier":null,
  //               "property":"questions.has_the_order_been_delivered",
  //               "value":"",
  //               "input":{
  //                 "@id":"\/forms\/7be25c64-aa94-4df7-b316-b579558c1be1",
  //                 "@type":"Form",
  //                 "questions":[
  //                   {
  //                     "@id":"\/questions\/3f83f700-bd56-45b9-997e-9f9ee7866509",
  //                     "@type":"Question",
  //                     "uuid":"3f83f700-bd56-45b9-997e-9f9ee7866509",
  //                     "position":0,
  //                     "name":"has_the_order_been_delivered",
  //                     "label":{
  //                       "show":true,
  //                       "text":"ordered_pc"
  //                     },
  //                     "placeholder":{
  //                       "show":false,
  //                       "text":null
  //                     },
  //                     "questionType":"radio",
  //                     "can_have_notes":false,
  //                     "valueOptions":{
  //                       "no":"no",
  //                       "yes":"yes"
  //                     }
  //                   }
  //                 ],
  //                 "uuid":"7be25c64-aa94-4df7-b316-b579558c1be1",
  //                 "name":"is_order_delivered",
  //                 "discr":"form"
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  //   {
  //     "@id":"\/steps\/d06d7e84-249d-4307-9f5e-8cb0cdbc35c0",
  //     "@type":"Step",
  //     "uuid":"d06d7e84-249d-4307-9f5e-8cb0cdbc35c0",
  //     "name":"are_you_picking_up_the_order",
  //     "decisions":[
  //       {
  //         "@id":"\/decisions\/ef9bc3d5-fe18-432d-939a-a23794db1ae2",
  //         "@type":"Decision",
  //         "uuid":"ef9bc3d5-fe18-432d-939a-a23794db1ae2",
  //         "position":0,
  //         "nextStepUuid":null,
  //         "conditionSets":[
  //           {
  //             "@id":"\/conditionSets\/025ce375-d379-4b39-a2f0-f84b41e4300b",
  //             "@type":"ConditionSet",
  //             "uuid":"025ce375-d379-4b39-a2f0-f84b41e4300b",
  //             "conditions":[
  //               {
  //                 "@id":"\/conditions\/beb08c18-f055-41e5-af84-60ce5b42421a",
  //                 "@type":"Condition",
  //                 "uuid":"beb08c18-f055-41e5-af84-60ce5b42421a",
  //                 "type":"boolean",
  //                 "operator":"equal",
  //                 "modifier":null,
  //                 "property":"questions.are_you_picking_up_the_order",
  //                 "value":"1",
  //                 "input":{
  //                   "@id":"\/forms\/673047ff-3c3d-4fcb-9c9e-7190a5b44342",
  //                   "@type":"Form",
  //                   "questions":[
  //                     {
  //                       "@id":"\/questions\/777aac05-53f0-4b18-b9ff-90cf438f3ee2",
  //                       "@type":"Question",
  //                       "uuid":"777aac05-53f0-4b18-b9ff-90cf438f3ee2",
  //                       "position":0,
  //                       "name":"are_you_picking_up_the_order",
  //                       "label":{
  //                         "show":true,
  //                         "text":"ordered_pc"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     }
  //                   ],
  //                   "uuid":"673047ff-3c3d-4fcb-9c9e-7190a5b44342",
  //                   "name":"picking_up_order",
  //                   "discr":"form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         "@id":"\/decisions\/c81cbcc0-7048-4202-bec4-67b09635f4e2",
  //         "@type":"Decision",
  //         "uuid":"c81cbcc0-7048-4202-bec4-67b09635f4e2",
  //         "position":1,
  //         "nextStepUuid":"6ec5a52a-329f-41e6-ac55-c52a3d944e9c",
  //         "conditionSets":[
  //           {
  //             "@id":"\/conditionSets\/4b12b64e-1661-429e-b9d3-d5f4e475ccba",
  //             "@type":"ConditionSet",
  //             "uuid":"4b12b64e-1661-429e-b9d3-d5f4e475ccba",
  //             "conditions":[
  //               {
  //                 "@id":"\/conditions\/16d548f8-6ab8-40f7-b023-ab39a1c61b55",
  //                 "@type":"Condition",
  //                 "uuid":"16d548f8-6ab8-40f7-b023-ab39a1c61b55",
  //                 "type":"boolean",
  //                 "operator":"equal",
  //                 "modifier":null,
  //                 "property":"questions.are_you_picking_up_the_order",
  //                 "value":"",
  //                 "input":{
  //                   "@id":"\/forms\/673047ff-3c3d-4fcb-9c9e-7190a5b44342",
  //                   "@type":"Form",
  //                   "questions":[
  //                     {
  //                       "@id":"\/questions\/777aac05-53f0-4b18-b9ff-90cf438f3ee2",
  //                       "@type":"Question",
  //                       "uuid":"777aac05-53f0-4b18-b9ff-90cf438f3ee2",
  //                       "position":0,
  //                       "name":"are_you_picking_up_the_order",
  //                       "label":{
  //                         "show":true,
  //                         "text":"ordered_pc"
  //                       },
  //                       "placeholder":{
  //                         "show":false,
  //                         "text":null
  //                       },
  //                       "questionType":"radio",
  //                       "can_have_notes":false,
  //                       "valueOptions":{
  //                         "no":"no",
  //                         "yes":"yes"
  //                       }
  //                     }
  //                   ],
  //                   "uuid":"673047ff-3c3d-4fcb-9c9e-7190a5b44342",
  //                   "name":"picking_up_order",
  //                   "discr":"form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ],
  "uuid":"buy_computer",
  "name":"buy_a_computer",
  "firstStepUuid":"f25f4809-73ea-40cd-b9d6-5b972b2a8df7",
}
