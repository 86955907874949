import {
  Component,
  EventEmitter,
  Input, OnChanges,
  Output, SimpleChanges
} from '@angular/core';
import { Edge } from '@swimlane/ngx-graph';
import * as shape from 'd3-shape';

import { FlowChartNode } from '@workflow-admin/shared/flow-chart/utils/flow-chart';

@Component({
  selector: 'workflow-admin-flow-chart-container',
  templateUrl: './flow-chart-container.component.html',
  styleUrls: ['./flow-chart-container.component.scss']
})
export class FlowChartContainerComponent  implements OnChanges {
  @Input() nodes: FlowChartNode[] = [];
  @Input() edges: Edge[] = [];

  @Output() addStep = new EventEmitter<string>();
  @Output() editStep = new EventEmitter<FlowChartNode>();
  @Output() editFirstStep = new EventEmitter<void>();

  public layoutSettings = {orientation: 'TB'};
  public curve: any = shape.curveLinear;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('this.nodes',this.nodes);
    // console.log('this.edges',this.edges);
    // console.log('changes',changes);
  }

  public showEditDialog(node: FlowChartNode): void {
    this.editStep.emit(node);
  }

  public onAddStep(parentNodeId: string) {
    this.addStep.emit(parentNodeId);
  }

  public showEditFirstStep(): void {
    this.editFirstStep.emit();
  }
}


