<div class="workflow-groups-overview-container__card">
  <button mat-icon-button (click)="workflowGroup.closed = workflowGroup.closed?false:true" >
    <fa-icon class="workflows-overview-container__delete-icon" [icon]="workflowGroup.closed ? faFolder : faFolderOpen"></fa-icon>
  </button>
  <span class="workflows-overview-container__card__text">{{workflowGroup.name}}</span>

  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="!(showWorkflowNameEdit | async)" (click)="addWorkflow()">
      <mat-icon>add</mat-icon>
      <span>Nieuwe workflow</span>
    </button>
    <button mat-menu-item (click)="deleteWorkflowGroup(workflowGroup.uuid)" >
      <mat-icon>remove</mat-icon>
      <span>Verwijder groep</span>
    </button>

  </mat-menu>
<!--  <button-->
<!--    *ngIf="!(showWorkflowNameEdit | async)"-->
<!--    mat-flat-button-->
<!--    color="primary"-->
<!--    class="workflows-overview-container__add-flow-button"-->
<!--    (click)="addWorkflow()">-->
<!--    Nieuwe workflow-->
<!--  </button>-->
<!--  <button mat-button (click)="deleteWorkflowGroup(workflowGroup.uuid)">-->
<!--    <fa-icon class="workflows-overview-container__delete-icon" [icon]="faTrashAlt"></fa-icon>-->
<!--  </button>-->
</div>
<div class="workflows" [hidden]="workflowGroup.closed" >
  <workflow-admin-group-workflow-list [items]="workflowGroup.workflows" (positionChanged)="onWorkflowMoved($event)" (itemSelected)="onWorkflowSelected($event)"></workflow-admin-group-workflow-list>
  <!--                  <div *ngFor="let workflow of workflowGroup.workflows" class="item">-->
  <!--                    -->
  <!--                    <div cdkDropList class="drag-drop-list" (cdkDropListDropped)="itemMoved($event)" [cdkDropListData]="items">-->
  <!--                      <div class="drag-drop-item" *ngFor="let item of items" cdkDrag (click)="editItem(item)" [id]="item.uuid">-->
  <!--                        <fa-icon class="drag-drop-icon" [icon]="faBars"></fa-icon>-->
  <!--                      </div>-->
  <!--                    </div>-->
  <!--                    <div class="workflows-overview-container__card" [routerLink]="['/workflow', workflow.uuid]">-->
  <!--                      <span class="workflows-overview-container__card__text">{{workflow.name}}</span>-->
  <!--                      <fa-icon class="workflows-overview-container__card__icon" [icon]="faArrowRight"></fa-icon>-->
  <!--                    </div>-->
  <!--&lt;!&ndash;                    <button mat-button (click)="deleteWorkflowGroup(workflow.uuid)">&ndash;&gt;-->
  <!--&lt;!&ndash;                      <fa-icon class="workflows-overview-container__delete-icon" [icon]="faTrashAlt"></fa-icon>&ndash;&gt;-->
  <!--&lt;!&ndash;                    </button>&ndash;&gt;-->
  <!--                  </div>-->


  <div *ngIf="showWorkflowNameEdit | async" class="workflows-overview-container__input-container">
    <mat-form-field appearance="fill" class="workflows-overview-container__input-field">
      <input matInput [formControl]="newWorkflowName" placeholder="Nieuwe workflow naam">
    </mat-form-field>
    <div class="workflows-overview-container__input-buttons">
      <button
        class="workflows-overview-container__input-cancel-buttons"
        mat-flat-button
        color="secondary"
        (click)="cancelCreate()">
        Annuleren
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="createNewWorkflow()">
        Toevoegen
      </button>
    </div>
  </div>


</div>
