import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  State,
  workflowGroupsAdapter,
  WorkflowGroupsPartialState,
  WORKFLOW_GROUPS_FEATURE_KEY,
} from './workflowGroups.reducer';

// Lookup the 'Workflows' feature state managed by NgRx
export const getWorkflowGroupsState = createFeatureSelector<
  WorkflowGroupsPartialState,
  State
>(WORKFLOW_GROUPS_FEATURE_KEY);

const { selectAll, selectEntities } = workflowGroupsAdapter.getSelectors();

export const getWorkflowGroupsLoaded = createSelector(
  getWorkflowGroupsState,
  (state: State) => state?state.loaded:false //@todo??
);

export const getWorkflowGroupsError = createSelector(
  getWorkflowGroupsState,
  (state: State) => state.error
);

export const getAllWorkflowGroups = createSelector(
  getWorkflowGroupsState,
  (state: State) => selectAll(state)
);

export const getWorkflowGroupsEntities = createSelector(
  getWorkflowGroupsState,
  (state: State) => selectEntities(state)
);

// export const getSelectedId = createSelector(
//   getWorkflowGroupsState,
//   (state: State) => state.selectedId
// );

// export const getSelected = createSelector(
//   getWorkflowGroupsEntities,
//   getSelectedId,
//   (entities, selectedId) => selectedId && entities[selectedId]
// );

