import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';

import {WorkflowEditService} from '../../services';
import * as WorkflowActions from './workflows.actions';
import * as fromWorkflows from './workflows.reducer'
import * as WorkflowsSelectors from './workflows.selectors';

@Injectable()
export class WorkflowsFacade {
  loaded$ = this.store.pipe(select(WorkflowsSelectors.getWorkflowsLoaded));
  allWorkflows$ = this.store.pipe(select(WorkflowsSelectors.getAllWorkflows));
  selectedWorkflowId$ = this.store.pipe(select(WorkflowsSelectors.getSelectedId))
  selectedWorkflow$ = this.store.pipe(select(WorkflowsSelectors.getSelected))
  selectedWorkflowFirstStepUuid$ = this.store.pipe(select(WorkflowsSelectors.getSelectedWorkflowFirstStepUuid))

  constructor(
    private store: Store<fromWorkflows.WorkflowsPartialState>,
    private workflowEditService: WorkflowEditService,
  ) {
  }

  // TODO: reconsider returning observable
  getAllWorkflows() {
    this.store.dispatch(WorkflowActions.loadWorkflows())
    return this.allWorkflows$;
  }

  getWorkflow(id: string) {
    this.store.dispatch(WorkflowActions.getWorkflow({id}))
  }

  selectWorkflow(workflowId: string) {
    this.store.dispatch(WorkflowActions.selectWorkflow({workflowId}))
  }

  clearSelectedWorkflow() {
    this.store.dispatch(WorkflowActions.clearSelectedWorkflow())
  }

  addWorkflow(name?: string) {
    const newWorkflow = this.workflowEditService.generateNewWorkflow(name);
    this.store.dispatch(WorkflowActions.createWorkflow({request: newWorkflow}));

    // TODO: consider adding first step here
    // const firstStep = this.workflowEditService.generateFirstStep(newWorkflow.uuid);

  }

  addWorkflowInGroup(name?: string, WorkflowGroupUuid?: string) {
    const newWorkflow = this.workflowEditService.generateNewWorkflow(name);

    this.store.dispatch(WorkflowActions.createWorkflow({
      request: {
        ...newWorkflow,
        workflowGroup: `/workflow_groups/` + WorkflowGroupUuid
      }
    }));
    // TODO: consider adding first step here
    // const firstStep = this.workflowEditService.generateFirstStep(newWorkflow.uuid);
  }

  deleteWorkflow(id: string) {
    this.store.dispatch(WorkflowActions.deleteWorkflow({id}))
  }


  deleteSelectedWorkflow() {
    this.selectedWorkflowId$.pipe(
      filter(id => !!id),
      take(1)
    ).subscribe(id => this.deleteWorkflow(id))
  }

  editFirstStep(workflowUuid: string, firstStepUuid: string) {
    this.selectedWorkflowFirstStepUuid$.pipe(
      take(1)
    ).subscribe(id => {
      if (id !== firstStepUuid) {
        this.store.dispatch(WorkflowActions.editFirstStep({
          workflowUuid, firstStepUuid,
        }))

      }
    })
  }

  renameWorkflow(name: string) {
    this.selectedWorkflowId$.pipe(
      filter(uuid => !!uuid),
      take(1)
    ).subscribe(uuid => {
      console.log('asad', uuid);
      this.store.dispatch(WorkflowActions.editName({
        workflowUuid: uuid, name,
      }))
    });

  }


}
