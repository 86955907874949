import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {Decision, DecisionRequest, WorkflowStep} from '@workflow-admin/shared/workflow/utils/workflow';

import * as StepsActions from './steps.actions';

export const STEPS_FEATURE_KEY = 'selectedWorkflowSteps';

export interface State extends EntityState<WorkflowStep> {
  selectedId?: string; // which Steps record has been selected
  loaded: boolean; // has the Steps list been loaded
  error?: string | null; // last known error (if any)
}

export interface StepsPartialState {
  readonly [STEPS_FEATURE_KEY]: State;
}

//TODO: consider using @id
export const stepsAdapter: EntityAdapter<WorkflowStep> = createEntityAdapter<
  WorkflowStep
>({ selectId: step => step.uuid });

export const initialState: State = stepsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  error: null,
});

const stepsReducer = createReducer(
  initialState,
  on(StepsActions.updateDecisionNextStep, (state, {nextStepUuid, decision }) => {
    const update: Update<Decision> = { id: decision.uuid, changes: { nextStepUuid: nextStepUuid }}
    console.log('doUpdate', state);
    return stepsAdapter.updateOne(update, state)
  }),
  on(StepsActions.loadStepsSuccess, (state, { steps }) => {
    const reducedSteps = steps.map(step => ( {...step, input: step.input && step.input['@id']}))
    return stepsAdapter.setAll(reducedSteps, { ...state, loaded: true })
  }),
  on(StepsActions.loadStepsFailure, (state, { error }) => ({ ...state, error })),
  on(StepsActions.selectStep, (state, {stepUuid}) => {
      return {...state, selectedId: stepUuid}
    }
  ),
  on(StepsActions.clearSelectedStep, (state) => {
      return {...state, selectedId: null}
    }
  ),
  on(StepsActions.clearSteps, () =>
    stepsAdapter.removeAll({ ...initialState })
  ),
  on(StepsActions.addStepAndUpdateParent,(state, {step}) =>
    stepsAdapter.addOne({...step, decisions: []}, state)
  ),
  on(StepsActions.createStepSuccess,  StepsActions.getStepSuccess,(state, {step}) =>
    stepsAdapter.setOne(step, state)
  ),
  on(StepsActions.editStep,(state, {id, request}) => {
    const stepUpdate: Update<WorkflowStep> = {
      id,
      changes: {
        ...request
      }
    }
    return stepsAdapter.updateOne(stepUpdate, state);
  }),
  on(StepsActions.editStepSuccess,(state, {step}) => {
    const {decisions, ...changes} = step;
      const stepUpdate: Update<WorkflowStep> = {
        id: step.uuid,
        changes
      }
      return stepsAdapter.updateOne(stepUpdate, state);
  }),
  on(StepsActions.deleteStep, StepsActions.deleteStepSuccess, (state, {id}) =>
    stepsAdapter.removeOne(id, state)
  ),
  on(StepsActions.addStepInBetween, (state, {step, newStepDecision}) =>
    stepsAdapter.addOne({...step, decisions: [newStepDecision]}, state)
  ),
  on(StepsActions.addStepInBetweenSuccess, (state, {step}) =>
    stepsAdapter.setOne(step, state)
  ),
  on(StepsActions.createDecision, (state, {decision }) => {
      const updatedStep = addDecision(state, decision)
      return stepsAdapter.setOne(updatedStep, state);
    }
  ),
  on(StepsActions.deleteDecision, (state, {stepId, decisionId}) => {
    const update = deleteDecision(state, stepId, decisionId)
    return stepsAdapter.updateOne(update, state)
  }),
  on(StepsActions.updateDecisions, (state, {stepId, dialogDecisions}) => {
    console.log('steps.reducer::updateDecisions',[state, {stepId, dialogDecisions}])
    const update: Update<WorkflowStep> = { id: stepId, changes: { decisions: dialogDecisions }}
    return stepsAdapter.updateOne(update, state)
  })
);

export function reducer(state: State | undefined, action: Action) {
  return stepsReducer(state, action);
}

function addDecision(state: State, decisionRequest: DecisionRequest): WorkflowStep {
  const stepId = decisionRequest.step.split("/").pop();
  const step = state.entities[stepId];

  const {nextStep, ...decision} = decisionRequest;

  const reducedDecision = [
    {...decisionRequest,
      step: decisionRequest.step['@id'],
      nextStepUuid: nextStep.split("/").pop(),
      conditionSets: []
    }];

  return {...step, decisions: [...step.decisions, ...reducedDecision]}
}

function deleteDecision(state: State, stepId: string, decisionId: string): Update<WorkflowStep> {
  const step = state.entities[stepId];
  const updatedDecisions = step.decisions.filter(decision => decision.uuid !== decisionId)
  return {
    id: stepId,
    changes: {
      decisions: updatedDecisions
    }
  }
}
