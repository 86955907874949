import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faChevronDown, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import {GenerateUuidService} from "@workflow-admin/shared/utils";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {Field, QuestionType, WorkflowAction} from '@workflow-admin/shared/workflow/utils/workflow';
import {ActionType} from "../../../../../../../workflow/utils/workflow/src/lib/models/action-type.enum";

@Component({
  selector: 'workflow-admin-edit-action',
  templateUrl: './edit-action.component.html',
  styleUrls: ['./edit-action.component.scss']
})

export class EditActionComponent implements OnInit, OnDestroy {
  @Input() action: WorkflowAction;
  @Input() isQuestionSet: boolean;
  @Output() actionChanged = new EventEmitter<Field>();

  public actionEditForm: FormGroup;
  public faChevronDown = faChevronDown;
  public faTrashAlt = faTrashAlt;
  public questionTypes = Object.values(ActionType);
  public canAddAnswer = false;
  public showAnswersControls = false;

  private unsubscribe$ = new Subject();

  constructor( private generateUuidService: GenerateUuidService) { }

  ngOnInit(): void {
    this.initializeActionEditForm();
    this.listenForActionEditFormChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // public addAnswer(): void {
  //   this.answersControl.push(new FormControl(''))
  // }
  //
  // public deleteAnswer(index: number): void {
  //   this.answersControl.removeAt(index);
  // }
  public addFieldAttribute(): void {
    this.attributeControl.push(this.getFieldAttributeForm())
  }

  public deleteFieldAttribute(index: number): void {
    this.attributeControl.removeAt(index);
    // console.log(this.attributeControl);
  }

  private initializeActionEditForm(): void {
    this.actionEditForm = this.getActionEditForm();

    if(this.action){
      const {...partialQuestion} = this.action;
      this.actionEditForm.patchValue(partialQuestion);
    }

    if(this.action?.actionAttributes?.length) {
      this.action.actionAttributes.forEach(option => {
        this.attributeControl.push(
          new FormGroup({
            uuid: new FormControl(option.uuid),
            name: new FormControl(option.name),
            value: new FormControl(option.value, {updateOn: 'blur'}),
          })

        )

      })
    }
  }

  private getFieldAttributeForm(): FormGroup {
    return new FormGroup({
      uuid: new FormControl(this.generateUuidService.generateUuid()),
      name: new FormControl(''),
      value: new FormControl('', {updateOn: 'blur'}),
    })
  }

  private getActionEditForm(): FormGroup {
    return new FormGroup({
      uuid: new FormControl('', Validators.required),
      position: new FormControl(''),
      actionType: new FormControl('', Validators.required),
      label: new FormGroup({
        show: new FormControl(true),
        text: new FormControl('')
      }),
      //valueOptions: new FormArray([]),
        actionAttributes: new FormArray([]),
      required: new FormControl(true),
    })
  }

  private listenForActionEditFormChanges(): void {
    this.actionEditForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(formValue => {
      console.log('listenForActionEditFormChanges',formValue);
      this.actionChanged.emit(formValue);
    })

    //@todo: specific fields by action
    // this.actionEditForm.get('actionType').valueChanges.pipe(
    //   takeUntil(this.unsubscribe$)
    // ).subscribe(type => this.initializeActionAnswers(type))
  }


  private initializeActionAnswers(type: string): void {
    // @todo change to required fields??
    /*
    this.answersControl.clear();

    switch (type) {
      case QuestionType.Boolean:
        this.answersControl.push(new FormControl({ value: 'ja', disabled: false }));
        this.answersControl.push(new FormControl({ value: 'nee', disabled: false }));
        this.canAddAnswer = false;
        this.showAnswersControls = true;
        break;
      case QuestionType.Radio:
      case QuestionType.CheckboxSingle:
      case QuestionType.CheckboxMultiple:
        this.answersControl.push(new FormControl(''));
        this.answersControl.push(new FormControl(''));
        this.canAddAnswer = true;
        this.showAnswersControls = true;
        break;
      case QuestionType.Text:
      case QuestionType.Integer:
      case QuestionType.Float:
      case QuestionType.Date:
      case QuestionType.Time:
      case QuestionType.DateTime:
      default:
        this.canAddAnswer = false;
        this.showAnswersControls = false;
        break;
    }*/
  }

  get answersControl(): FormArray {
    return this.actionEditForm.controls.answersControl as FormArray;
  }
  get attributeControl(): FormArray {
    return this.actionEditForm.controls.actionAttributes as FormArray; //@todo
  }

  get alternativeMessage(): string {
    const type = this.actionEditForm.get('type').value;
    switch (type) {
      case QuestionType.Integer:
        return 'Open vraag (getal)'
      case QuestionType.Text:
        return 'Open vraag (tekst)'
      case QuestionType.Date:
        return 'Open vraag (datum)'
      default:
        return undefined
    }
  }

}
