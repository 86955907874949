import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faChevronDown,
  faSearch,
} from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { StepEditService, StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { WorkflowsFacade } from '@workflow-admin/shared/workflow/data-access/workflow';
import {
  Decision,
  DecisionConditionSet,
  WorkflowStep
} from '@workflow-admin/shared/workflow/utils/workflow';

import { ConditionSetFormResult } from '../edit-condition-set/edit-condition-set-container.component';

export interface DecisionEditFormResult {
  name: string;
  nextStepUuid: string;
  conditionSets: DecisionConditionSet[];
}

@Component({
  selector: 'workflow-admin-edit-decision',
  templateUrl: './edit-decision-container.component.html',
  styleUrls: ['./edit-decision-container.component.scss']
})
export class EditDecisionContainerComponent implements OnInit, OnDestroy {
  @Input() steps: WorkflowStep[];
  @Input() sortedSteps: any;
  @Input() decision: Decision;
  @Input() currentStepUuid: string;
  @Output() decisionChanged = new EventEmitter<DecisionEditFormResult>();
  @Output() addingNewStep = new EventEmitter<boolean>();
  @Output() formValidation = new EventEmitter<boolean>();

  public decisionEditForm = new FormGroup({
    name: new FormControl('', Validators.required),
    nextStepUuid: new FormControl('', Validators.required),
    conditionSets: new FormControl([]),
  })

  public addingNewNextStep$ = new BehaviorSubject(false);
  public formValidion$ = new BehaviorSubject(false);
  public newNextStepName = '';

  public faChevronDown = faChevronDown;
  public faSearch = faSearch;

  private unsubscribe$ = new Subject();

  constructor(
    private workflowFacade: WorkflowsFacade,
    public stepsFacade: StepsFacade,
    private stepEditService: StepEditService) { }

  ngOnInit() {
    this.initializeDecisionForm();
    this.listenForDecisionEditFormChanges();

    this.addingNewNextStep$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(value => this.addingNewStep.emit(value));

        this.formValidion$.pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(value => {
          // console.log('formValidion$', value)
          this.formValidation.emit(value);
        }
        );

    // this.workflowFacade.selectedWorkflowId$.pipe(
    //   filter(id => !!id),
    //   take(1),
    //   switchMap(id => this.formsFacade.getFormsForWorkflow(id))
    // ).subscribe(inputs => console.log(inputs))
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addNewNextStep(): void {
    this.addingNewNextStep$.next(true);
  }

  public createNewNextStep(): void {
    this.stepsFacade.addNewNextStep(this.newNextStepName).pipe(
      filter(id => !!id),
      take(1)
    ).subscribe(nextStepUuid => {
      this.decisionEditForm.patchValue({nextStepUuid});
      this.addingNewNextStep$.next(false);
    })
  }

  public cancelCreateNewNextStep(): void {
    this.addingNewNextStep$.next(false);
  }

  public addConditionSet(): void {
    const newConditionSet = this.stepEditService.generateDecisionConditionSet(this.decision.uuid);
    const newCondition = this.stepEditService.generateDecisionCondition(newConditionSet.uuid);
    newConditionSet.conditions.push(newCondition);
    const currentConditionSets = this.conditionSets || [];
    this.decisionEditForm.patchValue({conditionSets: currentConditionSets.concat(newConditionSet)})
  }

  public removeConditionSet(index: number): void {
    const updatedConditionSets = [...this.conditionSets];
    updatedConditionSets.splice(index, 1);
    this.decisionEditForm.patchValue({conditionSets: updatedConditionSets});
  }

  public onConditionSetChanged(conditionSet: ConditionSetFormResult, index: number): void {
    const updatedConditionSets = [...this.conditionSets];
    updatedConditionSets.splice(index, 1, conditionSet);
    this.decisionEditForm.patchValue({conditionSets: updatedConditionSets});
  }

  get conditionSets(): DecisionConditionSet[] {
    return this.decisionEditForm?.get('conditionSets')?.value
  }

  private initializeDecisionForm(): void {
    this.decisionEditForm.patchValue({
      name: this.decision.name,
      nextStepUuid: this.decision.nextStepUuid,
      conditionSets: this.decision.conditionSets
    });
    this.formValidion$.next(this.decisionEditForm.valid);

  }

  private listenForDecisionEditFormChanges() {
    // TODO: consider alternative of emitting result ONLY on returning to main form
    this.decisionEditForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(formValue => {
      // console.log(this.decisionEditForm.valid);
      this.formValidion$.next(this.decisionEditForm.valid);
      this.decisionChanged.emit(formValue)

    } );
  }


}
