import {
  InputType,
  InputValueType,
  QuestionType,
  StepType,
  Workflow,
  WorkflowFull
} from '../shared/workflow/utils/workflow/src/lib/models';

export const demoFlow: WorkflowFull = {
  "@context": "/contexts/Workflow",
  "@id": "/workflows/0d24be04-d1ef-4f26-8177-595d198acc8g",
  "@type": "Workflow",
  "uuid": "0d24be04-d1ef-4f26-8177-595d198acc8g",
  "name": "Demo",
  "firstStepUuid": "9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
  "steps": [
    {
      "@id": "/steps/f049859d-8601-4b82-a1e7-4c717c166a3e",
      "@type": "Step",
      "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3e",
      "name": "Wie is de klant?",
      "type": StepType.QuestionSet,
      "decisions": [{
        "uuid": "c1939634-55c3-4050-8f08-ad470ac42bc6a",
        "position": 0,
        "name": "",
        "nextStepUuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
        "conditionSets": [],
        "step": "/steps/0d24be04-d1ef-4f26-8177-595d198acc8g"
      }],
      "input":   {
        "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9108",
        "@type": "Form",
        "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108",
        "name": "start_immediately",
        "fields": [
          {
            "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3d",
            "@type": "Field",
            "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3d",
            "position": 0,
            "type": QuestionType.Text,
            "label": {
              "show": true,
              "text": "Wat is je voornaam?"
            },
            "placeholder": {
              "show": false,
              "text": null
            },
            "valueOptions": [],
            "required": true,
            "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108"
          },
          {
            "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3e",
            "@type": "Field",
            "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3e",
            "position": 0,
            "type": QuestionType.Text,
            "label": {
              "show": true,
              "text": "Wat is je achternaam?"
            },
            "placeholder": {
              "show": false,
              "text": null
            },
            "valueOptions": [],
            "required": true,
            "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108"
          }
        ],
      },
    },
    {
      "@id": "/steps/f049859d-8601-4b82-a1e7-4c717c166a3c",
      "@type": "Step",
      "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
      "name": "Geeft de klant toestemming om de handeling te starten?",
      "type": StepType.Question,
      "decisions": [{
        "uuid": "c1939634-55c3-4050-8f08-ad470ac42bc6a",
        "position": 0,
        "name": "",
        "nextStepUuid": "9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
        "conditionSets": [],
        "step": "/steps/f049859d-8601-4b82-a1e7-4c717c166a3c"
      }],
      "input":    {
        "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
        "@type": "Form",
        "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
        "name": "start_immediately",
        "fields": [
          {
            "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3c",
            "@type": "Field",
            "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
            "position": 0,
            "type": QuestionType.Boolean,
            "label": {
              "show": true,
              "text": "Geeft de klant toestemming om de handeling te starten?"
            },
            "placeholder": {
              "show": false,
              "text": null
            },
            "valueOptions": ["ja", "nee"],
            "required": true,
            "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109"
          }
        ],
      },
    },
    {
      "@id": "/steps/9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
      "@type": "Step",
      "uuid": "9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
      "name": "Heb je toestemming?",
      "type": StepType.Decision,
      "input": null,
      "decisions":
        [
        {
          "@id": "/decisions/c1939634-55c3-4050-8f08-ad470ac42bc6",
          "@type": "Decision",
          "uuid": "c1939634-55c3-4050-8f08-ad470ac42bc6",
          "position": 0,
          "nextStepUuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8123",
          "name": "ja",
          "conditionSets": [
            {
              "@id": "/conditionSets/6e597780-b0cf-4f0f-94d5-02db4cc9b340",
              "@type": "ConditionSet",
              "uuid": "6e597780-b0cf-4f0f-94d5-02db4cc9b340",
              "conditions": [
                {
                  "@id": "/conditions/3b95d841-30ef-4614-b595-63236085f0cb",
                  "@type": "Condition",
                  "uuid": "3b95d841-30ef-4614-b595-63236085f0cb",
                  "type": InputType.Question,
                  "operator": "equal",
                  "modifier": null,
                  "property": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109.f049859d-8601-4b82-a1e7-4c717c166a3c",
                  "value": "ja",
                  "input": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
                  "valueType": InputValueType.String
                }
              ]
            }
          ],
          "step": "/steps/9ab94dca-34b2-499b-a23f-283bc0e0dd5a"
        },
        {
          "@id": "/decisions/46d480a9-5669-4d2e-aea3-5ac190ba6957",
          "@type": "Decision",
          "uuid": "46d480a9-5669-4d2e-aea3-5ac190ba6957",
          "position": 1,
          "nextStepUuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8456",
          "name": "nee",
          "conditionSets": [
            {
              "@id": "/conditionSets/c9a80939-f282-4f76-8900-55e4c688c0dc",
              "@type": "ConditionSet",
              "uuid": "c9a80939-f282-4f76-8900-55e4c688c0dc",
              "conditions": [
                {
                  "@id": "/conditions/526de47d-b32e-4ba3-ab2a-b98da31bf40a",
                  "@type": "Condition",
                  "uuid": "526de47d-b32e-4ba3-ab2a-b98da31bf40a",
                  "type": InputType.Question,
                  "operator": "equal",
                  "modifier": null,
                  "property": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109.f049859d-8601-4b82-a1e7-4c717c166a3c",
                  "value": "nee",
                  "input": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
                  "valueType": InputValueType.String
                }
              ]
            }
          ],
          "step": "/steps/9ab94dca-34b2-499b-a23f-283bc0e0dd5a"
        }
      ]
    },
    {
      "@id": "/steps/a5e72cf6-5078-467f-a34a-b4a6f81863c8",
      "@type": "Step",
      "type": StepType.Action,
      "uuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8123",
      "name": "Start handeling",
      "decisions": [],
      "input": null,
    },
    {
      "@id": "/steps/a5e72cf6-5078-467f-a34a-b4a6f81863c8",
      "@type": "Step",
      "type": StepType.Action,
      "uuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8456",
      "name": "Vraag toestemming",
      "decisions": [],
      "input": null,
    },
  ],
}
