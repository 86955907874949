<form [formGroup]="decisionEditForm">
  <mat-tab-group>

    <mat-tab label="Algemeen">
      <div class="edit-decision">
        <mat-form-field appearance="fill" class="edit-decision__input edit-decision__name-input">
          <mat-label>Titel</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>

      <div class="edit-decision">
        <ng-container formArrayName="conditionSets" *ngIf="conditionSets?.length; else addConditionButton">
          <ng-container *ngFor="let conditionSet of conditionSets; index as i">
            <div *ngIf="i > 0" class="edit-decision__condition-set--boolean">Of</div>
            <workflow-admin-edit-condition-set
              [conditionSet]="conditionSet"
              (removeConditionSet)="removeConditionSet(i)"
              (conditionSetChanged)="onConditionSetChanged($event, i)"
            ></workflow-admin-edit-condition-set>
          </ng-container>
          <button mat-stroked-button color="primary" class="edit-decision__add-condition-button small" (click)="addConditionSet()">+ Of</button>
        </ng-container>

        <ng-template #addConditionButton>
          <button mat-stroked-button color="primary" class="edit-decision__add-condition-button" (click)="addConditionSet()">+ Conditie toevoegen</button>
        </ng-template>
      </div>
    </mat-tab>

    <mat-tab label="Vervolgstap">
      <ng-container *ngIf="!(addingNewNextStep$ | async); else showAddNewNextStep">
        <div class="edit-decision">
          <mat-form-field appearance="fill" class="edit-decision__add-related-step-input">
            <mat-label>Volgende stap</mat-label>
            <fa-icon [icon]="faSearch" matPrefix class="edit-decision__add-related-step-search-icon"></fa-icon>
            <mat-select formControlName="nextStepUuid" placeholder="Kies" required>

              <mat-option  *ngFor="let stepSorted of this.sortedSteps" [value]="stepSorted.step.uuid" [class.optionHasItems]="stepSorted.position > 0" [class.optionDisabled]="stepSorted.step.uuid == currentStepUuid" [disabled]="stepSorted.step.uuid == currentStepUuid">
                {{stepSorted.step.name || stepSorted.step.uuid}}  <span class="tag" [class]=" stepSorted.step.type">{{ stepSorted.step.type }}</span>
              </mat-option>
            </mat-select>
            <fa-icon [icon]="faChevronDown" matSuffix class="edit-decision__chevron-down"></fa-icon>
          </mat-form-field>
          <button
            class="edit-decision__add-new-next-step-button"
            mat-stroked-button
            color="primary"
            (click)="addNewNextStep()">
            + Nieuwe stap toevoegen
          </button>
        </div>

      </ng-container>

      <ng-template #showAddNewNextStep>
        <div class="edit-decision__add-new-next-step-container">
          <mat-form-field appearance="fill">
            <input matInput placeholder="Nieuwe naam" [(ngModel)]="newNextStepName" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          <div class="edit-decision__add-new-next-step-buttons">
            <button
              class="edit-decision__add-new-next-step-buttons-cancel"
              mat-stroked-button
              color="primary"
              (click)="cancelCreateNewNextStep()">
              Annuleren
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="createNewNextStep()">
              Toevoegen
            </button>
          </div>
        </div>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
</form>

<!-- Old code with more complex autocomplete and drag and drop functionality. Can be deleted if not needed later -->
<!--        <div class="edit-step-label">-->
<!--            Volgende stap-->
<!--          </div>-->

<!--          <div *ngIf="dialogData.step.decisions.length > 1; else nextStep" cdkDropList class="drag-drop-list" (cdkDropListDropped)="drop($event)">-->
<!--            <div class="drag-drop-item" *ngFor="let decision of dialogData.step.decisions" cdkDrag>-->
<!--              <fa-icon class="drag-drop-icon" [icon]="faBars"></fa-icon>-->
<!--              <span class="drag-drop-item__label">{{decision.nextStepUuid}}</span>-->
<!--              <fa-icon class="edit-related-step-icon" [icon]="faTrashAlt"></fa-icon>-->
<!--            </div>-->
<!--          </div>-->

<!--          <ng-template #nextStep>-->
<!--            <div>-->
<!--              <div class="drag-drop-item no-drag" *ngFor="let decision of dialogData.step.decisions">-->
<!--                <fa-icon class="drag-drop-icon" [icon]="faBars"></fa-icon>-->
<!--                <span class="drag-drop-item__label">{{decision.nextStepUuid}}</span>-->
<!--                <fa-icon class="edit-related-step-icon" [icon]="faTrashAlt"></fa-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->

<!--          <div class="add-related-step">-->
<!--            <mat-form-field appearance="fill" class="add-related-step__input">-->
<!--              <fa-icon [icon]="faSearch" matPrefix class="add-related-step__search-icon"></fa-icon>-->
<!--              <input type="text"-->
<!--                     placeholder="Kies"-->
<!--                     aria-label="Number"-->
<!--                     matInput-->
<!--                     [formControl]="nextStepAutocomplete"-->
<!--                     [matAutocomplete]="auto">-->
<!--              <fa-icon [icon]="faChevronDown" matSuffix></fa-icon>-->
<!--              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">-->
<!--                <mat-option *ngFor="let step of filteredNextSteps | async" [value]="step">-->
<!--                  {{step.name}}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
<!--            <button class="add-related-step__button" mat-button (click)="addStep()">+</button>-->
<!--          </div>-->

