import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '@workflow-admin/shared/data-access';
import { Workflow, WorkflowFull, WorkflowRequest } from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class WorkflowApiService {

  baseUrl = this.apiService.apiUrl

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getWorkflows(): Observable<Workflow[]> {
    return this.http.get<Workflow[]>(`${this.baseUrl}/workflows`).pipe(
    // return this.http.get<Workflow[]>('api/workflows').pipe(
      map(response => response['hydra:member']),
      catchError(this.handleError)
    );
  }

  getWorkflowFull(id: string): Observable<WorkflowFull>{
    return this.http.get<WorkflowFull>(`${this.baseUrl}/workflows/${id}/full`).pipe(
      catchError(this.handleError)
    );
  }

  createWorkflow(workflow: WorkflowRequest): Observable<Workflow> {
    return this.http.post<Workflow>(`${this.baseUrl}/workflows`, workflow).pipe(
      catchError(this.handleError)
    )
  }

  deleteWorkflow(id: string) {
    return this.http.delete(`${this.baseUrl}/workflows/${id}`).pipe(
      catchError(this.handleError)
    )
  }

  editFirstStep(workflowId: string, firstStepId: string): Observable<Workflow> {
    return this.http.put<Workflow>(`${this.baseUrl}/workflows/${workflowId}`, { firstStep: firstStepId }).pipe(
      catchError(this.handleError)
    )
  }

  editPosition(workflowId: string, position: number): Observable<Workflow> {
    return this.http.put<Workflow>(`${this.baseUrl}/workflows/${workflowId}`, { position: position }).pipe(
      catchError(this.handleError)
    )
  }
  editName(workflowId: string, name: string): Observable<Workflow> {
    return this.http.put<Workflow>(`${this.baseUrl}/workflows/${workflowId}`, { name: name }).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
