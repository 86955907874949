import { createAction, props } from '@ngrx/store';

import {Field, WorkflowAction, WorkflowForm, WorkflowFormRequest} from '@workflow-admin/shared/workflow/utils/workflow';

export const loadAllForms = createAction('[Forms] Load All Forms');

export const loadAllFormsSuccess = createAction(
  '[Forms] Load All Forms Success',
  props<{ forms: WorkflowForm[] }>()
);

export const loadAllFormsFailure = createAction(
  '[Forms] Load All Forms Failure',
  props<{ error: any }>()
);

export const getForm = createAction(
  '[Forms] Get Form',
  props<{id: string}>()
);

export const getFormSuccess = createAction(
  '[Forms] Get Form Success',
  props<{ form: WorkflowForm }>()
);

export const getFormFailure = createAction(
  '[Forms] Get Form Failure',
  props<{ error: any }>()
);

export const selectForm = createAction(
  '[Forms] Select Form',
  props<{formUuid: string}>()
);

export const clearSelectedForm = createAction(  '[Forms] Clear Selected Form');

export const createForm = createAction(
  '[Forms] Create Form',
  props<{
    stepUuid: string,
    form: WorkflowFormRequest,
    questions: Field[]
  }>()
);

export const createFormSuccess = createAction(
  '[Forms] Create Form Success',
  props<{ stepUuid: string, form: WorkflowForm }>()
);

export const loadFormsForWorkflowSuccess = createAction(
  '[Selected Workflow => Forms] Load Forms Success',
  props<{ forms: WorkflowForm[] }>()
);

export const editForm = createAction(
  '[Forms] Edit Form',
  props<{form: WorkflowFormRequest}>()
)

export const editFormSuccess = createAction(
  '[Forms] Edit Form Success',
  props<{form: WorkflowForm}>()
)

export const deleteForm = createAction(
  '[Forms] Delete Form',
  props<{id: string}>()
)

export const deleteFormSuccess = createAction(
  '[Forms] Delete Form Success',
  props<{id: string}>()
)

export const createQuestions = createAction(
  '[Forms] Create Questions',
  props<{ questions: Field[], formId: string }>()
)

export const createQuestionsSuccess = createAction(
  '[Forms] Create Questions Success',
)

export const editQuestions = createAction(
  '[Forms] Edit Questions',
  props<{ form: string, currentQuestions: Field[], updatedQuestions: Field[] }>()
)

export const editQuestionsSuccess = createAction(
  '[Forms] Edit Questions Success',
)

export const editQuestionFailure = createAction(
  '[Forms] Edit Question Failure',
  props<{ error: any }>()
)


