import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { WorkflowStep } from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-edit-first-step-dialog',
  templateUrl: './delete-step-confirm-dialog.component.html',
  styleUrls: ['./delete-step-confirm-dialog.component.scss']
})
export class DeleteStepConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {step: WorkflowStep, steps: WorkflowStep[]},
  ) { }

  ngOnInit(): void {
  }
}
