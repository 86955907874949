import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {faArrowLeft, faChevronDown, faSearch, faTimes, faTrashAlt} from '@fortawesome/pro-light-svg-icons';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';

import {StepEditService, StepsFacade} from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import {EditStepDialogData} from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import {FormsFacade} from '@workflow-admin/shared/input/data-access/input';
import {ActionsFacade} from '@workflow-admin/shared/input/data-access/input';
import {GenerateUuidService} from '@workflow-admin/shared/utils';
import {
  Decision,
  Field,
  ListItem,
  StepType
} from '@workflow-admin/shared/workflow/utils/workflow';
import * as StepActions from "../../../../../../data-access/flow-chart/src/lib/+state/steps/steps.actions";

import {DecisionEditFormResult} from '../edit-decision/edit-decision-container.component';

@Component({
  selector: 'workflow-admin-edit-step-dialog-container',
  templateUrl: './edit-step-dialog-container.component.html',
  styleUrls: ['./edit-step-dialog-container.component.scss']
})
export class EditStepDialogContainerComponent implements OnInit, OnDestroy {
  public stepEditForm: FormGroup;
  BehaviorSubject
  public stepType = StepType;
  public stepTypes = Object.values(StepType);

  public showNextStep$ = new BehaviorSubject(false);
  public addingNewNextStep$ = new BehaviorSubject(false);
  public formValidated$ = new BehaviorSubject(true);
  // public formValid = new BehaviorSubject(false);
  public showOptionDetails$ = new BehaviorSubject(false);
  public showAnswersTab$ = new BehaviorSubject(false);
  public showQuestionDetails$ = new BehaviorSubject(false);
  public showTabGroup$ = combineLatest([this.showOptionDetails$, this.showQuestionDetails$]).pipe(map(([showOption, showQuestion]) => !showOption && !showQuestion))

  public selectedTabIndex = 0;
  public selectedOption$ = new BehaviorSubject<Decision>(undefined);
  public selectedQuestion$ = new BehaviorSubject<Field>(undefined);

  public newNextStepName = '';
  public sortedSteps= null;

  public faTrashAlt = faTrashAlt;
  public faClose = faTimes;
  public faChevronDown = faChevronDown;
  public faSearch = faSearch;
  public faArrowLeft = faArrowLeft;

  private unsubscribe$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: EditStepDialogData,
    private dialogRef: MatDialogRef<EditStepDialogContainerComponent>,
    private generateUuidService: GenerateUuidService,
    private formsFacade: FormsFacade,
    private actionFacade: ActionsFacade,
    public stepsFacade: StepsFacade,
    private stepEditService: StepEditService
  ) {
  }

  ngOnInit(): void {
    this.stepEditForm = new FormGroup({
      uuid: new FormControl(''),
      type: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      nextStepUuid: new FormControl(''),
      decisions: new FormControl([]),
      input: new FormControl(''),
      info: new FormControl(''),
      questions: new FormControl([]),
      workflowAction: new FormControl({}),
    })

    this.initializeStepEditForm();
    this.listenForStepEditFormChanges();
    this.getInput();
    this.stepsFacade.sortedStepsByUsage().subscribe((steps) => {
      this.sortedSteps = steps;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // this.formsFacade.clearSelectedForm();
  }

  public addLinkToNextStep(): void {
    this.showNextStep$.next(true);
  }

  public addNewNextStep(): void {
    this.addingNewNextStep$.next(true);
  }

  public createNewNextStep(): void {
    this.stepsFacade.addNewNextStep(this.newNextStepName).pipe(
      filter(id => !!id),
      take(1)
    ).subscribe(nextStepUuid => {
      this.stepEditForm.patchValue({nextStepUuid});
      this.addingNewNextStep$.next(false);
    })
  }

  public cancelCreateNewNextStep(): void {
    this.addingNewNextStep$.next(false);
  }

  public onAddingNewStep(value: boolean): void {
    this.addingNewNextStep$.next(value)
  }
  public onFormValidation(value: boolean): void {
    this.formValidated$.next(value)
  }

  public removeLinkToNextStep(): void {
    this.showNextStep$.next(false);
    this.stepEditForm.controls.nextStepUuid.patchValue('');
    this.stepEditForm.controls.decisions.patchValue([])
  }

  public deleteItem(): void {
    if (this.showOptionDetails$.value) {
      this.deleteOption();
    } else if (this.showQuestionDetails$.value) {
      this.deleteQuestion();
    } else {
      this.deleteStep();
    }
  }

  public editItem(): void {
    this.showTabGroup$.pipe(
      take(1)
    ).subscribe(showTabGroup => {
        if (showTabGroup) {
          return this.dialogRef.close(this.dialogResult)
        } else {
          this.returnToTabGroup();
        }
      }
    )
  }

  private initializeStepEditForm(): void {
    if (this.dialogData.step) {


      this.stepEditForm.patchValue({
        uuid: this.dialogData.step.uuid,
        type: this.dialogData.step.type,
        name: this.dialogData.step.name,
        info: this.dialogData.step.info,
      })
    }

    if (this.dialogData.step.type === this.stepType.Question) {
      this.showAnswersTab$.next(true);
      if (!this.stepEditForm.get('questions').value?.length) {
        this.addNewQuestion();
      }
    }

    if (this.dialogData.step?.decisions?.length === 1) {
      this.stepEditForm.patchValue({
        nextStepUuid: this.dialogData.step.decisions[0].nextStepUuid
      });
      this.showNextStep$.next(true);
    }

    if (this.dialogData.step?.decisions?.length) {
      const sortedDecision = this.sortItemsByPosition(this.dialogData.step.decisions as ListItem[])
      this.stepEditForm.patchValue({
        decisions: sortedDecision
      });
      this.showNextStep$.next(true);
    }

    if (this.dialogData.step?.input) {
      // console.log('this.dialogData.step.input', [ this.dialogData.step.input ]);
      this.stepEditForm.patchValue({input: this.dialogData.step.input})
    }
    this.actionFacade.allActions$.pipe(filter(action => !!action),
      take(1)
    ).subscribe(actions => {
      console.log(' this.actionFacade.allActions$', actions[0])
      this.stepEditForm.patchValue({
        workflowAction: actions[0]
      });
    });
    // this.actionFacade.selectedActions$.pipe(filter(action => !!action),
    //   take(1)
    // ).subscribe(action => {
    //   console.log(' this.actionFacade.selectedActions$', action)
    //   this.stepEditForm.patchValue({
    //     workflowAction: action
    //   });
    // });

    // also check if input is of type questionSet?
    this.formsFacade.selectedFormQuestions$.pipe(
      filter(questions => !!questions),
      take(1)
    ).subscribe(questions => {
      const sortedQuestions = this.sortItemsByPosition(questions as ListItem[]);
      this.stepEditForm.patchValue({
        questions: sortedQuestions
      })
    })
  }

  private listenForStepEditFormChanges(): void {
    this.stepEditForm.get('type').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(type => {
      switch (type) {
        case this.stepType.Question:
          this.showAnswersTab$.next(true);

          if (this.stepEditForm.get('questions')?.value?.length) {
            const firstQuestion = this.stepEditForm.get('questions').value[0];
            this.stepEditForm.patchValue({
              questions: [firstQuestion],
              name: firstQuestion.label?.text
            })
          } else {
            this.addNewQuestion();
          }

          if (![this.stepType.Question, this.stepType.QuestionSet].includes(this.dialogData.step.type as StepType)) {
            this.stepEditForm.get('input').reset();
          }
          break;
        case this.stepType.QuestionSet:
          this.showAnswersTab$.next(false);
          if (![this.stepType.Question, this.stepType.QuestionSet].includes(this.dialogData.step.type as StepType)) {
            this.stepEditForm.get('input').reset();
          }
          break;
        case this.stepType.Action:
          this.showAnswersTab$.next(false);
          this.stepEditForm.get('input').reset();
          if (this.dialogData.step.type as StepType !== this.stepType.Action) {
            this.stepEditForm.get('input').reset();
          }
          break;
        case this.stepType.Info:
          this.showAnswersTab$.next(false);
          this.stepEditForm.get('questions').reset();
          if (this.dialogData.step.type as StepType !== this.stepType.Info) {
            this.stepEditForm.get('input').reset();
          }
          break;
        default:
          this.showAnswersTab$.next(false);
          this.stepEditForm.get('questions').reset();
          this.stepEditForm.get('input').reset();
      }
    });

    this.stepEditForm.get('nextStepUuid').valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(nextStepUuid => this.onNextStepUuidChanged(nextStepUuid))
  }

  private getInput() {
    if (!this.dialogData.step.input) return;

    if ([this.stepType.Question, this.stepType.QuestionSet].includes(this.dialogData.step.type as StepType)) {
      this.formsFacade.selectForm(this.dialogData.step.input.split("/").pop())
    }
    // if([this.stepType.Action].includes(this.dialogData.step.type as StepType)) {
    //   this.formsFacade.selectForm(this.dialogData.step.input.split("/").pop())
    // }
  }

  private onNextStepUuidChanged(nextStepUuid) {
    const newDecision = this.generateNewDecision();
    const patchedDecision = {...newDecision, nextStepUuid: nextStepUuid}
    this.stepEditForm.patchValue({decisions: [patchedDecision]});
  }

  private deleteStep(): void {

    this.dialogRef.close({deleteStep: true, uuid: this.dialogData.step.uuid});
  }

  public debugStep() {
    if (this.dialogData.step.decisions.length === 1) {
      const nextStepUuid = this.dialogData.step.decisions[0].nextStepUuid;
      this.stepsFacade.getReferringDecisions(this.dialogData.step.uuid).pipe(
        filter(decisions => !!decisions),
        take(1)
      ).subscribe(decisions => {
        decisions.forEach(decision => console.log('decision', decision));
      });
    }
    this.stepEditForm.controls.decisions.patchValue([]);
  }


  private sortItemsByPosition(items: ListItem[]): ListItem[] {
    return [...items].sort((prev, next) => (next.position > prev.position) ? -1 : 1)
  }

  // NAVIGATION

  public returnToTabGroup(): void {
    if (this.showOptionDetails$.value) {
      this.returnToOptionsTab()
    }

    if (this.showQuestionDetails$.value) {
      this.returnToAnswersTab()
    }
  }

  private returnToOptionsTab(): void {
    this.selectedTabIndex = 1;
    this.showOptionDetails$.next(false);
    this.selectedOption$.next(undefined);
  }

  private returnToAnswersTab(): void {
    this.selectedTabIndex = 1;
    this.showQuestionDetails$.next(false);
    this.selectedQuestion$.next(undefined);
  }



  // DECISIONS

  public addNewOption(): void {
    const currentDecisions: Decision[] = this.decisions;
    const newDecision = this.generateNewDecision();
    this.stepEditForm.patchValue({decisions: currentDecisions.concat(newDecision)});
    this.editOption(newDecision);
  }

  public onDuplicateItem(item: Decision): void {
    const currentDecisions: Decision[] = this.decisions;
    const newPosition = currentDecisions?.length || 0;
    const stepUuid = this.dialogData.step.uuid
    const newDecision = this.stepEditService.cloneDecision(stepUuid, item, newPosition)
    this.stepEditForm.patchValue({decisions: currentDecisions.concat(newDecision)});
  }
  public onOptionSelected(item: ListItem): void {
    const selectedOption = this.decisions.find(decision => decision.uuid === item.uuid);
    this.editOption(selectedOption);
  }

  public onOptionMoved(items: ListItem[]) {
    const sortedDecisions = this.sortItemsByPosition(items);
    this.stepEditForm.patchValue({
      decisions: sortedDecisions
    })
  }

  public onDecisionChanged(result: DecisionEditFormResult): void {
    // console.log('esdc-onDecisionChanged', result)
    const decisions: Decision[] = this.decisions;
    const selectedDecision = this.selectedOption$.value;
    const updatedDecisions = decisions.map(decision => {
      if (decision.uuid !== selectedDecision.uuid) {
        return decision;
      }
      return {...decision, conditionSets: result.conditionSets, name: result.name, nextStepUuid: result.nextStepUuid}
    })
    const sortedDecisions = this.sortItemsByPosition(updatedDecisions as ListItem[ ]);

    this.stepEditForm.patchValue({decisions: sortedDecisions})
  }

  private editOption(decision: Decision) {
    this.selectedOption$.next(decision);
    this.showOptionDetails$.next(true);
  }

  private deleteOption(): void {
    const currentDecisions: Decision[] = this.decisions;
    const selectedOption = this.selectedOption$.value.uuid;
    const filteredDecisions = currentDecisions.filter(decision => decision.uuid !== selectedOption);
    this.stepEditForm.patchValue({decisions: filteredDecisions});
    // this.dialogRef.close(this.dialogResult);
    this.returnToTabGroup();
  }

  private generateNewDecision(): Decision {
    const currentDecisions: Decision[] = this.decisions;
    const newPosition = currentDecisions?.length || 0;
    const stepUuid = this.dialogData.step.uuid

    return this.stepEditService.generateDecision(stepUuid, undefined, newPosition)
  }

  // FORMS AND QUESTIONS

  public onAddQuestion(): void {
    const newQuestion = this.addNewQuestion();
    this.editQuestion(newQuestion)
  }

  public onQuestionSelected(item: ListItem): void {
    const selectedQuestion = this.questions.find(question => question.uuid === item.uuid);
    this.editQuestion(selectedQuestion);
  }

  public onQuestionMoved(items: ListItem[]): void {
    const sortedQuestions = this.sortItemsByPosition(items);
    this.stepEditForm.patchValue({
      questions: sortedQuestions
    })
  }
  public onQuestionDuplicate(duplicateQuestion: Field): void {
    //@todo
    const currentQuestions = this.questions;
    const newQuestion = this.generateQuestion();
    const questionDuplicated =  {...duplicateQuestion, uuid:newQuestion.uuid};;
    this.stepEditForm.patchValue({questions: currentQuestions.concat(questionDuplicated)});
  }


  public onQuestionChanged(questionEditResult: Field): void {
    const currentQuestions: Field[] = this.questions;
    let updatedQuestions;

    if (!questionEditResult.uuid) {
      const newQuestionId = this.generateUuidService.generateUuid();
      updatedQuestions = currentQuestions.concat({...questionEditResult, uuid: newQuestionId});
    } else {
      updatedQuestions = currentQuestions.map(question => {
        if (question.uuid !== questionEditResult.uuid) {
          return question
        } else {
          return questionEditResult;
        }
      })
    }
    const sortedQuestions = this.sortItemsByPosition(updatedQuestions as ListItem[ ]);

    this.stepEditForm.patchValue({questions: sortedQuestions})

  }

  private addNewQuestion(): Field {
    const currentQuestions = this.questions;
    const newQuestion = this.generateQuestion();
    this.stepEditForm.patchValue({questions: currentQuestions.concat(newQuestion)});
    return newQuestion;
  }

  private editQuestion(question: Field): void {

    this.selectedQuestion$.next(question);
    this.showQuestionDetails$.next(true);
  }

  private deleteQuestion(): void {
    const currentQuestions: Field[] = this.questions;
    const selectedQuestion = this.selectedQuestion$.value.uuid;
    const filteredQuestion = currentQuestions.filter(question => question.uuid !== selectedQuestion);
    this.stepEditForm.patchValue({questions: filteredQuestion});
    // this.dialogRef.close(this.dialogResult);
    this.returnToTabGroup();
  }

  private generateQuestion(): Field {
    const currentQuestions: Field[] = this.questions;
    const newPosition = currentQuestions?.length || 0;

    return this.formsFacade.generateNewQuestion(undefined, newPosition)
  }
  // WORKFLOWACTION

  public onActionChanged(result: any): void {
    if(result) {
      this.stepEditForm.patchValue({workflowAction: result})
    }
  }



  // GETTERS/SETTERS

  get dialogResult() {
    return this.stepEditForm.value;
  }

  get decisions(): Decision[] {
    return this.stepEditForm.get('decisions').value
  }


  get questions(): Field[] {
    return this.stepEditForm.get('questions').value;
  }

  get workflowAction(): Field[] {
    return this.stepEditForm.get('workflowAction').value;
  }

  get tooltipDeleteText(): string {
    if (this.showOptionDetails$.value) {
      return 'Verwijder deze optie'
    } else if (this.showQuestionDetails$.value) {
      return 'Verwijder deze vraag'
    } else {
      return 'Verwijder deze stap'
    }
  }

  get backButtonText(): string {
    if (this.showOptionDetails$.value) {
      return 'Terug naar alle opties'
    } else if (this.showQuestionDetails$.value) {
      return 'Terug naar alle vragen'
    } else {
      return ''
    }
  }
}
