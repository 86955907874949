import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Workflow } from '@workflow-admin/shared/workflow/utils/workflow';
import { MockWorkflow } from '@workflow-admin/testdata';

@Component({
  selector: 'workflow-admin-flow-chart-header',
  templateUrl: './flow-chart-header.component.html',
  styleUrls: ['./flow-chart-header.component.scss']
})
export class FlowChartHeaderComponent implements OnInit, OnDestroy {
  @Input() workflows: (Workflow | MockWorkflow)[];
  @Input() currentWorkflow: Workflow;
  @Input() selectedFlowId: string;

  @Output() selectedFlowChanged = new EventEmitter<string>();
  @Output() removeWorkflow = new EventEmitter();
  @Output() renameWorkflow = new EventEmitter<string>();

  public faChevronDown = faChevronDown;
  public faArrowLeft = faArrowLeft;

  public showWorkflowNameEdit = false;
  public selectedWorkflow: FormControl;

  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.selectedWorkflow = new FormControl(this.selectedFlowId);
// console.log(this.selectedFlowId,this.currentWorkflow);
    this.selectedWorkflow.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(selectedFlowId => {
      this.router.navigate([`workflow/${selectedFlowId}`]);
      this.selectedFlowChanged.emit(selectedFlowId);
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRemoveWorkflow() {
    this.removeWorkflow.emit();
  }
  onRenameWorkflow(newName) {
    this.renameWorkflow.emit(newName);
    this.showWorkflowNameEdit = false;
  }
}
