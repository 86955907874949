import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { optimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { map } from 'rxjs/operators';

import { StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';

import { ActionsApiService } from '../../services/actions-api.service';
import * as WorkflowActionsActions from './actions.actions';

@Injectable()
export class ActionsEffects {
  // loadActions$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ActionsActions.loadActions),
  //     fetch({
  //       run: (action) => {
  //         // Your custom service 'load' logic goes here. For now just return a success action...
  //         return ActionsActions.loadActionsSuccess({ actions: [] });
  //       },
  //
  //       onError: (action, error) => {
  //         console.error('Error', error);
  //         return ActionsActions.loadActionsFailure({ error });
  //       },
  //     })
  //   )
  // );

  createAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActionsActions.createWorkflowAction),
      optimisticUpdate({
        run: (action) => {
          return this.actionsApiService.createAction(action.action).pipe(
            map(response => WorkflowActionsActions.createWorkflowActionSuccess({stepUuid: action.stepUuid, action: response}))
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  // Consider emitting StepAction.editStepInputReference directly here, compare forms.effects
  updateStepReference$ = createEffect(()=>
      this.actions$.pipe(
        ofType(WorkflowActionsActions.createWorkflowActionSuccess),
        map(action =>  this.stepsFacade.editStepInputReference(action.stepUuid, action.action['@id']))
      ),
    {dispatch: false}
  )

  editAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActionsActions.editAction),
      optimisticUpdate({
        run: (action) => {
          console.log('update action');
          return this.actionsApiService.editAction(action.action).pipe(
            map(response => WorkflowActionsActions.editActionSuccess({action: response}))
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  constructor(
    private actions$: Actions,
    private actionsApiService: ActionsApiService,
    private stepsFacade: StepsFacade
    ) {}
}
