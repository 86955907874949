import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { EditStepDialogResult } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { GenerateUuidService } from '@workflow-admin/shared/utils';
import {
  Field,
  StepType,
  WorkflowForm,
  WorkflowFormRequest,
  WorkflowInput
} from '@workflow-admin/shared/workflow/utils/workflow';

import { FormsApiService } from '../../services';
import * as FormsActions from './forms.actions';
import * as fromForms from './forms.reducer';
import * as FormsSelectors from './forms.selectors';


@Injectable()
export class FormsFacade {
  loaded$ = this.store.pipe(select(FormsSelectors.getFormsLoaded));
  allForms$ = this.store.pipe(select(FormsSelectors.getAllForms));
  selectedForm$ = this.store.pipe(select(FormsSelectors.getSelectedForm));
  // selectedFormId$ = this.store.pipe(select(FormsSelectors.getSelectedFormId));
  selectedFormQuestions$ = this.store.pipe(select(FormsSelectors.getSelectedFormQuestions));

  allQuestions$ = this.store.pipe(select(FormsSelectors.getAllQuestions));

  constructor(
    private store: Store<fromForms.FormsPartialState>,
    private generateUuidService: GenerateUuidService,
    private formsApiService: FormsApiService
  ) {}

  getAllForms(): Observable<WorkflowForm[]> {
    this.store.dispatch(FormsActions.loadAllForms())
    return this.allForms$;
  }

  getFormsForWorkflow(id: string): Observable<WorkflowInput[]> {
    return this.formsApiService.getFormsForWorkflow(id)
  }

  selectForm(formUuid: string) {
    this.store.dispatch(FormsActions.selectForm({formUuid}));
  }

  clearSelectedForm() {
    this.store.dispatch(FormsActions.clearSelectedForm())
  }

  getSelectedQuestion(selectedQuestionProperty: string): Observable<Field> {
    return this.store.pipe(select(FormsSelectors.getSelectedQuestion, selectedQuestionProperty));
  }

  getFormQuestions(formId: string): Observable<Field[]> {
    return this.store.pipe(select(FormsSelectors.getFormQuestions, formId))
  }

  editForm(result: EditStepDialogResult): void {
    if(!result.questions) return;

    if(!result.input) {
      this.addNewForm(result);
    } else {
      this.updateForm(result);
    }
  }

  deleteForm(formId: string) {
    const formUuid = formId?.split("/").pop();
    this.store.dispatch(FormsActions.deleteForm({id: formUuid}))
  }

  public generateNewQuestion(formId: string, position = 0): Field {
    const newQuestionUuid = this.generateUuidService.generateUuid();

    return {
      uuid: newQuestionUuid,
      position: position,
      type: undefined,
      label: {
        show: true,
        text: ''
      },
      valueOptions: [],
      required: true,
      form: formId? `\/forms\/${formId}`: '',
    }
  }

  private addNewForm(dialogResult: EditStepDialogResult) {
    const newFormId = this.generateUuidService.generateUuid();
    const newForm = this.generateNewForm(newFormId, dialogResult.name);

    // TODO: Consider if these two calls can be one

    const updatedQuestions = dialogResult.questions.map(question => {
      const newQuestion = this.generateNewQuestion(newFormId);
      return this.patchQuestionWithData(question, newQuestion, dialogResult.name);
    })

    this.store.dispatch(FormsActions.createForm({
      stepUuid: dialogResult.uuid,
      form: newForm,
      questions: updatedQuestions,
    }))
  }

  private updateForm(dialogResult: EditStepDialogResult): void {
    this.store.pipe(select(FormsSelectors.getSelectedForm)).pipe(
      filter(form => !!form),
      take(1)
    ).subscribe((currentForm: WorkflowForm) => {
      if(currentForm.name !== dialogResult.name) {
        const updatedForm = {uuid: currentForm.uuid, name: dialogResult.name}
        this.store.dispatch(FormsActions.editForm({form: updatedForm}))
      }
    })

    this.store.pipe(select(FormsSelectors.getSelectedFormQuestions)).pipe(
      filter(fields => !!fields),
      take(1)
    ).subscribe((currentQuestions: Field[]) => {
      const dialogQuestions = dialogResult?.questions;
      if(!currentQuestions?.length && !dialogQuestions?.length) return;
      const updatedQuestions: Field[] = ( dialogResult.type === StepType.Question ) ?
        [{ ...dialogQuestions[0], label: { ...dialogQuestions[0].label, text: dialogResult.name} }]
        : dialogQuestions
      this.store.dispatch(FormsActions.editQuestions({form: dialogResult.input, currentQuestions, updatedQuestions}))
    })
  }



  private generateNewForm(newId: string, stepName: string): WorkflowFormRequest {
    return {
      uuid: newId,
      name: stepName,
      fields: [],
    }
  }



  private patchQuestionWithData(result: Field, newQuestion: Field, stepName: string): Field {
    console.log('patchQuestionWithData');
    return {
      ...newQuestion,
      type: result.type,
      label: { ...newQuestion.label, text: result.label.text || stepName},
      valueOptions: result.valueOptions,
      position: result.position??0,
    }
  }
}
