import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { map } from 'rxjs/operators';

import { WorkflowApiService } from '../../services';
import * as WorkflowsActions from './workflows.actions';

@Injectable()
export class WorkflowsEffects {

  loadWorkflows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.loadWorkflows),
      fetch({
        run: (action) => {
          return this.workflowApiService.getWorkflows().pipe(
            map(workflows =>  WorkflowsActions.loadWorkflowsSuccess({ workflows }))
          )
        },
        onError: (action, error) => {
          console.error(error);
          return WorkflowsActions.loadWorkflowsFailure({ error });
        }
      })
    )
  );

  createWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.createWorkflow),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.createWorkflow(action.request).pipe(
            map(response => WorkflowsActions.createWorkflowSuccess({workflow: response}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  addFirstStep = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.createWorkflowSuccess),
      map(action => WorkflowsActions.createFirstStep({workflowId: action.workflow.uuid}))
    )
  )

  deleteWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.deleteWorkflow),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.deleteWorkflow(action.id).pipe(
            map(response => WorkflowsActions.deleteWorkflowSuccess({id: action.id}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  selectWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.selectWorkflow),
      map((action) => WorkflowsActions.getWorkflow({ id: action.workflowId }))
    )
  )

  getWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.getWorkflow),
      fetch({
        run: (action) => {
          return this.workflowApiService.getWorkflowFull(action.id).pipe(
            map(workflow => WorkflowsActions.getWorkflowSuccess({workflow}))
          )
        },
        onError(action, error): any {
          console.error(error);
        }
      })
    )
  )

  editFirstStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.editFirstStep),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.editFirstStep(action.workflowUuid, `\/steps\/${action.firstStepUuid}`).pipe(
            map(response => WorkflowsActions.editFirstStepSuccess({workflow: response}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  renameWorkflow = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.editName),
      optimisticUpdate({
        run: (action) => {

          console.log('effect - update')
          return this.workflowApiService.editName(action.workflowUuid, action.name).pipe(
            map(response => WorkflowsActions.editPositionSuccess({workflow: response}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )


  editPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowsActions.editPosition),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.editPosition(action.workflowUuid, action.position).pipe(
            map(response => WorkflowsActions.editPositionSuccess({workflow: response}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  constructor(
    private actions$: Actions,
    private workflowApiService: WorkflowApiService) {}
}
