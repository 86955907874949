import { Injectable } from '@angular/core';

import { GenerateUuidService } from '@workflow-admin/shared/utils';
import {
  WorkflowGroup,
  WorkflowGroupRequest,
} from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class WorkflowGroupEditService {

  constructor(
    private generateUuidService: GenerateUuidService,
  ) { }

  addWorkflowGroup(workflowGroups: WorkflowGroup[], newWorkflowGroup: WorkflowGroup): WorkflowGroup[] {
    return workflowGroups.concat(newWorkflowGroup);
  }

  deleteWorkflowGroup(id: string, workflows: WorkflowGroup []): WorkflowGroup[] {
    return workflows.filter(flow => flow.uuid !== id);
  }

  generateNewWorkflowGroup(name?: string): WorkflowGroupRequest {
    const workflowGroupId = this.generateUuidService.generateUuid();

    return {
      uuid: workflowGroupId,
      name: name || workflowGroupId,
      workflows: [],
    }
  }
}
