import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// TODO: fix this import
// tslint:disable:nx-enforce-module-boundaries
import { DemoWorkflowContainerComponent } from '../../../../libs/demo/feature/demo/src/lib/containers/demo-workflow-container/demo-workflow-container.component';
import {DemoWorkflowGroupsOverviewContainerComponent} from "../../../../libs/demo/feature/demo/src/lib/containers/demo-workflow-groups-overview-container/demo-workflow-groups-overview-container.component";
import { DemoWorkflowsOverviewContainerComponent } from '../../../../libs/demo/feature/demo/src/lib/containers/demo-workflows-overview-container/demo-workflows-overview-container.component';
import {LogInComponent} from "./components/log-in/log-in.component";
import {AuthService} from "./services/auth.service";

const routes: Routes = [
  { path: 'groups', component: DemoWorkflowGroupsOverviewContainerComponent},
  { path: 'login', component: LogInComponent },
  { path: 'workflows', component: DemoWorkflowsOverviewContainerComponent},
  { path: 'workflow/:id', component: DemoWorkflowContainerComponent },

  { path: '', redirectTo: '/groups', pathMatch: 'full'},
  { path: '**', component: DemoWorkflowContainerComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService],
})
export class AppRoutingModule {}
