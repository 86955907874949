import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@workflow-admin/shared/data-access';
import {
  CreateWorkflowStepRequest,
  DecisionCondition,
  DecisionConditionSet,
  DecisionConditionSetRequest,
  DecisionRequest,
  DecisionResponse,
  EditWorkflowStepRequest,
  WorkflowStep
} from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class StepApiService {

  baseUrl = this.apiService.apiUrl

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getSteps(): Observable<WorkflowStep[]> {
    return this.http.get<WorkflowStep[]>(`${this.baseUrl}/steps`).pipe(
      catchError(this.handleError)
    );
  }

  getStep(id: string): Observable<WorkflowStep>{
    return this.http.get<WorkflowStep>(`${this.baseUrl}/steps/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  createStep(step: CreateWorkflowStepRequest): Observable<WorkflowStep> {
    return this.http.post<WorkflowStep>(`${this.baseUrl}/steps`, step).pipe(
      catchError(this.handleError)
    )
  }


  editStep(stepId: string, request: EditWorkflowStepRequest): Observable<WorkflowStep> {
    return this.http.put<WorkflowStep>(`${this.baseUrl}/steps/${stepId}`, request).pipe(
      catchError(this.handleError)
    )
  }

  deleteStep(stepId: string) {
    return this.http.delete(`${this.baseUrl}/steps/${stepId}`).pipe(
      catchError(this.handleError)
    )
  }

  addDecision(decision: DecisionRequest): Observable<DecisionResponse> {
    return this.http.post<DecisionResponse>(`${this.baseUrl}/decisions`, decision).pipe(
      catchError(this.handleError)
    )
  }

  editDecision(decision: DecisionRequest): Observable<DecisionResponse> {
    return this.http.put<DecisionResponse>(`${this.baseUrl}/decisions/${decision.uuid}`, decision).pipe(
      catchError(this.handleError)
    )
  }

  deleteDecision(decisionId: string) {
    return this.http.delete(`${this.baseUrl}/decisions/${decisionId}`).pipe(
      catchError(this.handleError)
    )
  }

  addDecisionConditionSet(conditionSet: DecisionConditionSetRequest): Observable<DecisionConditionSet> {
    return this.http.post<DecisionConditionSet>(`${this.baseUrl}/decision_condition_sets`, conditionSet).pipe(
      catchError(this.handleError)
    )
  }

  editDecisionConditionSet(conditionSet: DecisionConditionSetRequest): Observable<DecisionConditionSet> {
    return this.http.put<DecisionConditionSet>(`${this.baseUrl}/decision_condition_sets/${conditionSet.uuid}`, conditionSet).pipe(
      catchError(this.handleError)
    )
  }

  deleteDecisionConditionSet(conditionSetId: string) {
    return this.http.delete(`${this.baseUrl}/decision_condition_sets/${conditionSetId}`).pipe(
      catchError(this.handleError)
    )
  }

  addDecisionCondition(condition: DecisionCondition): Observable<DecisionCondition> {
    return this.http.post<DecisionCondition>(`${this.baseUrl}/decision_conditions`, condition).pipe(
      catchError(this.handleError)
    )
  }

  editDecisionCondition(condition: DecisionCondition): Observable<DecisionCondition> {
    return this.http.put<DecisionCondition>(`${this.baseUrl}/decision_conditions/${condition.uuid}`, condition).pipe(
      catchError(this.handleError)
    )
  }

  deleteDecisionCondition(condition: DecisionCondition): Observable<DecisionCondition> {
    return this.http.delete<DecisionCondition>(`${this.baseUrl}/decision_conditions/${condition.uuid}`).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
