import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { EditStepDialogResult } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { GenerateUuidService } from '@workflow-admin/shared/utils';
import { WorkflowAction } from '@workflow-admin/shared/workflow/utils/workflow';

import * as WorkflowActionsActions from './actions.actions';
import * as fromActions from './actions.reducer';
import * as ActionsSelectors from './actions.selectors';

@Injectable()
export class ActionsFacade {
  loaded$ = this.store.pipe(select(ActionsSelectors.getActionsLoaded));
  allActions$ = this.store.pipe(select(ActionsSelectors.getAllActions));
  selectedActions$ = this.store.pipe(select(ActionsSelectors.getSelected));

  constructor(
    private store: Store<fromActions.ActionsPartialState>,
    private generateUuidService: GenerateUuidService,
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  editAction(result: EditStepDialogResult): void {
    if(!result.input) {
      this.addNewAction(result);
    } else {
      this.updateAction(result);
    }
  }

  private addNewAction(dialogResult: EditStepDialogResult) {
    const newActionId = this.generateUuidService.generateUuid();
    const newAction = this.generateNewAction(newActionId, dialogResult.name);
    newAction.actionType = dialogResult.workflowAction.actionType,
      newAction.actionAttributes = dialogResult.workflowAction.actionAttributes

    this.store.dispatch(WorkflowActionsActions.createWorkflowAction({
      stepUuid: dialogResult.uuid,
      action: newAction,

    }))
  }

  private generateNewAction(newId: string, stepName: string): WorkflowAction {
    return {
      uuid: newId,
      actionType: null,
      name: stepName
    }
  }

  private updateAction(dialogResult: EditStepDialogResult): void {
    const actionId = dialogResult.input.split("/").pop();
    this.store.pipe(select(ActionsSelectors.getActionById, actionId)).pipe(
      filter(action => !!action),
      take(1)
    ).subscribe((currentAction: WorkflowAction) => {
      console.log('updateAction', dialogResult.workflowAction);

      // if(currentAction.name !== dialogResult.name) {
        const updatedAction = {uuid: currentAction.uuid, name: dialogResult.name, actionType: dialogResult.workflowAction.actionType, actionAttributes: dialogResult.workflowAction.actionAttributes}
        this.store.dispatch(WorkflowActionsActions.editAction({action: updatedAction}))
      // }
    })
  }

}
