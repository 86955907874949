<ng-container *ngIf="items?.length">
  <ng-container *ngIf="items.length > 1; else singleOption">
    <div cdkDropList class="drag-drop-list" (cdkDropListDropped)="itemMoved($event)" [cdkDropListData]="items">
      <div class="drag-drop-item" *ngFor="let item of items" cdkDrag (click)="editItem(item)" [id]="item.uuid">
        <fa-icon class="drag-drop-icon" [icon]="faBars"></fa-icon>
        <span class="drag-drop-item__label">{{item.name || item.label?.text || item.uuid}}</span>
<!--        <fa-icon class="edit-option-icon" [icon]="faClone" (click)="duplicateItem($event,item)" *ngIf="itemDuplicate"></fa-icon>-->

        <fa-icon class="edit-option-icon" [icon]="faChevronRight"></fa-icon>
      </div>
    </div>
  </ng-container>
  <ng-template #singleOption>
    <div>
      <div class="single-item" *ngIf="items[0]; let item" (click)="editItem(item)" >
        <span class="drag-drop-item__label">{{item.name || item.uuid}}</span>
<!--        <fa-icon class="edit-option-icon" [icon]="faClone" (click)="duplicateItem($event,item)" *ngIf="itemDuplicate"></fa-icon>-->
        <fa-icon class="edit-option-icon" [icon]="faChevronRight"></fa-icon>

      </div>
    </div>
  </ng-template>
</ng-container>
