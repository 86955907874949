import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { EnvironmentConfig, ENV_CONFIG } from './environment-config.interface';

@NgModule({
  imports: [CommonModule]
})
export class SharedDataAccessModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<SharedDataAccessModule> {
    return {
      ngModule: SharedDataAccessModule,
      providers: [
        {
          provide: ENV_CONFIG,
          useValue: config
        },
        ApiService
      ]
    };
  }
}
