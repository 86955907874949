import {Pipe, PipeTransform} from '@angular/core';
import {Edge} from '@swimlane/ngx-graph';

import {WorkflowStep} from '@workflow-admin/shared/workflow/utils/workflow';

@Pipe({
  name: 'edgesPipe'
})
export class EdgesPipe implements PipeTransform {

  transform(steps: WorkflowStep[], firstStepUuid: string): Edge[] {
    const links = [];
    for (const step of steps) {
      if (!step.decisions || !step.decisions.length) {
        continue;
      }

      for (const decision of step.decisions) {
        if (!decision.nextStepUuid) {
          continue;
        }
        if (steps.filter((s) => s.uuid === decision.nextStepUuid).length) { // sometimes a step is not available??
          const edge: Edge = {
            source: step.uuid,
            target: decision.nextStepUuid,
            data: {
              linkText: decision.name,
            }
          };

          links.push(edge);
        }
      }
    }

    if (firstStepUuid && firstStepUuid.length) {
      const firstEdge: Edge = {
        source: '0',
        target: firstStepUuid,
        data: {
          linkText: undefined,
        }
      }
      links.push(firstEdge);
    }

    return links;
  }
}
