<mat-toolbar class="demo-container__header"></mat-toolbar>
<div class="workflows-overview-container">
  <div class="workflows-overview-container__content">
    <h2 class="workflows-overview-container__header">Flows</h2>
    <div class="workflows-overview-container__flows">
      <ng-container *ngIf="workflows$ | async; let workflows">
        <div *ngFor="let workflow of workflows" class="workflows-overview-container__flow">
          <div class="workflows-overview-container__card" [routerLink]="['/workflow', workflow.uuid]">
            <span class="workflows-overview-container__card__text">{{workflow.name}}</span>
            <fa-icon class="workflows-overview-container__card__icon" [icon]="faArrowRight"></fa-icon>
          </div>
          <button mat-button (click)="deleteWorkflow(workflow.uuid)">
            <fa-icon class="workflows-overview-container__delete-icon" [icon]="faTrashAlt"></fa-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <div *ngIf="showWorkflowNameEdit | async" class="workflows-overview-container__input-container">
      <mat-form-field appearance="fill" class="workflows-overview-container__input-field">
        <input matInput [formControl]="newWorkflowName" placeholder="Nieuwe workflow naam">
      </mat-form-field>
      <div class="workflows-overview-container__input-buttons">
        <button
          class="workflows-overview-container__input-cancel-buttons"
          mat-flat-button
          color="secondary"
          (click)="cancelCreate()">
          Annuleren
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="createNewWorkflow()">
          Toevoegen
        </button>
      </div>
    </div>

    <button
      *ngIf="!(showWorkflowNameEdit | async)"
      mat-flat-button
      color="primary"
      class="workflows-overview-container__add-flow-button"
      (click)="addWorkflow()">
      Nieuwe flow maken
    </button>
  </div>
</div>
