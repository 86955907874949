import { Pipe, PipeTransform } from '@angular/core';

import { FlowChartNode } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { MockWorkflowStep } from '@workflow-admin/testdata';


@Pipe({
  name: 'mockNodesPipe'
})
export class MockNodesPipe implements PipeTransform {

  transform(steps: MockWorkflowStep[]): FlowChartNode[] {
    const nodes = [];
    for (const step of steps) {
      const node: FlowChartNode = {
        id: step.id,
        label: step.label,
        type: step.type,
        name: step.text,
      };

      nodes.push(node);
    }
    return nodes;
  }
}
