import { StepType, Workflow, WorkflowFull } from '../shared/workflow/utils/workflow/src/lib/models';

export const miniFlowDakkapel: WorkflowFull = {
  "@context": "/contexts/Workflow",
  "@id": "/workflows/0d24be04-d1ef-4f26-8177-595d198acc8f",
  "@type": "Workflow",
  "uuid": "miniflow",
  "name": "Miniflow",
  "firstStepUuid": "9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
  "steps": []
  // "steps": [
  //   {
  //     "@id": "/steps/a5e72cf6-5078-467f-a34a-b4a6f81863c8",
  //     "@type": StepType.Question,
  //     "uuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8",
  //     "name": "Kan er direct worden gestart?",
  //     "decisions": []
  //   },
  //   {
  //     "@id": "/steps/f049859d-8601-4b82-a1e7-4c717c166a3c",
  //     "@type": StepType.Question,
  //     "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
  //     "name": "Geeft de klant toestemming om de handeling te starten?",
  //     "decisions": []
  //   },
  //   {
  //     "@id": "/steps/9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
  //     "@type": StepType.Decision,
  //     "uuid": "9ab94dca-34b2-499b-a23f-283bc0e0dd5a",
  //     "name": "Heb je alles bij je en heb je de kennis om direct te beginnen?",
  //     "decisions": [
  //       {
  //         "@id": "/decisions/c1939634-55c3-4050-8f08-ad470ac42bc6",
  //         "@type": "Decision",
  //         "uuid": "c1939634-55c3-4050-8f08-ad470ac42bc6",
  //         "position": 0,
  //         "nextStepUuid": "a5e72cf6-5078-467f-a34a-b4a6f81863c8",
  //         "name": "ja",
  //         "conditionSets": [
  //           {
  //             "@id": "/conditionSets/6e597780-b0cf-4f0f-94d5-02db4cc9b340",
  //             "@type": "ConditionSet",
  //             "uuid": "6e597780-b0cf-4f0f-94d5-02db4cc9b340",
  //             "conditions": [
  //               {
  //                 "@id": "/conditions/3b95d841-30ef-4614-b595-63236085f0cb",
  //                 "@type": "Condition",
  //                 "uuid": "3b95d841-30ef-4614-b595-63236085f0cb",
  //                 "type": "string",
  //                 "operator": "equal",
  //                 "modifier": null,
  //                 "property": "questions.can_we_start_immediately",
  //                 "value": "yes",
  //                 "input": {
  //                   "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
  //                   "@type": "Form",
  //                   "questions": [
  //                     {
  //                       "@id": "/questions/9051e57c-d4ba-4db1-8772-7b111a164120",
  //                       "@type": "Question",
  //                       "uuid": "9051e57c-d4ba-4db1-8772-7b111a164120",
  //                       "position": 0,
  //                       "name": "can_we_start_immediately",
  //                       "label": {
  //                         "show": true,
  //                         "text": "can_we_start_immediately"
  //                       },
  //                       "placeholder": {
  //                         "show": false,
  //                         "text": null
  //                       },
  //                       "questionType": "radio",
  //                       "can_have_notes": false,
  //                       "valueOptions": {
  //                         "no": "no_ask_customer",
  //                         "yes": "yes_start_immediately"
  //                       }
  //                     }
  //                   ],
  //                   "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
  //                   "name": "start_immediately",
  //                   "discr": "form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         "@id": "/decisions/46d480a9-5669-4d2e-aea3-5ac190ba6957",
  //         "@type": "Decision",
  //         "uuid": "46d480a9-5669-4d2e-aea3-5ac190ba6957",
  //         "position": 1,
  //         "nextStepUuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
  //         "name": "nee",
  //         "conditionSets": [
  //           {
  //             "@id": "/conditionSets/c9a80939-f282-4f76-8900-55e4c688c0dc",
  //             "@type": "ConditionSet",
  //             "uuid": "c9a80939-f282-4f76-8900-55e4c688c0dc",
  //             "conditions": [
  //               {
  //                 "@id": "/conditions/526de47d-b32e-4ba3-ab2a-b98da31bf40a",
  //                 "@type": "Condition",
  //                 "uuid": "526de47d-b32e-4ba3-ab2a-b98da31bf40a",
  //                 "type": "string",
  //                 "operator": "equal",
  //                 "modifier": null,
  //                 "property": "questions.can_we_start_immediately",
  //                 "value": "no",
  //                 "input": {
  //                   "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
  //                   "@type": "Form",
  //                   "questions": [
  //                     {
  //                       "@id": "/questions/9051e57c-d4ba-4db1-8772-7b111a164120",
  //                       "@type": "Question",
  //                       "uuid": "9051e57c-d4ba-4db1-8772-7b111a164120",
  //                       "position": 0,
  //                       "name": "can_we_start_immediately",
  //                       "label": {
  //                         "show": true,
  //                         "text": "can_we_start_immediately"
  //                       },
  //                       "placeholder": {
  //                         "show": false,
  //                         "text": null
  //                       },
  //                       "questionType": "radio",
  //                       "can_have_notes": false,
  //                       "valueOptions": {
  //                         "no": "no_ask_customer",
  //                         "yes": "yes_start_immediately"
  //                       }
  //                     }
  //                   ],
  //                   "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
  //                   "name": "start_immediately",
  //                   "discr": "form"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ],
}
