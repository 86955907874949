import { Pipe, PipeTransform } from '@angular/core';

import { FlowChartNode } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { WorkflowStep } from '@workflow-admin/shared/workflow/utils/workflow';

@Pipe({
  name: 'nodesPipe'
})
export class NodesPipe implements PipeTransform {

  transform(steps: WorkflowStep[], selectedStepUuid: string): FlowChartNode[] {
    const nodes = [];
    for (const step of steps) {
      const node: FlowChartNode = {
        id: step.uuid,
        label: step.name,
        type: step.type,
        name: step.name,
        isSelected: step.uuid === selectedStepUuid
      };

      nodes.push(node);
    }

    const firstStep = {
      id: '0',
      label: 'Start',
      type: 'starter',
      name: 'Start'
    }

    nodes.push(firstStep);

    return nodes;
  }
}
