export enum QuestionType {
  Boolean = "boolean",
  Text = "text",
  Integer = "integer",
  Float = "float",
  Radio = "radio",
  Date = "date",
  Time = "time",
  DateTime = "datetime",
  CheckboxSingle = "checkbox_single",
  CheckboxMultiple = "checkbox_multiple",
  UploadFile = "upload_file",
  UploadImage = "upload_image",
  Address = "address",
  Html = "html"
}
