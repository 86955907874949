// komt: valueType: string, integer, datetime, floats, boolean, null, array
export enum InputValueType {
  String = 'string',
  Integer = 'integer',
  Floats = 'float',
  Datetime = 'datetime',
  Boolean = 'boolean',
  Null = 'null',
  Array = 'array'
}
