import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EditQuestionRequest, EditStepDialogResult } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { GenerateUuidService } from '@workflow-admin/shared/utils';
import {
  Field,
  WorkflowForm
} from '@workflow-admin/shared/workflow/utils/workflow';
import { demoForms } from '@workflow-admin/testdata';


@Injectable({
  providedIn: 'root'
})
export class MockFormsFacade {

  forms$ = new BehaviorSubject<WorkflowForm[]>(demoForms);
  currentForm$ = new BehaviorSubject<WorkflowForm>(undefined);

  constructor(
    private generateUuidService: GenerateUuidService) { }

  // TODO: fix leaky subscriptions

  getForms(): void {
    //TODO: run api call
  }

  public getForm(id: string): BehaviorSubject<WorkflowForm> {
    //   this.workflowApiService.getWorkflow(id).subscribe(workflow => this.currentWorkflow$.next(workflow));
    // const form = this.forms$.value[`\/forms\/${id}`];
    const form = this.forms$.value[id];

    this.currentForm$.next(form);
    return this.currentForm$;
  }

  public getAllQuestions(): Field[] {
    const forms = this.forms$.value;

    const questions: Field[] = [];

    Object.keys(forms).forEach(key => {
      if(forms[key].questions?.length) {
        forms[key].questions.forEach(question => {
          questions.push({ ...question, property: `${question.form}.${question.uuid}`})
        })
      }
    });
    return questions;
  }

  public addInput(input: EditStepDialogResult, newInputId: string): void {
    const newInput = this.generateNewInput(newInputId);

    const newQuestion = this.generateNewQuestion();
    const patchedQuestion = this.patchQuestion(input.questions[0], newQuestion, input.name);

    newInput.fields.push(patchedQuestion);
    this.addToInputs(newInput);
  }

  public editQuestion(result: EditQuestionRequest, index: number, stepName: string): Field {
    const question = this.findQuestion(index);
    return this.patchQuestion(result, question, stepName);
  }

  public updateInputs(editedForm: WorkflowForm): void {
    const editedInputs = this.forms$.value;
    editedInputs[editedForm['@id']]= editedForm;

    // TODO: maybe use spread operator here
    this.forms$.next(editedInputs);
  }

  private generateNewInput(newId: string): WorkflowForm {
    // TODO: split this out for different types
    return {
      "@id": `\/forms\/${newId}`,
      "@type": "Form",
      uuid: newId,
      name: '',
      fields: [],
    }
  }

  private generateNewQuestion(): Field {
    const newQuestionUuid = this.generateUuidService.generateUuid();

    // TODO: check if initial value for position and required are correct
    return {
      "@id": `\/questions\/${newQuestionUuid}`,
      uuid: newQuestionUuid,
      position: 0,
      type: undefined,
      label: undefined,
      valueOptions: [],
      required: true,
    }
  }

  private addToInputs(input: WorkflowForm): void {
    const inputs = this.forms$.value;
    const inputId = input['@id'];

    inputs[inputId] = input;
    this.forms$.next(inputs);
  }

  private findQuestion(index: number): Field {
    const form = this.currentForm$.value;
    return form.fields[index];
  }

  private patchQuestion(result: EditQuestionRequest, question: Field, stepName: string): Field {
    return {
      ...question,
      type: result.type,
      label: { ...question.label, text: result.label.text || stepName},
      valueOptions: result.valueOptions
    }
  }
}
