import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { isEqual }  from 'lodash-es';
import { undo } from 'ngrx-undo';
import { forkJoin, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import * as WorkflowActions from '@workflow-admin/shared/workflow/data-access/workflow';

import { FormsApiService } from '../../services';
import * as FormsActions from './forms.actions';

@Injectable()
export class FormsEffects {
  loadForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.loadAllForms),
      fetch({
        run: () => {
          return this.formsApiService.getForms().pipe(
            map(forms => FormsActions.loadAllFormsSuccess({forms}))
          )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return FormsActions.loadAllFormsFailure({ error });
        },
      })
    )
  );

  getForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.getForm),
      fetch({
        run: (action) => {
          return this.formsApiService.getForm(action.id).pipe(
            map(form => FormsActions.getFormSuccess({ form }))
          )
        },
        onError(action, error): any {
          console.error(error);
          return FormsActions.getFormFailure({ error })
        }
      })
    )
  )

  createForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.createForm),
      optimisticUpdate({
        run: (action) => {
          return this.formsApiService.createForm(action.form).pipe(
            switchMap(form => [
              FormsActions.createFormSuccess({ stepUuid: action.stepUuid, form }),
              FormsActions.createQuestions({questions: action.questions, formId: action.form.uuid}),
            ])
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  // Consider emitting StepAction.editStepInputReference directly here, compare action.effects
  updateStepReference$ = createEffect(()=>
    this.actions$.pipe(
      ofType(FormsActions.createFormSuccess),
      map(action =>  this.stepsFacade.editStepInputReference(action.stepUuid, action.form['@id']))
    ),
{dispatch: false}
  )

  editForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.editForm),
      optimisticUpdate({
        run: (action) => {
          return this.formsApiService.editForm(action.form).pipe(
            map(form => FormsActions.editFormSuccess({form}))
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  deleteForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.deleteForm),
      optimisticUpdate({
        run: (action) => {
          return this.formsApiService.deleteForm(action.id).pipe(
            map(response => FormsActions.deleteFormSuccess({id: action.id}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  createQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.createQuestions),
      optimisticUpdate({
        run: (action) => {
          const addedQuestions$ = action.questions.map(question => this.formsApiService.createQuestion(question))
          return forkJoin([...addedQuestions$]).pipe(
            switchMap(_ => [
              FormsActions.createQuestionsSuccess(),
              FormsActions.getForm({ id: action.formId })
            ])
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  editQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormsActions.editQuestions),
      optimisticUpdate({
        run: (action) => {
          const addedQuestions = action.updatedQuestions.filter(dialQ => !action.currentQuestions.some(curQ => curQ.uuid === dialQ.uuid));
          const editedQuestions = action.updatedQuestions.filter(dialQ => action.currentQuestions.some(curQ => ((curQ.uuid === dialQ.uuid) && (!isEqual(curQ, dialQ)))));
          const deletedQuestions = action.currentQuestions.filter(curQ => !action.updatedQuestions.some(dialQ => dialQ.uuid === curQ.uuid));

          const addedQuestions$ = addedQuestions.map(question => this.formsApiService.createQuestion({ ...question, form: action.form }));
          const editedQuestions$ = editedQuestions.map(question => this.formsApiService.editQuestion({ ...question, form: action.form }));
          const deletedQuestions$ = deletedQuestions.map(question => this.formsApiService.deleteQuestion(question.uuid));

          return forkJoin([...addedQuestions$, ...editedQuestions$, ...deletedQuestions$]).pipe(
            switchMap(_ => [
              FormsActions.editQuestionsSuccess(),
              FormsActions.getForm({ id: action.form.split("/").pop() })
            ])
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  deleteForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActions.deleteWorkflow),
      switchMap(action => {
        return this.formsApiService.getFormsForWorkflow(action.id).pipe(
          filter(forms => !!forms && !!forms.length),
          switchMap(forms => {
            return from(forms).pipe(
              map(form => FormsActions.deleteForm({ id: form.uuid }))
            )
          })
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private formsApiService: FormsApiService,
    private stepsFacade: StepsFacade) {}
}
