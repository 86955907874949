import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {Field, StepType, WorkflowGroup} from "@workflow-admin/shared/workflow/utils/workflow";
import { filter, take } from 'rxjs/operators';
import * as FormsActions from "../../../../../../../input/data-access/input/src/lib/+state/forms/forms.actions";
import * as FormsSelectors from "../../../../../../../input/data-access/input/src/lib/+state/forms/forms.selectors";

import {  WorkflowGroupEditService } from '../../services';
import * as WorkflowGroupActions from './workflowGroups.actions';
import * as WorkflowActions from '../workflows/workflows.actions';
import * as fromWorkflowGroups from './workflowGroups.reducer'
import * as WorkflowGroupsSelectors from './workflowGroups.selectors';

@Injectable()
export class WorkflowGroupsFacade {
  loaded$ = this.store.pipe(select(WorkflowGroupsSelectors.getWorkflowGroupsLoaded));
  allWorkflowGroups$ = this.store.pipe(select(WorkflowGroupsSelectors.getAllWorkflowGroups));
  // selectedWorkflowGroupId$ = this.store.pipe(select(WorkflowGroupsSelectors.getSelectedId))

  constructor(
    private store: Store<fromWorkflowGroups.WorkflowGroupsPartialState>,
    private workflowEditService: WorkflowGroupEditService,
  ) {
    select(WorkflowGroupsSelectors.getAllWorkflowGroups)
    // console.log('store',store);
    // console.log('WorkflowGroupsSelectors.getAllWorkflowGroups',WorkflowGroupsSelectors.getAllWorkflowGroups);

  }

  // TODO: reconsider returning observable
  getAllWorkflowGroups() {
    console.log('getAllWorkflowGroups');
    this.store.dispatch(WorkflowGroupActions.loadWorkflowGroups());

    return this.allWorkflowGroups$;
  }

  getWorkflowGroup(id: string) {
    console.log('getWorkflowGroup');
    this.store.dispatch(WorkflowGroupActions.getWorkflowGroup({ id }))
  }
  updateWorkflowGroup(workflowGroup: WorkflowGroup) {  //   this.store.dispatch(FormsActions.editQuestions({form:
    console.log(workflowGroup);
    this.store.dispatch(WorkflowGroupActions.editWorkflowGroup({workflowGroup}));
  }
  updateWorkflowGroupPositions(workflowGroup: WorkflowGroup) {  //   this.store.dispatch(FormsActions.editQuestions({form:
      workflowGroup.workflows.forEach((workflow) => {
        this.store.dispatch(WorkflowActions.editPosition({workflowUuid: workflow.uuid,position:workflow.position}));
      })
  }

  addWorkflowGroup(name?: string) {
    const newWorkflowGroup = this.workflowEditService.generateNewWorkflowGroup(name);
    this.store.dispatch(WorkflowGroupActions.createWorkflowGroup({request: newWorkflowGroup}));

    // TODO: consider adding first step here
    // const firstStep = this.workflowEditService.generateFirstStep(newWorkflow.uuid);

  }

  deleteWorkflowGroup(id: string) {
    this.store.dispatch(WorkflowGroupActions.deleteWorkflowGroup({ id }))
  }

  // deleteSelectedWorkflowGroup() {
  //   this.selectedWorkflowGroupId$.pipe(
  //     filter(id => !!id),
  //     take(1)
  //   ).subscribe(id => this.deleteWorkflowGroup(id))
  // }

}
