import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {MatDividerModule} from "@angular/material/divider";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ConditionComponent } from './components/condition/condition.component';
import { DeleteStepConfirmDialogComponent } from './components/delete-step-confirm-dialog/delete-step-confirm-dialog.component';
import { EditActionComponent } from "./components/edit-action/edit-action.component";
import { EditFirstStepDialogComponent } from './components/edit-first-step-dialog/edit-first-step-dialog.component';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';
import { ListComponent } from './components/list/list.component';
import { StarterComponent } from './components/start/starter.component';
import { StepComponent } from './components/step/step.component';
import {ActionTypePipe} from "./pipes/action-type.pipe";
import { InputTypePipe } from './pipes/input-type.pipe';
import { OperatorPipe } from './pipes/operator.pipe';
import { QuestionTypePipe} from './pipes/question-type.pipe';
import { StepTypePipe } from './pipes/step-type.pipe';
import { SharedFlowChartUiFlowChartMaterialModule } from './shared-flow-chart-ui-flow-chart-material.module';

const components = [
  StepComponent,
  ConditionComponent,
  EditQuestionComponent,
  EditActionComponent,
  ListComponent,
  StarterComponent,
  EditFirstStepDialogComponent,
  DeleteStepConfirmDialogComponent
]

const pipes = [
  StepTypePipe,
  OperatorPipe,
  InputTypePipe,
  QuestionTypePipe,
  ActionTypePipe
]

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        SharedFlowChartUiFlowChartMaterialModule,
        ReactiveFormsModule,
        MatDividerModule
    ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  entryComponents: [EditFirstStepDialogComponent, DeleteStepConfirmDialogComponent]
})
export class SharedFlowChartUiFlowChartModule {}

