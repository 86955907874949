import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '@workflow-admin/shared/data-access';
import {WorkflowAction, WorkflowGroup, WorkflowGroupRequest} from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class WorkflowGroupApiService {

  baseUrl = this.apiService.apiUrl

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getWorkflowGroups(): Observable<WorkflowGroup[]> {
    return this.http.get<WorkflowGroup[]>(`${this.baseUrl}/workflow_groups`).pipe(
    // return this.http.get<Workflow[]>('api/workflows').pipe(
      map(response => response['hydra:member']),
      catchError(this.handleError)
    );
  }

  editWorkflowGroup(workflowGroup: WorkflowGroup): Observable<WorkflowGroup> {
    return this.http.put<WorkflowGroup>(`${this.baseUrl}/workflow_groups/${workflowGroup.uuid}`, workflowGroup).pipe(
      catchError(this.handleError)
    )
  }

  createWorkflowGroup(workflow: WorkflowGroupRequest): Observable<WorkflowGroup> {
    return this.http.post<WorkflowGroup>(`${this.baseUrl}/workflow_groups`, workflow).pipe(
      catchError(this.handleError)
    )
  }

  deleteWorkflowGroup(id: string) {
    return this.http.delete(`${this.baseUrl}/workflow_groups/${id}`).pipe(
      catchError(this.handleError)
    )
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
