import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEye, faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons';

import { FlowChartNode } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { StepType } from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() nodeStep: FlowChartNode;
  @Output() addNextStep = new EventEmitter<string>();

  stepType = StepType;

  eyeIcon = faEye;
  plusIcon = faPlus;
  layerIcon = faLayerGroup

  constructor() { }

  ngOnInit(): void {
  }

  onAddNextStep(event: MouseEvent): void {
    event.stopPropagation();
    this.addNextStep.emit(this.nodeStep.id);
  }
}
