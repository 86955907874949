import { Pipe, PipeTransform } from '@angular/core';

import { questionTypeLabel } from '../constants/question-type-labels.constant';

@Pipe({
  name: 'questionTypePipe'
})
export class QuestionTypePipe implements PipeTransform {

  transform(questionType: string): string {
    return questionTypeLabel[questionType];
  }

}
