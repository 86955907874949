import { createAction, props } from '@ngrx/store';

import { WorkflowAction } from '@workflow-admin/shared/workflow/utils/workflow';

export const loadActions = createAction('[Actions] Load Actions');

export const loadActionsSuccess = createAction(
  '[Actions] Load Actions Success',
  props<{ actions: WorkflowAction[] }>()
);

export const loadActionsFailure = createAction(
  '[Actions] Load Actions Failure',
  props<{ error: any }>()
);

export const createWorkflowAction = createAction(
  '[Actions] Create Action',
  props<{stepUuid: string, action: WorkflowAction}>()
)

export const createWorkflowActionSuccess = createAction(
  '[Actions] Create Action Success',
  props<{stepUuid: string, action: WorkflowAction}>()
)

export const editAction = createAction(
  '[Actions] Edit Action',
  props<{action: WorkflowAction}>()
)

export const editActionSuccess = createAction(
  '[Actions] Edit Action Success',
  props<{action: WorkflowAction}>()
)
