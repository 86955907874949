import {Injectable} from '@angular/core';

import {GenerateUuidService} from '@workflow-admin/shared/utils';
import {
  CreateWorkflowStepRequest, Decision, DecisionCondition, DecisionConditionSet,
  DecisionRequest
} from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class StepEditService {

  constructor(
    private generateUuidService: GenerateUuidService,
  ) {
  }

  generateStep(workflowId: string, name = ''): CreateWorkflowStepRequest {
    const stepUuid = this.generateUuidService.generateUuid();

    return {
      uuid: stepUuid,
      type: '',
      name: name,
      workflow: `\/workflows\/${workflowId}`,
      input: null,
    }
  }

  generateDecision(stepUuid: string, nextStepUuid: string, position = 0): Decision {
    const decisionUuid = this.generateUuidService.generateUuid();

    return {
      uuid: decisionUuid,
      name: '',
      position: position,
      step: `\/steps\/${stepUuid}`,
      nextStepUuid: nextStepUuid,
      conditionSets: [],
    }
  }

  cloneDecision(stepUuid: string, decision: Decision, position = 0): Decision {
    const decisionUuid = this.generateUuidService.generateUuid();


    const newConditionSets = [...decision.conditionSets]
      .map((conditionSet) => {
      const newConditionSetUuid = this.generateUuidService.generateUuid();
      const newConditions = [...conditionSet.conditions].map((condition) => {
        return {
          ...condition,
          conditionSet: `\/decision_condition_sets\/${newConditionSetUuid}`,
          uuid: this.generateUuidService.generateUuid(),
          "@id": null
        }
      });
      return {...conditionSet, uuid: newConditionSetUuid, conditions: newConditions,        decision: `\/decisions\/${decisionUuid}`, "@id": null}
    });

    return {
      ...decision,
      uuid: decisionUuid,
      step: `\/steps\/${stepUuid}`,
      name: "Optie " + (position+1).toString(),
      position: position,
      conditionSets: newConditionSets,
      "@id": null
    }
  }

  generateDecisionRequest(stepUuid, nextStepUuid): DecisionRequest {
    const decision = this.generateDecision(stepUuid, nextStepUuid);
    return this.toDecisionRequest(decision);
  }

  toDecisionRequest(decision: Decision): DecisionRequest {
    const {nextStepUuid, ...reducedDecisionRequest} = decision;
    return {
      ...reducedDecisionRequest,
      nextStep: `\/steps\/${nextStepUuid}`,
      conditionSets: []
    }
  }

  generateDecisionConditionSet(decisionUuid = ''): DecisionConditionSet {
    const newConditionSetUuid = this.generateUuidService.generateUuid();
    return {
      uuid: newConditionSetUuid,
      decision: `\/decisions\/${decisionUuid}`,
      conditions: []
    }
  }

  generateDecisionCondition(conditionSetUuid = ''): DecisionCondition {
    const newConditionUuid = this.generateUuidService.generateUuid();

      return {
        uuid: newConditionUuid,
        type: '',
        input: '',
        property: '',
        operator: '',
        value: '',
        conditionSet: `\/decision_condition_sets\/${conditionSetUuid}`,
        valueType: undefined,
      }
  }
}
