import {DragDropModule} from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedFlowChartDataAccessFlowChartModule } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { SharedFlowChartFeatureFlowChartModule } from '@workflow-admin/shared/flow-chart/feature/flow-chart';
import { SharedFlowChartUtilsFlowChartModule} from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { SharedInputDataAccessInputModule } from '@workflow-admin/shared/input/data-access/input';
import { SharedWorkflowDataAccessWorkflowModule } from '@workflow-admin/shared/workflow/data-access/workflow';

import { WorkflowListComponent } from "./components/list/list.component";
import { WorkflowGroupFolderComponent} from "./components/workflow-group/workflow-group-folder.component";
import { DemoWorkflowContainerComponent } from './containers/demo-workflow-container/demo-workflow-container.component';
import { DemoWorkflowGroupsOverviewContainerComponent} from "./containers/demo-workflow-groups-overview-container/demo-workflow-groups-overview-container.component";
import { DemoWorkflowsOverviewContainerComponent } from './containers/demo-workflows-overview-container/demo-workflows-overview-container.component';
import { DemoFeatureDemoMaterialModule } from './demo-feature-demo-material.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedFlowChartFeatureFlowChartModule,
    SharedWorkflowDataAccessWorkflowModule,
    DemoFeatureDemoMaterialModule,
    RouterModule,
    SharedFlowChartUtilsFlowChartModule,
    SharedFlowChartDataAccessFlowChartModule,
    SharedInputDataAccessInputModule,
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    MatMenuModule,
  ],
  declarations: [WorkflowGroupFolderComponent,WorkflowListComponent, DemoWorkflowContainerComponent, DemoWorkflowsOverviewContainerComponent,DemoWorkflowGroupsOverviewContainerComponent],
  exports: [WorkflowGroupFolderComponent,WorkflowListComponent,DemoWorkflowContainerComponent, DemoWorkflowsOverviewContainerComponent, DemoWorkflowGroupsOverviewContainerComponent],
})
export class DemoFeatureDemoModule {}
