import { StepType } from '@workflow-admin/shared/workflow/utils/workflow';

export const stepTypeLabel = {
  [StepType.Info]: "Info",
  [StepType.QuestionSet]: "Vragenset",
  [StepType.Question]: "Vraag",
  [StepType.Decision]: "Beslissing",
  [StepType.Action]: "Actie",
  // [StepType.AddItems]: "Items toevoegen",
  // [StepType.SetVariables]: "Variabelen Instellen",
}
