import { StepType } from '../shared/workflow/utils/workflow/src/lib/models';

export interface MockWorkflow {
  uuid: string;
  discr: string;
  name: string;
  steps: MockWorkflowStep[];
  isMock: boolean;
}
export interface MockWorkflowCondition {
  condition: string;
  parentNode: string;
}

export interface MockWorkflowStep {
  id: string;
  type: StepType,
  label: string;
  text: string;
  links?: MockWorkflowCondition[];
}

export const workflowDakkapelSteps: MockWorkflowStep[] = [
  {
    id: '1',
    type: StepType.QuestionSet,
    label: 'VragenSet',
    text: 'Gegevens klant',
  },
  {
    id: '2',
    type: StepType.Question,
    label: 'Vraag',
    text: 'Kan er direct worden gestart?',
    links: [
      {
        condition: null,
        parentNode: '1'
      }
    ]
  },
  {
    id: '3',
    type: StepType.Decision,
    label: 'Beslissing',
    text: 'Direct starten?',
    links: [
      {
        condition: null,
        parentNode: '2'
      }
    ]
  },
  {
    id: '4',
    type: StepType.Question,
    label: 'Vraag',
    text: 'Geeft de klant toestemming om de handeling te starten?',
    links: [
      {
        condition: 'Vraag klant',
        parentNode: '3'
      }
    ]
  },
  {
    id: '5',
    type: StepType.Question,
    label: 'Vraag',
    text: 'Heb je alles bij je en heb je de kennis om direct te beginnen?',
    links: [
      {
        condition: 'Ja, start direct',
        parentNode: '3'
      }
    ]
  },
  {
    id: '6',
    type: StepType.Decision,
    label: 'Beslissing',
    text: 'Toestemming handeling starten?',
    links: [
      {
        condition: null,
        parentNode: '4'
      }
    ]
  },
  {
    id: '7',
    type: StepType.Decision,
    label: 'Beslissing',
    text: 'Items op voorraad en kennis?',
    links: [
      {
        condition: null,
        parentNode: '5'
      }
    ]
  },
  {
    id: '8',
    type: StepType.Action,
    label: 'Items toevoegen',
    text: 'Nabestelling',
    links: [
      {
        condition: 'Nee, geen toestemming',
        parentNode: '3'
      },
      {
        condition: 'Nee, advies',
        parentNode: '6'
      }
    ]
  },
  {
    id: '9',
    type: StepType.Action,
    label: 'Items toevoegen',
    text: 'Nabestelling',
    links: [
      {
        condition: 'Ja, nabestelling',
        parentNode: '6'
      },
      {
        condition: 'Nee, nabestelling',
        parentNode: '7'
      }
    ]
  },
  {
    id: '10',
    type: StepType.Action,
    label: 'Items toevoegen',
    text: 'Benodigdheden',
    links: [
      {
        condition: 'Ja, uitvoeren',
        parentNode: '6'
      },
      {
        condition: 'Ja, uitvoeren',
        parentNode: '7'
      }
    ]
  },
  {
    id: '11',
    type: StepType.Action,
    label: 'Variabelen instellen',
    text: 'Variabelen instellen',
    links: [
      {
        condition: null,
        parentNode: '8'
      }
    ]
  },
  {
    id: '12',
    type: StepType.Action,
    label: 'Variabelen instellen',
    text: 'Variabelen instellen',
    links: [
      {
        condition: null,
        parentNode: '9'
      }
    ]
  },
  {
    id: '13',
    type: StepType.Action,
    label: 'Variabelen instellen',
    text: 'Variabelen instellen',
    links: [
      {
        condition: null,
        parentNode: '10'
      }
    ]
  },
]

export const mockWorkflowDakkapel: MockWorkflow  = {
  uuid: '1',
  discr: 'workflow',
  name: 'Dakkapellen flow',
  steps: workflowDakkapelSteps,
  isMock: true
}
