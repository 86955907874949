import { Pipe, PipeTransform } from '@angular/core';

import { inputTypeLabel } from '../constants/input-type-labels.constant';

@Pipe({
  name: 'inputTypePipe'
})
export class InputTypePipe implements PipeTransform {

  transform(inputType: number): string {
    return inputTypeLabel[inputType];
  }

}
