import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import { ApiService } from '@workflow-admin/shared/data-access';

import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,   private apiService: ApiService) {}
  baseUrl = this.apiService.apiUrl

  getToken(): string {
    return localStorage.getItem('token');
  }

  logIn(email: string, password: string): Observable<any> {
    const url = `${this.baseUrl}/authentication_token`;
    return this.http.post<User>(url, {email, password});
  }
  getStatus(): Observable<User> {
    const url = `${this.baseUrl}/status`;
    return this.http.get<User>(url);
  }
}
