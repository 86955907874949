import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Workflow, WorkflowFull } from '@workflow-admin/shared/workflow/utils/workflow';
import { buyComputer, demoFlow, miniFlowDakkapel } from '@workflow-admin/testdata';

import * as WorkflowsActions from './workflows.actions';

export const WORKFLOWS_FEATURE_KEY = 'workflows';

export interface State extends EntityState<Workflow> {
  selectedId?: string; // which Workflows record has been selected
  loaded: boolean; // has the Workflows list been loaded
  error?: string | null; // last known error (if any)
  selectedWorkflow?: WorkflowFull;
  selectedWorkflowLoaded?: boolean;
}

export interface WorkflowsPartialState {
  readonly [WORKFLOWS_FEATURE_KEY]: State;
}

//TODO: consider using @id
export const workflowsAdapter: EntityAdapter<Workflow> = createEntityAdapter<
  Workflow
>({selectId: workflow => workflow.uuid});

export const initialState: State = workflowsAdapter.getInitialState({
  loaded: false,
  selectedId: null,
  selectedWorkflow: null,
  selectedWorkflowLoaded: false
})

const workflowsReducer = createReducer(
  initialState,
  on(WorkflowsActions.loadWorkflows, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(WorkflowsActions.loadWorkflowsSuccess, (state, { workflows }) =>
    // maybe this should be setAll on ...state to replace all
    workflowsAdapter.addMany(workflows, {
      ...state,
      loaded: true,
      entities: initialState.entities
    })
  ),
  on(WorkflowsActions.loadWorkflowsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(WorkflowsActions.createWorkflow, (state, { request}) =>
    workflowsAdapter.addOne({ ...request, steps: [] }, state)
  ),
  on(WorkflowsActions.createWorkflowSuccess, (state, { workflow}) =>
    workflowsAdapter.setOne(workflow, state)
  ),
  on(WorkflowsActions.deleteWorkflow, WorkflowsActions.deleteWorkflowSuccess, (state, {id}) =>
    workflowsAdapter.removeOne(id, state)
  ),
  on(WorkflowsActions.selectWorkflow, (state, {workflowId}) => {
      return {...state, selectedId: workflowId}
    }
  ),
  on(WorkflowsActions.clearSelectedWorkflow, (state) => ({
    ...state,
    selectedId: null,
    selectedWorkflow: null,
    selectedWorkflowLoaded: false
  })),
  on(WorkflowsActions.getWorkflow, (state) => ({
    ...state,
    selectedWorkflow: null,
    error: null,
    selectedWorkflowLoaded: false,
  })),
  on(WorkflowsActions.getWorkflowSuccess, (state, {workflow}) => ({
    ...state,
    selectedWorkflow: workflow,
    selectedWorkflowLoaded: true
  })),
  on(WorkflowsActions.editFirstStep, (state, {workflowUuid, firstStepUuid}) => {
    const workflowUpdate: Update<Workflow> = {
      id: workflowUuid,
      changes: {
        firstStepUuid
      }
    }
    return workflowsAdapter.updateOne(workflowUpdate, state)
  }),
  on(WorkflowsActions.editName, (state, {workflowUuid, name}) => {
    const workflowUpdate: Update<Workflow> = {
      id: workflowUuid,
      changes: {
        name
      }
    }
    return workflowsAdapter.updateOne(workflowUpdate, state)
  }),
  on(WorkflowsActions.editFirstStepSuccess, (state, {workflow}) =>
    workflowsAdapter.setOne(workflow, state)
  ),
  on(WorkflowsActions.editPosition, (state, {workflowUuid, position}) => {
    const workflowUpdate: Update<Workflow> = {
      id: workflowUuid,
      changes: {
        position
      }
    }
    return workflowsAdapter.updateOne(workflowUpdate, state)
  }),
  on(WorkflowsActions.editPositionSuccess, (state, {workflow}) =>
    workflowsAdapter.setOne(workflow, state)
  )
);

// TODO: add reducerfunctions for createWorkflowFailure

export function reducer(state: State | undefined, action: Action) {
  return workflowsReducer(state, action);
}
