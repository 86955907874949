import { createAction, props } from '@ngrx/store';

import {WorkflowGroup, WorkflowGroupRequest} from '@workflow-admin/shared/workflow/utils/workflow';

export const loadWorkflowGroups = createAction('[Workflows] Load WorkflowGroups');

export const loadWorkflowGroupsSuccess = createAction(
  '[WorkflowGroups] Load WorkflowGroups Success',
  props<{ workflowGroups: WorkflowGroup[] }>()
);

export const loadWorkflowGroupsFailure = createAction(
  '[WorkflowGroups] Load WorkflowGroups Failure',
  props<{ error: any }>()
);

export const selectWorkflowGroup = createAction(
  '[WorkflowGroups] Select WorkflowGroup',
  props<{workflowGroupId: string}>()
);

export const clearSelectedWorkflowGroup = createAction(  '[WorkflowGroups] Clear Selected WorkflowGroup');

export const createWorkflowGroup = createAction(
  '[WorkflowGroups] Create WorkflowGroup',
  props<{request: WorkflowGroupRequest}>()
)

export const createWorkflowGroupSuccess = createAction(
  '[WorkflowGroups] Create WorkflowGroup Success',
  props<{workflowGroup: WorkflowGroup}>()
)

export const createWorkflowGroupFailure = createAction(
  '[WorkflowGroups] Create WorkflowGroup Failure',
  props<{ error: any }>()
)

export const createFirstStep = createAction(
  '[WorkflowGroups] Create First Step',
  props<{workflowId: string}>()
)

export const deleteWorkflowGroup = createAction(
  '[WorkflowGroups] Delete WorkflowGroup',
  props<{id: string}>()
)

export const deleteWorkflowGroupSuccess = createAction(
  '[WorkflowGroups] Delete WorkflowGroup Success',
  props<{id: string}>()
)

export const deleteWorkflowGroupFailure = createAction(
  '[WorkflowGroups] Delete WorkflowGroup Failure',
  props<{ error: any }>()
)

export const getWorkflowGroup = createAction(
  '[WorkflowGroups] Get WorkflowGroup',
  props<{id: string}>()
)

export const getWorkflowGroupSuccess = createAction(
  '[WorkflowGroups] Get WorkflowGroup Success',
  props<{ workflowGroup: WorkflowGroup }>()
);

export const editWorkflowGroup = createAction(
  '[WorkflowGroups] Edit WorkflowGroup',
  props<{workflowGroup: WorkflowGroup}>()
)

export const editWorkflowGroupSuccess = createAction(
  '[WorkflowGroups] Edit WorkflowGroup Success',
  props<{workflowGroup: WorkflowGroup}>()
)
