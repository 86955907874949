import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { map } from 'rxjs/operators';
import * as WorkflowActionsActions
  from "../../../../../../../input/data-access/input/src/lib/+state/actions/actions.actions";

import { WorkflowGroupApiService } from '../../services';
import * as WorkflowGroupsActions from './workflowGroups.actions';

@Injectable()
export class WorkflowGroupsEffects {

  loadWorkflowGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowGroupsActions.loadWorkflowGroups),
      fetch({
        run: (action) => {
          return this.workflowApiService.getWorkflowGroups().pipe(
            map(workflowGroups =>  WorkflowGroupsActions.loadWorkflowGroupsSuccess({ workflowGroups }))
          )
        },
        onError: (action, error) => {
          console.error(error);
          return WorkflowGroupsActions.loadWorkflowGroupsFailure({ error });
        }
      })
    )
  );

  createWorkflowGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowGroupsActions.createWorkflowGroup),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.createWorkflowGroup(action.request).pipe(
            map(response => WorkflowGroupsActions.createWorkflowGroupSuccess({workflowGroup: response}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  editWorkflowGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowGroupsActions.editWorkflowGroup),
      optimisticUpdate({
        run: (action) => {
          console.log('update action');
          return this.workflowApiService.editWorkflowGroup(action.workflowGroup).pipe(
            map(response => WorkflowGroupsActions.editWorkflowGroupSuccess({workflowGroup: response}))
          )
        },
        undoAction(action, e) {
          return undo(action)
        }
      })
    )
  )

  deleteWorkflowGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowGroupsActions.deleteWorkflowGroup),
      optimisticUpdate({
        run: (action) => {
          return this.workflowApiService.deleteWorkflowGroup(action.id).pipe(
            map(response => WorkflowGroupsActions.deleteWorkflowGroupSuccess({id: action.id}))
          )
        },
        undoAction(action, e: any) {
          return undo(action)
        }
      })
    )
  )

  selectWorkflowGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowGroupsActions.selectWorkflowGroup),
      map((action) => WorkflowGroupsActions.getWorkflowGroup({ id: action.workflowGroupId }))
    )
  )

  constructor(
    private actions$: Actions,
    private workflowApiService: WorkflowGroupApiService) {}
}
