import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import { faArrowRight, faFolder,faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {faBars, faChevronRight, faClone} from '@fortawesome/pro-light-svg-icons';

import {WorkflowsFacade} from "@workflow-admin/shared/workflow/data-access/workflow";
import {ListItem, Workflow, WorkflowGroup} from '@workflow-admin/shared/workflow/utils/workflow';
import {BehaviorSubject} from "rxjs";

import {WorkflowGroupsFacade} from "../../../../../../../shared/workflow/data-access/workflow/src/lib/+state/workflowGroups";

@Component({
  selector: 'workflow-admin-workflow-group-folder',
  templateUrl: './workflow-group-folder.component.html',
  styleUrls: ['./workflow-group-folder.component.scss']
})

export class WorkflowGroupFolderComponent {
  @Input() workflowGroup: WorkflowGroup;

  public faArrowRight = faArrowRight;
  public faTrashAlt = faTrashAlt;
  public faFolder = faFolder;
  public faFolderOpen = faFolderOpen;

  public newWorkflowName = new FormControl('');
  public showWorkflowNameEdit = new BehaviorSubject(false);


  constructor(
    private workflowsFacade: WorkflowsFacade,
    private workflowGroupsFacade: WorkflowGroupsFacade,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
  ) {

  }
  ngOnInit(): void {
    this.workflowGroup.workflows = this.sortItemsByPosition(this.workflowGroup.workflows);
  }

  private sortItemsByPosition(items: Workflow[]): Workflow[] {
    return [...items].sort((prev, next) => (next.position > prev.position) ? -1 : 1)
  }
  public deleteWorkflowGroup(id: string): void {
    this.workflowGroupsFacade.deleteWorkflowGroup(id);
  }

  public onWorkflowMoved(items: Workflow[]): void {
    this.workflowGroup.workflows = this.sortItemsByPosition(items);
    console.log(items);
    this.workflowGroupsFacade.updateWorkflowGroupPositions({...this.workflowGroup,workflows:this.sortItemsByPosition(items)})

// this.workflowGroupsFacade.
    // this.stepEditForm.patchValue({...group,
    //   workflows: items
    // })
  }

  public onWorkflowSelected(item: Workflow): void {
    this.router.navigate(['/workflow/' + item.uuid]);
  }


  public addWorkflow(): void {
    this.showWorkflowNameEdit.next(true);
  }

  public createNewWorkflow(): void {
    this.workflowsFacade.addWorkflowInGroup(this.newWorkflowName.value,this.workflowGroup.uuid);
    this.showWorkflowNameEdit.next(false);
    this.workflowGroupsFacade.getAllWorkflowGroups();
  }

  public cancelCreate(): void {
    this.showWorkflowNameEdit.next(false);
    this.newWorkflowName.reset();
  }

  public deleteWorkflow(id: string): void {
    this.workflowsFacade.deleteWorkflow(id);
  }
}
