import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faChevronDown, faSearch, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { WorkflowStep } from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-edit-first-step-dialog',
  templateUrl: './edit-first-step-dialog.component.html',
  styleUrls: ['./edit-first-step-dialog.component.scss']
})
export class EditFirstStepDialogComponent implements OnInit {
  public editFirstStepForm: FormGroup
  public faSearch = faSearch;
  public faChevronDown = faChevronDown;
  public faTrashAlt = faTrashAlt;
  public faClose = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {workflowUuid: string, firstStepUuid: string, steps: WorkflowStep[]},
  ) { }

  ngOnInit(): void {
    this.editFirstStepForm = new FormGroup(
      {
        workflowUuid: new FormControl('', Validators.required),
        firstStepUuid: new FormControl('', Validators.required)
      }
    )

    if(this.dialogData){
      this.editFirstStepForm.patchValue(this.dialogData)
    }
  }

  public removeFirstStep(): void {
    this.editFirstStepForm.controls.firstStepUuid.reset();
  }

}
