import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Edge } from '@swimlane/ngx-graph';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import {
  EdgesPipe, EditStepDialogService,
  NodesPipe
} from '@workflow-admin/shared/flow-chart/feature/flow-chart';
import { EditFirstStepDialogComponent } from '@workflow-admin/shared/flow-chart/ui/flow-chart';
import {
  FlowChartNode
} from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { WorkflowsFacade } from '@workflow-admin/shared/workflow/data-access/workflow';
import { StepType, WorkflowStep } from '@workflow-admin/shared/workflow/utils/workflow';


@Component({
  selector: 'workflow-admin-demo-workflow-container',
  templateUrl: './demo-workflow-container.component.html',
  styleUrls: ['./demo-workflow-container.component.scss']
})
export class DemoWorkflowContainerComponent implements OnInit, OnDestroy {

  public workflows$ = this.workflowsFacade.allWorkflows$;
  public selectedFlowId$ = this.workflowsFacade.selectedWorkflowId$;
  public selectedWorkflow$ = this.workflowsFacade.selectedWorkflow$;
  public selectedWorkflowFirstStepUuid$ = this.workflowsFacade.selectedWorkflowFirstStepUuid$;
  public selectedWorkflowSteps$ = this.stepsFacade.selectedWorkflowSteps$;
  public selectedStepUuid$ = this.stepsFacade.selectedId$;

  private unsubscribe$ = new Subject();
  private stepType = StepType;

  public flowChartEdges$ = new BehaviorSubject<Edge[]>([]);
  public flowChartNodes$ = new BehaviorSubject<FlowChartNode[]>([]);


  constructor(
    private workflowsFacade: WorkflowsFacade,
    private stepsFacade: StepsFacade,
    private route: ActivatedRoute,
    private editStepDialogService: EditStepDialogService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
    console.log('demo workflow');

    this.workflowsFacade.loaded$.pipe(
      filter(loaded => !loaded),
      takeUntil(this.unsubscribe$)
    ).subscribe(_ => this.workflowsFacade.getAllWorkflows())

    const routeId = this.route.snapshot.paramMap.get('id');
    this.workflowsFacade.selectWorkflow(routeId);

    const edgesPipe = new EdgesPipe();
    const nodesPipe = new NodesPipe();

    combineLatest([this.selectedWorkflowSteps$, this.selectedStepUuid$, this.selectedWorkflowFirstStepUuid$]).pipe(
      filter(([steps, selectedStepUuid, firstStepUuid]) => !!steps),
      takeUntil(this.unsubscribe$)
  ).subscribe(([steps, selectedStepUuid, firstStepUuid]) => {
      if(steps.length) {


        const links = edgesPipe.transform(steps, firstStepUuid);
        const nodes = nodesPipe.transform(steps, selectedStepUuid);
        // console.log('links',links);
        // console.log('nodes',nodes);
        // console.log('steps',steps);
        this.flowChartEdges$.next(links);
        this.flowChartNodes$.next(nodes);

      } else {
        this.workflowsFacade.getWorkflow(routeId)
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.workflowsFacade.clearSelectedWorkflow();
  }

  onSelectedFlowChanged(id: string) {
    this.workflowsFacade.selectWorkflow(id);
  }

  onAddStep(parentStepId: string) {
    this.stepsFacade.getStep(parentStepId).pipe(
      take(1)
    ).subscribe(parentStep => {
      if(parentStep.type === this.stepType.Decision || !parentStep.decisions?.length) {
        this.addNextStep(parentStepId);
      } else {
      this.addStepInBetween(parentStep)
      }
    })
  }

  private addNextStep(parentStepId: string) {
    this.selectedFlowId$.pipe(
      take(1),
      switchMap(workflowId => this.stepsFacade.addStepAndUpdateParent(parentStepId, workflowId)),
      filter(step => !!step),
      take(1),
    ).subscribe(newStep => this.editStepDialogService.openEditStepDialog(newStep))
  }

  private addStepInBetween(parentStep: WorkflowStep) {
    const previousNextStepUuid = parentStep?.decisions[0]?.nextStepUuid;
    const previousDecisionUuid = parentStep?.decisions[0]?.uuid;

    this.selectedFlowId$.pipe(
      take(1),
      switchMap(workflowId => this.stepsFacade.addStepInBetween(parentStep.uuid, previousNextStepUuid, workflowId, previousDecisionUuid)),
      filter(step => !!step),
      take(1),
    ).subscribe(newStep => this.editStepDialogService.openEditStepDialog(newStep))
  }


  onEditStep(node: FlowChartNode): void {
    this.stepsFacade.getStep(node.id).pipe(
      take(1)
    ).subscribe(selectedStep => this.editStepDialogService.openEditStepDialog(selectedStep))
  }

  onEditFirstStep(): void {
    let editFirstStepDialogRef;

    combineLatest([this.selectedFlowId$, this.selectedWorkflowFirstStepUuid$, this.selectedWorkflowSteps$]).pipe(
      filter(([flowId, firstStepUuid, steps]) => !!flowId && !!steps && !!steps.length),
      take(1)
    ).subscribe(([flowId, firstStepUuid, steps]) => {
      editFirstStepDialogRef = this.matDialog.open<EditFirstStepDialogComponent>(EditFirstStepDialogComponent, {
        autoFocus: false,
        width: '528px',
        data: {
          workflowUuid: flowId,
          firstStepUuid: firstStepUuid,
          steps
        }
      })
    })

    editFirstStepDialogRef.afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(result => this.workflowsFacade.editFirstStep(result.workflowUuid, result.firstStepUuid))
  }

  onRemoveWorkflow() {
    this.workflowsFacade.deleteSelectedWorkflow();
  }
  onRenameWorkflow(newName) {
    console.log(newName);
    this.workflowsFacade.renameWorkflow(newName);
  }
}
