<div class="flow-chart-container">
  <ngx-graph
    [links]="edges"
    [nodes]="nodes"
    [curve]="curve"
    [layoutSettings]="layoutSettings"
    [autoCenter]="true"
    [autoZoom]="true"
    [enableZoom]="true"
    [draggingEnabled]="false"
  >

    <ng-template #defsTemplate>
      <svg:marker id="circle" viewBox="0 0 10 10" refX="-2" refY="0" markerWidth="10" markerHeight="10" orient="auto" overflow="visible">
        <svg:circle r="2" fill="rgba(45,107,251,0.8)" />
      </svg:marker>
    </ng-template>

    <ng-template #nodeTemplate let-node>
      <ng-container *ngIf="node.type === 'starter'; else step">
        <svg:g>
          <svg:foreignObject width="100" height="22" overflow="visible">
            <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
              <workflow-admin-starter-component [starter]="node" (click)="showEditFirstStep()"></workflow-admin-starter-component>
            </xhtml:div>
          </svg:foreignObject>
        </svg:g>
      </ng-container>
      <ng-template #step>
        <svg:g>
          <svg:foreignObject width="170" height="68" overflow="visible">
            <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
              <workflow-admin-step [nodeStep]="node" (click)="showEditDialog(node)" (addNextStep)="onAddStep($event)"></workflow-admin-step>
            </xhtml:div>
          </svg:foreignObject>
        </svg:g>
      </ng-template>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge">
        <svg:path class="line" stroke-width="1" marker-start="url(#circle)" stroke="rgba(45,107,251,0.3)"></svg:path>
      </svg:g>
      <svg:foreignObject
        *ngIf="link.midPoint && link.data.linkText"
        [attr.transform]="'translate(' + (link.midPoint.x - 50) + ',' + link.midPoint.y + ')'"
        width="100"
        height="70"
        overflow="visible"
      >
        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
          <workflow-admin-condition [condition]="link"></workflow-admin-condition>
        </xhtml:div>
      </svg:foreignObject>
    </ng-template>
  </ngx-graph>
</div>


<!--</ng-container>-->

<!--<ng-template #alternative>-->
<!--  <div *ngIf="currentWorkflow; else notFound"[innerHTML]="currentWorkflow | prettyjson"></div>-->
<!--</ng-template>-->

<!--<ng-template #notFound>Workflow not found</ng-template>-->
