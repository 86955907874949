<div mat-dialog-title>
  <div class="edit-first-step-dialog__header">
    <h1 class="edit-first-step-dialog__title">Kies eerste stap</h1>
    <button
      mat-button
      class="edit-first-step-dialog__header-button"
      [autofocus]="true"
      mat-dialog-close>
      <fa-icon class="edit-first-step-dialog__header-icon" [icon]="faClose"></fa-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <form [formGroup]="editFirstStepForm">
<!--    <div class="edit-first-step-label">-->
<!--      Kies eerste stap-->
<!--    </div>-->
    <div class="edit-first-step-dialog__select-first-step">
      <mat-form-field appearance="fill" class="select-first-step__input">
        <fa-icon [icon]="faSearch" matPrefix class="select-first-step__search-icon"></fa-icon>
        <mat-select formControlName="firstStepUuid" placeholder="Kies">
          <mat-option *ngFor="let step of this.dialogData.steps" [value]="step.uuid">
            {{step.name || step.uuid}}
          </mat-option>
        </mat-select>
        <fa-icon [icon]="faChevronDown" matSuffix class="select-first-step__chevron"></fa-icon>
      </mat-form-field>
<!--      <button mat-button (click)="removeFirstStep()">-->
<!--        <fa-icon class="remove-first-step__icon" [icon]="faTrashAlt"></fa-icon>-->
<!--      </button>-->
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="edit-first-step-dialog__action-container">
  <button
    mat-flat-button
    color="primary"
    class="edit-first-step-dialog__button"
    [disabled]="editFirstStepForm.invalid"
    [mat-dialog-close]="this.editFirstStepForm.value">
    Aanpassen
  </button>
</div>
