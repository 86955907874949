import { Pipe, PipeTransform } from '@angular/core';


import { actionTypeLabel } from "../constants/action-type-labels.constant";


@Pipe({
  name: 'actionTypePipe'
})
export class ActionTypePipe implements PipeTransform {

  transform(actionType: string): string {
    return actionTypeLabel[actionType];
  }

}
