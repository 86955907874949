export enum Operators {
  Equal = 'equal',
  NotEqual = 'not_equal',
  GreaterThan = 'greater_than',
  LessThan = 'less_than',
  GreaterThanOrEqual = 'greater_than_or_equal',
  LessThanOrEqual = 'less_than_or_equal',
  Contains = 'contains',
  NotContains = 'not_contains',
  IsSet = 'is_set',
  IsNotSet = 'is_not_set',
  IsValid = 'is_valid',
}
