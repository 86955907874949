import { QuestionType, WorkflowForm } from '../shared/workflow/utils/workflow/src/lib/models';

export const demoForms: WorkflowForm[] = [
   {
      "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
      "@type": "Form",
      "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109",
      "name": "start_immediately",
      "fields": [
      {
        "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3c",
        "@type": "Field",
        "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3c",
        "position": 0,
        "type": QuestionType.Boolean,
        "label": {
          "show": true,
          "text": "Geeft de klant toestemming om de handeling te starten?"
        },
        "placeholder": {
          "show": false,
          "text": null
        },
        "valueOptions": ["ja", "nee"],
        "required": true,
        "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9109"
      }
    ],
  },
  {
    "@id": "/forms/9ced106b-f331-4a5e-aa21-6b9b8dfd9108",
    "@type": "Form",
    "uuid": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108",
    "name": "start_immediately",
    "fields": [
      {
        "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3d",
        "@type": "Field",
        "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3d",
        "position": 0,
        "type": QuestionType.Text,
        "label": {
          "show": true,
          "text": "Wat is je voornaam?"
        },
        "placeholder": {
          "show": false,
          "text": null
        },
        "valueOptions": [],
        "required": true,
        "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108"
      },
      {
        "@id": "/fields/f049859d-8601-4b82-a1e7-4c717c166a3e",
        "@type": "Field",
        "uuid": "f049859d-8601-4b82-a1e7-4c717c166a3e",
        "position": 0,
        "type": QuestionType.Text,
        "label": {
          "show": true,
          "text": "Wat is je achternaam?"
        },
        "placeholder": {
          "show": false,
          "text": null
        },
        "valueOptions": [],
        "required": true,
        "form": "9ced106b-f331-4a5e-aa21-6b9b8dfd9108"
      }
    ],
  }
]
