import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faChevronDown, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import {GenerateUuidService} from "@workflow-admin/shared/utils";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Field, QuestionType } from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})

export class EditQuestionComponent implements OnInit, OnDestroy {
  @Input() question: Field;
  @Input() isQuestionSet: boolean;
  @Output() questionChanged = new EventEmitter<Field>();

  public questionEditForm: FormGroup;
  public faChevronDown = faChevronDown;
  public faTrashAlt = faTrashAlt;
  public questionTypes = Object.values(QuestionType);
  public canAddAnswer = false;
  public showAnswersControls = false;

  private unsubscribe$ = new Subject();

  constructor( private generateUuidService: GenerateUuidService) { }

  ngOnInit(): void {
    this.initializeQuestionEditForm();
    this.listenForQuestionEditFormChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addAnswer(): void {
    this.answersControl.push(new FormControl(''))
  }

  public deleteAnswer(index: number): void {
    this.answersControl.removeAt(index);
  }
  public addFieldAttribute(): void {
    this.attributeControl.push(this.getFieldAttributeForm())
  }

  public deleteFieldAttribute(index: number): void {
    this.attributeControl.removeAt(index);
    console.log(this.attributeControl);
  }

  private initializeQuestionEditForm(): void {
    this.questionEditForm = this.getQuestionEditForm();

    if(this.question){
      const {valueOptions, ...partialQuestion} = this.question;
      this.questionEditForm.patchValue(partialQuestion);
    }

    if(this.question?.valueOptions?.length) {
      this.question.valueOptions.forEach(option => {
        this.answersControl.push(new FormControl(option))
        this.showAnswersControls = true;
      })
      this.initializeQuestionAnswers(this.question.type)
    }
    if(this.question?.fieldAttributes?.length) {
      this.question.fieldAttributes.forEach(option => {
        // this.attributeControl.push(new FormControl(option))
        this.attributeControl.push(
          new FormGroup({
            uuid: new FormControl(option.uuid),
            name: new FormControl(option.name),
            value: new FormControl(option.value, {updateOn: 'blur'}),
          })
        )
      })
    }
  }

  private getFieldAttributeForm(): FormGroup {
    return new FormGroup({
      uuid: new FormControl(this.generateUuidService.generateUuid()),
      name: new FormControl(''),
      value: new FormControl('', {updateOn: 'blur'}),
    })
  }

  private getQuestionEditForm(): FormGroup {
    return new FormGroup({
      uuid: new FormControl('', Validators.required),
      position: new FormControl(''),
      type: new FormControl('', Validators.required),
      label: new FormGroup({
        show: new FormControl(true),
        text: new FormControl('')
      }),
      valueOptions: new FormArray([]),
        fieldAttributes: new FormArray([]),
      required: new FormControl(true),
    })
  }

  private listenForQuestionEditFormChanges(): void {
    this.questionEditForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(formValue => {
      if(formValue.position === "")
        formValue.position = 0;
      this.questionChanged.emit(formValue);
    })

    this.questionEditForm.get('type').valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(type => this.resetQuestionAnswers(type))
  }


  private resetQuestionAnswers(type: string): void {
    if(this.answersControl)
     this.answersControl.clear();
    this.initializeQuestionAnswers(type);
    switch (type) {
      case QuestionType.Boolean:
        this.answersControl.push(new FormControl({ value: 'ja', disabled: false }));
        this.answersControl.push(new FormControl({ value: 'nee', disabled: false }));
      break;
      case QuestionType.Radio:
      case QuestionType.CheckboxSingle:
      case QuestionType.CheckboxMultiple:
        this.answersControl.push(new FormControl(''));
        this.answersControl.push(new FormControl(''));
        break;
    }
  }
  private initializeQuestionAnswers(type: string): void {
    switch (type) {
      case QuestionType.Boolean:
        this.canAddAnswer = false;
        this.showAnswersControls = true;
        break;
      case QuestionType.Radio:
      case QuestionType.CheckboxSingle:
      case QuestionType.CheckboxMultiple:
        this.canAddAnswer = true;
        this.showAnswersControls = true;
        break;
      case QuestionType.Text:
      case QuestionType.Integer:
      case QuestionType.Float:
      case QuestionType.Date:
      case QuestionType.Time:
      case QuestionType.DateTime:
      default:
        this.canAddAnswer = false;
        this.showAnswersControls = false;
        break;
    }
  }

  get answersControl(): FormArray {
    return this.questionEditForm.controls.valueOptions as FormArray;
  }
  get attributeControl(): FormArray {
    return this.questionEditForm.controls.fieldAttributes as FormArray; //@todo
  }

  get alternativeMessage(): string {
    const type = this.questionEditForm.get('type').value;
    switch (type) {
      case QuestionType.Integer:
        return 'Open vraag (getal)'
      case QuestionType.Text:
        return 'Open vraag (tekst)'
      case QuestionType.Date:
        return 'Open vraag (datum)'
      default:
        return undefined
    }
  }

}
