import { createAction, props } from '@ngrx/store';

import { Workflow, WorkflowFull, WorkflowRequest } from '@workflow-admin/shared/workflow/utils/workflow';

export const loadWorkflows = createAction('[Workflows] Load Workflows');

export const loadWorkflowsSuccess = createAction(
  '[Workflows] Load Workflows Success',
  props<{ workflows: Workflow[] }>()
);

export const loadWorkflowsFailure = createAction(
  '[Workflows] Load Workflows Failure',
  props<{ error: any }>()
);

export const selectWorkflow = createAction(
  '[Workflows] Select Workflow',
  props<{workflowId: string}>()
);

export const clearSelectedWorkflow = createAction(  '[Workflows] Clear Selected Workflow');

export const createWorkflow = createAction(
  '[Workflows] Create Workflow',
  props<{request: WorkflowRequest}>()
)

export const createWorkflowSuccess = createAction(
  '[Workflows] Create Workflow Success',
  props<{workflow: Workflow}>()
)

export const createWorkflowFailure = createAction(
  '[Workflows] Create Workflow Failure',
  props<{ error: any }>()
)

export const createFirstStep = createAction(
  '[Workflows] Create First Step',
  props<{workflowId: string}>()
)

export const deleteWorkflow = createAction(
  '[Workflows] Delete Workflow',
  props<{id: string}>()
)

export const deleteWorkflowSuccess = createAction(
  '[Workflows] Delete Workflow Success',
  props<{id: string}>()
)

export const deleteWorkflowFailure = createAction(
  '[Workflows] Delete Workflow Failure',
  props<{ error: any }>()
)

export const getWorkflow = createAction(
  '[Workflows] Get Workflow',
  props<{id: string}>()
)

export const getWorkflowSuccess = createAction(
  '[Workflows] Get Workflow Success',
  props<{ workflow: WorkflowFull }>()
);

export const editFirstStep = createAction(
  '[Workflows] Edit First Step',
  props<{ workflowUuid: string, firstStepUuid: string }>()
);
export const editName = createAction(
  '[Workflows] Name Step',
  props<{ workflowUuid: string, name: string }>()
);

export const editFirstStepSuccess = createAction(
  '[Workflows] Edit First Step Success',
  props<{ workflow: Workflow }>()
);

export const editPosition = createAction(
  '[Workflows] Edit Position',
  props<{ workflowUuid: string, position: number }>()
);
export const editPositionSuccess = createAction(
  '[Workflows] Edit Position Success',
  props<{ workflow: Workflow }>()
);
