import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { StepsFacade } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { DeleteStepConfirmDialogComponent,  } from '@workflow-admin/shared/flow-chart/ui/flow-chart';
import { EditStepDialogData, EditStepDialogResult } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { ActionsFacade, FormsFacade } from '@workflow-admin/shared/input/data-access/input';
import { StepType, WorkflowStep } from '@workflow-admin/shared/workflow/utils/workflow';

import {EditStepDialogContainerComponent} from '../containers/edit-step-dialog-container/edit-step-dialog-container.component';

@Injectable({
  providedIn: 'root'
})
export class EditStepDialogService {

  constructor(
    private matDialog: MatDialog,
    private stepsFacade: StepsFacade,
    private formsFacade: FormsFacade,
    private actionsFacade: ActionsFacade
  ) { }

  openEditStepDialog(step: WorkflowStep): void {
    this.formsFacade.clearSelectedForm();

    this.stepsFacade.selectStep(step.uuid);

    this.matDialog.open<EditStepDialogContainerComponent, EditStepDialogData, EditStepDialogResult>(
      EditStepDialogContainerComponent, {
      autoFocus: false,
      width: '528px',
      data: { step }
    }).afterClosed().pipe(
      tap(_ => this.stepsFacade.clearSelectedStep()),
      filter(result => !!result)
    ).subscribe(result => {
      this.processEditResult(result, step)
    });
  }

  private processEditResult(result: EditStepDialogResult, stepBefore: WorkflowStep) {

    if(result.deleteStep) {
      this.deleteStep(stepBefore)
      return;
    }

    this.stepsFacade.editStep(result)

    if([StepType.QuestionSet, StepType.Question].includes(result.type)){
      this.formsFacade.editForm(result);
    } else if (this.hasForm(stepBefore)) {
      this.formsFacade.deleteForm(result.input)
    }

    if(result.type === StepType.Action) {
      this.actionsFacade.editAction(result);
    }
  }

  private deleteStep(stepBefore: WorkflowStep) {
    if(this.hasForm(stepBefore)) {
      this.deleteForm(stepBefore)
    } else {
      this.stepsFacade.deleteStep(stepBefore);
    }
  }


  private deleteForm(stepBefore: WorkflowStep) {
    this.formIsUsedInConditions(stepBefore.input).pipe(
      filter(filteredSteps => !!filteredSteps),
      take(1)
    ).subscribe(filteredSteps => {
      if(!filteredSteps.length) {
        this.stepsFacade.deleteStep(stepBefore);
        this.formsFacade.deleteForm(stepBefore.input);
      }

      if(filteredSteps.length) {
        this.matDialog.open<DeleteStepConfirmDialogComponent>(
          DeleteStepConfirmDialogComponent, {
            autoFocus: false,
            width: '528px',
            data: {
              step: stepBefore,
              steps: filteredSteps
            }
          }
        ).afterClosed().pipe(
          filter(confirm => !!confirm)
        ).subscribe(_ => {
          this.stepsFacade.deleteStep(stepBefore);
          this.formsFacade.deleteForm(stepBefore.input);
        })
      }

    })
  }

  private hasForm(step: EditStepDialogResult | WorkflowStep): boolean {
    if(step?.input && step.input?.length && step.input?.indexOf("forms") !== -1 ) {
      return true;
    }
    return false;
  }

  private formIsUsedInConditions(formId: string): Observable<WorkflowStep[]> {
    return this.stepsFacade.selectedWorkflowSteps$.pipe(
      filter(steps => !!steps && !!steps.length),
      take(1),
      map(steps => steps.filter(step => {
        return (!!step?.decisions?.length) &&
        step.decisions.some(decision => {
          return (!!decision?.conditionSets?.length)
            && decision.conditionSets.some(conditionSet => {
              return (!!conditionSet?.conditions?.length)
                && conditionSet.conditions.some(
                  condition => condition?.input['@id'] === formId
              )
            })
        });
      }))
    )
  }
  private stepIsUsedInstep(formId: string): Observable<WorkflowStep[]> {
    return this.stepsFacade.selectedWorkflowSteps$.pipe(
      filter(steps => !!steps && !!steps.length),
      take(1),
      map(steps => steps.filter(step => {
        return (!!step?.decisions?.length) &&
          step.decisions.some(decision => {
            return (!!decision?.conditionSets?.length)
              && decision.conditionSets.some(conditionSet => {
                return (!!conditionSet?.conditions?.length)
                  && conditionSet.conditions.some(
                    condition => condition?.input['@id'] === formId
                  )
              })
          });
      }))
    )
  }
}

