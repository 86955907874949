import { Pipe, PipeTransform } from '@angular/core';
import { Edge } from '@swimlane/ngx-graph';

import { MockWorkflowStep } from '@workflow-admin/testdata';

@Pipe({
  name: 'mockEdgesPipe'
})
export class MockEdgesPipe implements PipeTransform {

  transform(steps: MockWorkflowStep[]): Edge[] {
    const links = [];
    for (const step of steps) {
      if(!step.links || !step.links.length) {
        continue;
      }

      for (const link of step.links) {
        const edge: Edge = {
          source: link.parentNode,
          target: step.id,
          data: {
            linkText: link.condition
          }
        };
        links.push(edge);
      }
    }

    return links;
  }
}
