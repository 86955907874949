<div mat-dialog-title>
  <div class="delete-step-confirm-dialog__header">
    <h1 class="delete-step-confirm-dialog__title">Let op!</h1>
  </div>
</div>

<mat-dialog-content class="delete-step-confirm-dialog__content">
  <span>De stap die u wilt verwijderen bevat een {{dialogData.step.type | stepTypePipe | lowercase}}. Deze wordt gebruikt in de condities van de volgende stappen:</span>
  <ul class="delete-step-confirm-dialog__step-list">
    <li *ngFor="let step of dialogData.steps">{{step.type | stepTypePipe}}: {{step.name}}</li>
  </ul>
  <span>Weet u zeker dat u de stap wilt verwijderen? Zo ja, dan worden ook de bijbehorende condities verwijderd.</span>
</mat-dialog-content>

<div mat-dialog-actions class="delete-step-confirm-dialog__action-container">
  <button
    mat-button
    color="primary"
    class="delete-step-confirm-dialog__button-cancel"
    [mat-dialog-close]="false">
    Annuleren
  </button>
  <button
    mat-flat-button
    color="primary"
    class="delete-step-confirm-dialog__button-confirm"
    [mat-dialog-close]="true">
    Verwijderen
  </button>
</div>
