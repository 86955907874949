import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faArrowRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { WorkflowsFacade } from '@workflow-admin/shared/workflow/data-access/workflow';

@Component({
  selector: 'workflow-admin-demo-workflows-overview-container',
  templateUrl: './demo-workflows-overview-container.component.html',
  styleUrls: ['./demo-workflows-overview-container.component.scss']
})
export class DemoWorkflowsOverviewContainerComponent implements OnInit, OnDestroy {
  public workflows$ = this.workflowsFacade.allWorkflows$;

  public faArrowRight = faArrowRight;
  public faTrashAlt = faTrashAlt;

  public newWorkflowName = new FormControl('');
  public showWorkflowNameEdit = new BehaviorSubject(false);

  private unsubscribe$ = new Subject();

  constructor(
    private workflowsFacade: WorkflowsFacade,

  ) { }

  ngOnInit(): void {
    this.workflowsFacade.loaded$.pipe(
      filter(loaded => !loaded),
      takeUntil(this.unsubscribe$)
    ).subscribe(_ => this.workflowsFacade.getAllWorkflows())
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addWorkflow(): void {
    this.showWorkflowNameEdit.next(true);
  }

  public createNewWorkflow(): void {
    this.workflowsFacade.addWorkflow(this.newWorkflowName.value);
    this.showWorkflowNameEdit.next(false);
  }

  public cancelCreate(): void {
    this.showWorkflowNameEdit.next(false);
    this.newWorkflowName.reset();
  }

  public deleteWorkflow(id: string): void {
    this.workflowsFacade.deleteWorkflow(id);
  }

}
