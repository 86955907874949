import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import * as WorkflowActions from '@workflow-admin/shared/workflow/data-access/workflow';
import { WorkflowAction, WorkflowForm } from '@workflow-admin/shared/workflow/utils/workflow';

import * as WorkflowActionActions from '../actions/actions.actions';
import * as FormsActions from '../forms/forms.actions';

@Injectable()
export class InputsEffects {
  loadSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowActions.getWorkflowSuccess),
      switchMap(action => {
        const inputs = action.workflow.steps.map(step => step.input).filter(input => !!input);
        const forms = inputs.filter(input => input['@type'] === 'Form') as WorkflowForm[];
        const actions = inputs.filter(input => input['@type'] === 'Action') as WorkflowAction[];
        return [FormsActions.loadFormsForWorkflowSuccess({ forms }), WorkflowActionActions.loadActionsSuccess({actions})]
      })
    )
  );

  constructor(
    private actions$: Actions
  ) {}
}
