import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedFlowChartUtilsFlowChartModule } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { SharedUtilsModule } from '@workflow-admin/shared/utils';
import { SharedWorkflowDataAccessWorkflowModule } from '@workflow-admin/shared/workflow/data-access/workflow';
import { SharedWorkflowUtilsWorkflowModule } from '@workflow-admin/shared/workflow/utils/workflow';

import { StepsEffects } from './+state/steps/steps.effects';
import { StepsFacade } from './+state/steps/steps.facade';
import * as fromSteps from './+state/steps/steps.reducer';
import { StepApiService, StepEditService } from './services';

@NgModule({
  imports: [
    CommonModule,
    SharedFlowChartUtilsFlowChartModule,
    SharedWorkflowDataAccessWorkflowModule,
    SharedWorkflowUtilsWorkflowModule,
    SharedUtilsModule,
    StoreModule.forFeature(fromSteps.STEPS_FEATURE_KEY, fromSteps.reducer),
    EffectsModule.forFeature([StepsEffects]),
  ],
  providers:  [ StepsFacade, StepEditService, StepApiService]
})
export class SharedFlowChartDataAccessFlowChartModule {}
