import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepEditService } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import { DecisionCondition, DecisionConditionSet, } from '@workflow-admin/shared/workflow/utils/workflow';

import { ConditionFormResult } from '../edit-condition/edit-condition-container.component';

export interface ConditionSetFormResult {
  uuid: string
  decision: string
  conditions: DecisionCondition[]
}

@Component({
  selector: 'workflow-admin-edit-condition-set',
  templateUrl: './edit-condition-set-container.component.html',
  styleUrls: ['./edit-condition-set-container.component.scss']
})
export class EditConditionSetContainerComponent implements OnInit, OnDestroy {
  @Input() conditionSet: DecisionConditionSet;
  @Output() removeConditionSet = new EventEmitter<void>();
  @Output() conditionSetChanged = new EventEmitter<ConditionSetFormResult>();

  public conditionSetForm: FormGroup;
  public faTrashAlt = faTrashAlt;

  private unsubscribe$ = new Subject();


  constructor(
    private stepEditService: StepEditService
  ) { }

  ngOnInit(): void {
    this.conditionSetForm = new FormGroup({
      uuid: new FormControl(''),
      decision: new FormControl(''),
      conditions: new FormControl([])
    })

    this.conditionSetForm.patchValue(this.conditionSet)

    this.conditionSetForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(formValue => {
      this.conditionSetChanged.emit(formValue);
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addCondition(): void {
    const newCondition = this.stepEditService.generateDecisionCondition(this.conditionSet.uuid)
    const currentConditions = this.conditions || [];
    this.conditionSetForm.patchValue({conditions: currentConditions.concat(newCondition)})
  }

  public removeCondition(index: number) {
    const updatedConditions = [...this.conditions];
    updatedConditions.splice(index, 1);
    this.conditionSetForm.patchValue({conditions: updatedConditions})
  }

  public onConditionChanged(conditionEditResult: ConditionFormResult, index: number) {
    const updatedConditions = [...this.conditions];
    updatedConditions.splice(index, 1, conditionEditResult);
    this.conditionSetForm.patchValue({conditions: updatedConditions})
  }

  get conditions(): DecisionCondition[] {
    return this.conditionSetForm?.get('conditions')?.value
  }

}
