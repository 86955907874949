<workflow-admin-flow-chart-header
  [workflows]="workflows$ | async"
  [currentWorkflow]="selectedWorkflow$ | async"
  [selectedFlowId]="selectedFlowId$ | async"
  (selectedFlowChanged)="onSelectedFlowChanged($event)"
  (removeWorkflow)="onRemoveWorkflow()"
  (renameWorkflow)="onRenameWorkflow($event)"
></workflow-admin-flow-chart-header>

<workflow-admin-flow-chart-container
  class="flow-chart"
  [nodes]="flowChartNodes$ | async"
  [edges]="flowChartEdges$ | async"
  (addStep)="onAddStep($event)"
  (editStep)="onEditStep($event)"
  (editFirstStep)="onEditFirstStep()"
></workflow-admin-flow-chart-container>


