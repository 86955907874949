import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@workflow-admin/shared/data-access';
import {  WorkflowAction } from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class ActionsApiService {

  baseUrl = this.apiService.apiUrl

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  createAction(action: WorkflowAction): Observable<WorkflowAction> {
    return this.http.post<WorkflowAction>(`${this.baseUrl}/actions`, action).pipe(
      catchError(this.handleError)
    )
  }

  editAction(action: WorkflowAction): Observable<WorkflowAction> {
    return this.http.put<WorkflowAction>(`${this.baseUrl}/actions/${action.uuid}`, action).pipe(
      catchError(this.handleError)
    )
  }

  deleteAction(id: string) {
    return this.http.delete(`${this.baseUrl}/actions/${id}`).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
