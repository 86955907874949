<mat-toolbar class="demo-container__header">

  <div>
    <button mat-button color="primary" class="demo-container__header__back-button" [routerLink]="['/groups']">
      <fa-icon class="demo-container__header__back-button__icon" [icon]="faArrowLeft"></fa-icon>
      <span class="demo-container__header__back-button__label">Terug naar overzicht</span>
    </button>
  </div>
  <div class="demo-container__header__flows">
    <span class="title" *ngIf="currentWorkflow && !showWorkflowNameEdit" (click)="showWorkflowNameEdit = true">{{currentWorkflow.name}}</span>
    <div *ngIf="showWorkflowNameEdit" class="workflows-overview-container__input-container">
<!--      <form class="example-form">-->
<!--      <mat-form-field appearance="fill" color="primary">-->
        <input class="change-workflow-name-input" [(ngModel)]="currentWorkflow.name" placeholder="Nieuwe naam">
<!--      </mat-form-field>-->

<!--      <div class="workflows-overview-container__input-buttons">-->
<!--        <button-->
<!--          class="workflows-overview-container__input-cancel-buttons"-->
<!--          mat-flat-button-->
<!--          color="secondary"-->
<!--          (click)="cancelCreate()">-->
<!--          Annuleren-->
<!--        </button>-->
        <button
          mat-icon-button
          color="primary"
          (click)="onRenameWorkflow(currentWorkflow.name)">
          <mat-icon color="primary" style="color: #FFF">check</mat-icon>
        </button>
<!--      </form>-->
<!--      </div>-->
    </div>
    <span class="example-spacer"></span>


<!--    <mat-form-field>-->
<!--      <mat-select class="demo-container__header__select-flow" [formControl]="selectedWorkflow">-->
<!--        <ng-container *ngIf="workflows; let flows">-->
<!--          <mat-option *ngFor="let flow of flows" [value]="flow.uuid">-->
<!--            {{flow.name}}-->
<!--          </mat-option>-->
<!--        </ng-container>-->
<!--      </mat-select>-->
<!--      <h2>{{ selectedWorkflow }}</h2>-->
<!--      <fa-icon matSuffix class="demo-container__header__select-flow__icon" [icon]="faChevronDown"></fa-icon>-->
<!--    </mat-form-field>-->
  </div>
  <div class="demo-container__header__edit-buttons" style="width:178px;text-align: right">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon style="color:#FFF;">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">

      <button mat-menu-item (click)="onRemoveWorkflow()" >
        <mat-icon color="warn">remove_circle</mat-icon>
        <span>Verwijder workflow</span>
      </button>

    </mat-menu>

<!--    <button mat-button class="demo-container__header__edit-buttons__cancel-button">Annuleren</button>-->
  </div>
</mat-toolbar>
