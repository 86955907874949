import { Injectable } from '@angular/core';

import { GenerateUuidService } from '@workflow-admin/shared/utils';
import {
  Workflow,
  WorkflowRequest,
} from '@workflow-admin/shared/workflow/utils/workflow';


@Injectable({
  providedIn: 'root'
})
export class WorkflowEditService {

  constructor(
    private generateUuidService: GenerateUuidService,
  ) { }

  addWorkflow(workflows: Workflow[], newWorkflow: Workflow): Workflow[] {
    return workflows.concat(newWorkflow);
  }

  deleteWorkflow(id: string, workflows: Workflow []): Workflow[] {
    return workflows.filter(flow => flow.uuid !== id);
  }

  generateNewWorkflow(name?: string): WorkflowRequest {
    const workflowId = this.generateUuidService.generateUuid();

    return {
      uuid: workflowId,
      name: name || workflowId,
      steps: [],
    }
  }
}
