import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedUtilsModule } from '@workflow-admin/shared/utils';

import {WorkflowGroupsFacade} from "./+state/workflowGroups";
import { WorkflowGroupsEffects } from './+state/workflowGroups/workflowGroups.effects';
import * as fromWorkflowGroups from './+state/workflowGroups/workflowGroups.reducer';
import { WorkflowsFacade } from './+state/workflows';
import { WorkflowsEffects } from './+state/workflows/workflows.effects';
import * as fromWorkflows from './+state/workflows/workflows.reducer';
import {
  WorkflowApiService,
  WorkflowEditService, WorkflowGroupApiService, WorkflowGroupEditService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromWorkflows.WORKFLOWS_FEATURE_KEY,
      fromWorkflows.reducer,

    ),
    StoreModule.forFeature(

      fromWorkflowGroups.WORKFLOW_GROUPS_FEATURE_KEY,
      fromWorkflowGroups.reducer
    ),
    EffectsModule.forFeature([WorkflowsEffects]),
    EffectsModule.forFeature([WorkflowGroupsEffects]),
    SharedUtilsModule
  ],
  providers: [
    WorkflowApiService,
    WorkflowEditService,
    WorkflowsFacade,
    WorkflowGroupEditService,
    WorkflowGroupApiService,
    WorkflowGroupsFacade,
  ],
})
export class SharedWorkflowDataAccessWorkflowModule {}
