import { Component, Input, OnInit } from '@angular/core';

import { FlowChartNode } from '@workflow-admin/shared/flow-chart/utils/flow-chart';

@Component({
  selector: 'workflow-admin-starter-component',
  templateUrl: './starter.component.html',
  styleUrls: ['./starter.component.scss']
})
export class StarterComponent implements OnInit {
  @Input() starter: FlowChartNode;

  constructor() { }

  ngOnInit(): void {
  }

}
