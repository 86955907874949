<form [formGroup]="conditionForm" class="edit-condition">
  <mat-form-field appearance="fill" class="edit-condition__condition">
    <mat-select value="option" formControlName="type" placeholder="Type">
      <mat-option  *ngFor="let inputType of inputTypes" [value]="inputType">{{ inputType | inputTypePipe }}</mat-option>
    </mat-select>
    <fa-icon [icon]="faChevronDown" matSuffix class="edit-condition__chevron-down"></fa-icon>
  </mat-form-field>

  <mat-form-field appearance="fill" class="edit-condition__condition">
    <mat-select formControlName="input" placeholder="Input">
      <ng-container *ngIf="selectedInputType">
        <ng-container [ngSwitch]="selectedInputType">
          <ng-container *ngSwitchCase="inputType.Workflow">
            <mat-option *ngFor="let workflow of getInputs(inputType.Workflow) | async" [value]="workflow['@id']">
              {{ workflow.name }}
            </mat-option>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-option *ngFor="let step of getInputs(selectedInputType) | async" [value]="step.input">
              {{ step.name }}
            </mat-option>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-select>
    <fa-icon [icon]="faChevronDown" matSuffix class="edit-condition__chevron-down"></fa-icon>
  </mat-form-field>

  <mat-form-field appearance="fill" class="edit-condition__condition" *ngIf="selectedInputType === inputType.QuestionSet">
    <mat-select formControlName="property" placeholder="Kies vraag">
      <mat-option *ngFor="let question of getQuestionSetQuestions() | async" [value]="question.property">
        {{ question?.label?.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="edit-condition__condition">
    <mat-select formControlName="operator" placeholder="Operator">
      <mat-option *ngFor="let operator of selectedOperators | async" [value]="operator">
        {{ operator | operatorPipe }}
      </mat-option>
    </mat-select>
    <fa-icon [icon]="faChevronDown" matSuffix class="edit-condition__chevron-down"></fa-icon>
  </mat-form-field>

  <ng-container *ngIf="showAnswers" >
    <ng-container *ngIf="getSelectedQuestion() | async; let question">
      <ng-container *ngIf="isClosedOptionsQuestion(question) && question.valueOptions.length; else openAnswerInput">
        <mat-form-field appearance="fill" class="edit-condition__condition">
          <mat-select formControlName="value" placeholder="Antwoord">
            <mat-option *ngFor="let valueOption of question.valueOptions" [value]="valueOption">
              {{ valueOption }}
            </mat-option>
          </mat-select>
          <fa-icon [icon]="faChevronDown" matSuffix class="edit-condition__chevron-down"></fa-icon>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #openAnswerInput>
    <mat-form-field appearance="fill" class="edit-condition__condition">
      <input matInput formControlName="value" placeholder="Antwoord">
    </mat-form-field>

  </ng-template>

</form>

