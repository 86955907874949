import { Component, Input, OnInit } from '@angular/core';
import { Edge } from '@swimlane/ngx-graph';

@Component({
  selector: 'workflow-admin-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {
  @Input() condition: Edge;

  constructor() { }

  ngOnInit(): void {
  }

}
