import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {faBars, faChevronRight, faClone} from '@fortawesome/pro-light-svg-icons';

import {ListItem, Workflow} from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-group-workflow-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class WorkflowListComponent {
  @Input() items: Workflow[];
  @Output() positionChanged = new EventEmitter<Workflow[]>();
  @Output() itemSelected = new EventEmitter<Workflow>();
  @Output() itemDuplicate = new EventEmitter<Workflow>();

  public faBars = faBars;
  public faChevronRight = faChevronRight;
  public faClone = faClone;

  itemMoved(event: CdkDragDrop<ListItem[]>) {
    console.log(event);

    const sortedList = event.container.getSortedItems();
    this.moveItem(event, sortedList);

    const updatedItems = this.items.map(item => {
      const newIndex = sortedList.findIndex(listItem =>  listItem.element.nativeElement.id === item.uuid)
      return {
        ...item,
        position: newIndex
      }
    });
// console.log(updatedItems,updatedItems);
    return this.positionChanged.emit(updatedItems);
  }

  editItem(item: Workflow) {
    this.itemSelected.emit(item);
  }
  duplicateItem(event, item: Workflow) {
    this.itemDuplicate.emit(item);
    event.stopPropagation();
  }

  private moveItem(event: CdkDragDrop<ListItem[]>, list: CdkDrag[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }
}
