import { Pipe, PipeTransform } from '@angular/core';

import { operatorLabel } from '../constants/operator-label.constant';

@Pipe({
  name: 'operatorPipe'
})
export class OperatorPipe implements PipeTransform {

  transform(operator: string): string {
    return operatorLabel[operator];
  }

}
