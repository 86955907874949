import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {faBars, faChevronRight, faClone} from '@fortawesome/pro-light-svg-icons';

import { ListItem } from '@workflow-admin/shared/workflow/utils/workflow';

@Component({
  selector: 'workflow-admin-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent {
  @Input() items: ListItem[];
  @Output() positionChanged = new EventEmitter<ListItem[]>();
  @Output() itemSelected = new EventEmitter<ListItem>();
  @Output() itemDuplicate = new EventEmitter<ListItem>();

  public faBars = faBars;
  public faChevronRight = faChevronRight;
  public faClone = faClone;

  itemMoved(event: CdkDragDrop<ListItem[]>) {
    const sortedList = event.container.getSortedItems();
    this.moveItem(event, sortedList);

    const updatedItems = this.items.map(item => {
      const newIndex = sortedList.findIndex(listItem =>  listItem.element.nativeElement.id === item.uuid)
      return {
        ...item,
        position: newIndex
      }
    });

    return this.positionChanged.emit(updatedItems);
  }

  editItem(item: ListItem) {
    this.itemSelected.emit(item);
  }
  duplicateItem(event, item: ListItem) {
    this.itemDuplicate.emit(item);
    event.stopPropagation();
  }

  private moveItem(event: CdkDragDrop<ListItem[]>, list: CdkDrag[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }
}
