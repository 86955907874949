import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { WorkflowAction } from '@workflow-admin/shared/workflow/utils/workflow';

import * as ActionsActions from './actions.actions';

export const ACTIONS_FEATURE_KEY = 'workflow_actions';

export interface State extends EntityState<WorkflowAction> {
   selectedId?: string | number; // which Actions record has been selected
  loaded: boolean; // has the Actions list been loaded
  error?: string | null; // last known error (if any)
}

export interface ActionsPartialState {
  readonly [ACTIONS_FEATURE_KEY]: State;
}

// TODO: consider using @id here instead of uuid

export const actionsAdapter: EntityAdapter<WorkflowAction> = createEntityAdapter<
  WorkflowAction
>({selectId: action => action.uuid});

export const initialState: State = actionsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const actionsReducer = createReducer(
  initialState,
  on(ActionsActions.loadActions, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ActionsActions.loadActionsSuccess, (state, { actions }) =>
    actionsAdapter.setAll(actions, { ...state, loaded: true })
  ),
  on(ActionsActions.loadActionsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(ActionsActions.createWorkflowAction, (state, {action}) =>
    actionsAdapter.addOne(action, state)
  ),
  on(ActionsActions.createWorkflowActionSuccess, (state, {action}) =>
    actionsAdapter.setOne(action, state)
  ),
  on(ActionsActions.editAction, ActionsActions.editActionSuccess, (state, {action}) => {
    const update: Update<WorkflowAction> = {
      id: action.uuid,
      changes: action
    }
    return actionsAdapter.updateOne(update, state)
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return actionsReducer(state, action);
}
