import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GenerateUuidService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [GenerateUuidService]
})
export class SharedUtilsModule {}
