import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import {MatMenuModule} from "@angular/material/menu";
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxGraphModule} from '@swimlane/ngx-graph';

import { SharedFlowChartDataAccessFlowChartModule } from '@workflow-admin/shared/flow-chart/data-access/flow-chart';
import {
  SharedFlowChartUiFlowChartModule
} from '@workflow-admin/shared/flow-chart/ui/flow-chart';
import { SharedFlowChartUtilsFlowChartModule } from '@workflow-admin/shared/flow-chart/utils/flow-chart';
import { SharedInputDataAccessInputModule } from '@workflow-admin/shared/input/data-access/input';

import { EditConditionSetContainerComponent } from './containers/edit-condition-set/edit-condition-set-container.component';
import { EditConditionContainerComponent } from './containers/edit-condition/edit-condition-container.component';
import { EditDecisionContainerComponent } from './containers/edit-decision/edit-decision-container.component';
import { EditStepDialogContainerComponent } from './containers/edit-step-dialog-container/edit-step-dialog-container.component';
import { FlowChartContainerComponent } from './containers/flow-chart-container/flow-chart-container.component';
import { FlowChartHeaderComponent } from './containers/flow-chart-header/flow-chart-header.component';
import { EdgesPipe, MockEdgesPipe, MockNodesPipe, NodesPipe } from './pipes';
import { EditStepDialogService } from './services';
import { SharedFlowChartFeatureFlowChartMaterialModule } from './shared-flow-chart-feature-flow-chart-material.module';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatDialogModule,
        NgxGraphModule,
        ReactiveFormsModule,
        RouterModule,
        SharedFlowChartFeatureFlowChartMaterialModule,
        SharedFlowChartUtilsFlowChartModule,
        SharedFlowChartUiFlowChartModule,
        SharedFlowChartDataAccessFlowChartModule,
        SharedInputDataAccessInputModule,
        FormsModule,
        MatMenuModule
    ],
  declarations: [ FlowChartContainerComponent, FlowChartHeaderComponent, EdgesPipe, NodesPipe, MockEdgesPipe, MockNodesPipe, EditStepDialogContainerComponent, EditDecisionContainerComponent, EditConditionSetContainerComponent, EditConditionContainerComponent ],
  exports: [ FlowChartContainerComponent, FlowChartHeaderComponent, EdgesPipe, NodesPipe, MockEdgesPipe, MockNodesPipe, EditStepDialogContainerComponent, EditDecisionContainerComponent ],
  providers: [EditStepDialogService],
  entryComponents: [EditStepDialogContainerComponent]
})
export class SharedFlowChartFeatureFlowChartModule {}
