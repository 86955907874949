import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {Field, WorkflowAction, WorkflowForm} from '@workflow-admin/shared/workflow/utils/workflow';

import {
  formsAdapter,
  FormsPartialState,
  FORMS_FEATURE_KEY,
  State,
} from './forms.reducer';

export const getFormsState = createFeatureSelector<FormsPartialState, State>(
  FORMS_FEATURE_KEY
);

const { selectAll, selectEntities } = formsAdapter.getSelectors();

export const getFormsLoaded = createSelector(
  getFormsState,
  (state: State) => state.loaded
);

export const getFormsError = createSelector(
  getFormsState,
  (state: State) => state.error
);

export const getAllForms = createSelector(getFormsState, (state: State) =>
  selectAll(state)
);

export const getFormsEntities = createSelector(getFormsState, (state: State) =>
  selectEntities(state)
);

export const getFormById = createSelector(
  getFormsEntities,
  (entities, id) => id && entities[id]
)

export const getSelectedFormId = createSelector(
  getFormsState,
  (state: State) => state.selectedId
);

export const getSelectedForm = createSelector(
  getFormsEntities,
  getSelectedFormId,
  (entities, selectedId) => selectedId && entities[selectedId]
);



export const getSelectedFormQuestions = createSelector(
  getSelectedForm,
  (form: WorkflowForm) => form && form.fields
)
// export const getSelectedAction = createSelector(
//   getSelectedAction,
//   (action: WorkflowAction) => form && form.fields
// )

export const getAllQuestions = createSelector(
  getFormsEntities,
  (forms: Dictionary<WorkflowForm>) => {
    const questions: Field[] = [];
    Object.keys(forms).forEach(key => {
      if(forms[key].fields?.length) {
        forms[key].fields.forEach(field => {
          questions.push({ ...field, property: `${forms[key]['@id']}.${field.uuid}`})
        })
      }
    });
    return questions;
  }
)

export const getSelectedQuestion = createSelector(
  getAllQuestions,
  (questions, selectedQuestionProperty) => {
    return questions.find(question => question.property === selectedQuestionProperty);
  }
)

export const getFormQuestions = createSelector(
  getFormById,
  (form: WorkflowForm) => {
    return form.fields?.map(field => ({
      ...field,
        property: `${form['@id']}.${field.uuid}`
    }))
  }
)


// export const getFormById = createSelector(
//   getFormsEntities,
//   (entities, id) => id && entities[id]
// )
//
// export const getFormQuestions = createSelector(
//   getFormById,
//   (form) => form && form.questions
// )

