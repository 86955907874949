import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { handleUndo} from 'ngrx-undo';

import { DemoFeatureDemoModule } from '@workflow-admin/demo/feature/demo';
import { SharedDataAccessModule } from '@workflow-admin/shared/data-access';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers } from './app.states';
import { AuthEffects} from "./auth/auth.effects";
import { LogInComponent } from './components/log-in/log-in.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { AuthService } from "./services/auth.service";
import {ErrorInterceptor, TokenInterceptor} from "./services/token.interceptor";

@NgModule({
  declarations: [AppComponent, LogInComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    DemoFeatureDemoModule,
    AppRoutingModule,
    HttpClientModule,
    // StoreModule.forRoot(reducers, {}),
    StoreModule.forRoot(
      reducers,
      {
        metaReducers: [handleUndo],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([AuthEffects]),
    // EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedDataAccessModule.forRoot({environment}),
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
  ],
  providers: [ AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
})
export class AppModule {}
