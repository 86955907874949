import { Pipe, PipeTransform } from '@angular/core';

import { stepTypeLabel } from '../constants/step-type-labels.constant';

@Pipe({
  name: 'stepTypePipe'
})
export class StepTypePipe implements PipeTransform {

  transform(stepType: string): string {
    return stepTypeLabel[stepType] || "stap"
  }

}
