<div mat-dialog-title>
  <div class="edit-step-dialog__header">
    <h1 *ngIf="showTabGroup$ | async; else backButton" class="edit-step-dialog__title">
      Bewerken
<!--      {{selectedTabIndex === 0 ? 'Bewerken' : (this.stepEditForm.get('type').value | stepTypePipe)  }}-->
    </h1>
    <ng-template #backButton>
      <button mat-button (click)="returnToTabGroup()">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        {{backButtonText}}
      </button>
    </ng-template>
    <div>
      <button
        mat-button
        class="edit-step-dialog__header-button"
        [autofocus]="false" >
        <fa-icon class="edit-step-dialog__header-icon" [icon]="faSearch"></fa-icon>
      </button>
      <button
        mat-button
        class="edit-step-dialog__header-button"
        [autofocus]="false"
        (click)="deleteItem()"
        matTooltip="{{tooltipDeleteText}}">
        <fa-icon class="edit-step-dialog__header-icon" [icon]="faTrashAlt"></fa-icon>
      </button>
      <button
        mat-button
        class="edit-step-dialog__header-button"
        [autofocus]="true"
        mat-dialog-close>
        <fa-icon class="edit-step-dialog__header-icon" [icon]="faClose"></fa-icon>
      </button>
    </div>
  </div>

<!--  <h2 class="edit-step-dialog__title" *ngIf="selectedTabIndex=== 0"> {{stepEditForm.get('name').value }}</h2>-->
</div>


<mat-dialog-content class="edit-step-dialog__content">
  <form [formGroup]="stepEditForm">
    <mat-tab-group *ngIf="(showTabGroup$ | async)" [selectedIndex]="selectedTabIndex">

      <mat-tab label="Algemeen">
        <div class="edit-step-general">
          <mat-form-field appearance="fill" class="edit-step-general__input">
            <mat-label>Type stap</mat-label>
            <mat-select value="option" formControlName="type">
              <mat-option  *ngFor="let type of stepTypes" [value]="type">{{ type | stepTypePipe }}</mat-option>
            </mat-select>
            <fa-icon [icon]="faChevronDown" matSuffix class="edit-step-general__type-chevron"></fa-icon>
          </mat-form-field>
          <mat-form-field appearance="fill" class="edit-step-general__input edit-step-general__name-input">
            <mat-label>Benaming</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field appearance="fill" class="edit-step-general__input" *ngIf="stepEditForm.get('type').value === stepType.Info" style="margin-top:20px">
            <mat-label>Info</mat-label>
            <textarea matInput matTextareaAutosize formControlName="info"></textarea>
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab label="Vraag" *ngIf="this.showAnswersTab$.value">
        <workflow-admin-edit-question
          [question]="questions?.length ? questions[0] : undefined"
          [isQuestionSet]="false"
          (questionChanged)="onQuestionChanged($event)"></workflow-admin-edit-question>
      </mat-tab>


      <mat-tab label="Vragen" *ngIf="stepEditForm.get('type').value === stepType.QuestionSet">
        <workflow-admin-list [items]="questions" (positionChanged)="onQuestionMoved($event)" (itemSelected)="onQuestionSelected($event)" (itemDuplicate)="onQuestionDuplicate($event)"></workflow-admin-list>
        <div class="edit-step-dialog__add-step-container--buttons">
          <button
            mat-flat-button
            class="edit-step-dialog__button"
            (click)="onAddQuestion()"
            color="primary">
            Vraag toevoegen
          </button>
        </div>
      </mat-tab>

      <mat-tab label="Actie" *ngIf="stepEditForm.get('type').value === stepType.Action">
        <workflow-admin-edit-action
          [action]="workflowAction"
          [isQuestionSet]="false"
          (actionChanged)="onActionChanged($event)"></workflow-admin-edit-action>
      </mat-tab>

      <mat-tab label="Opties" *ngIf="stepEditForm.get('type').value === stepType.Decision" >
        <workflow-admin-list [items]="decisions" (itemSelected)="onOptionSelected($event)" (positionChanged)="onOptionMoved($event)" (itemDuplicate)="onDuplicateItem($event)"></workflow-admin-list>

        <div class="edit-step-dialog__add-step-container--buttons">
          <button
            mat-flat-button
            class="edit-step-dialog__button"
            (click)="addNewOption()"
            color="primary">
            Optie toevoegen
          </button>
        </div>

      </mat-tab>

      <mat-tab label="Volgende stap" *ngIf="stepEditForm.get('type').value !== stepType.Decision" >
        <div *ngIf="this.showNextStep$ | async" class="edit-step-dialog__add-step-container">
          <ng-container *ngIf="!(this.addingNewNextStep$ | async); else showAddNewNextStep">
            <div class="edit-step-label">
              Volgende stap
            </div>
            <div class="edit-step-dialog__add-existing-step">
              <mat-form-field appearance="fill" class="add-related-step__input">
                <fa-icon [icon]="faSearch" matPrefix class="add-related-step__search-icon"></fa-icon>
                <mat-select formControlName="nextStepUuid" placeholder="Kies">
<!--                  <mat-option *ngFor="let step of this.stepsFacade.selectedWorkflowSteps$ | async" [value]="step.uuid">-->
                  <mat-option  *ngFor="let stepSorted of this.sortedSteps " [value]="stepSorted.step.uuid" [class.optionHasItems]="stepSorted.position > 0" [class.optionDisabled]="stepSorted.step.uuid == stepEditForm.get('uuid').value" [disabled]="stepSorted.step.uuid == stepEditForm.get('uuid').value">
                    {{stepSorted.step.name || stepSorted.step.uuid}}  <span class="tag" [class]=" stepSorted.step.type">{{ stepSorted.step.type }}</span>
                  </mat-option>
                </mat-select>
                <fa-icon [icon]="faChevronDown" matSuffix class="add-related-step__chevron"></fa-icon>
              </mat-form-field>
              <button mat-button (click)="removeLinkToNextStep()">
                <fa-icon class="remove-related-step__icon" [icon]="faTrashAlt"></fa-icon>
              </button>
            </div>
            <button
              class="edit-step__add-new-next-step-button"
              mat-stroked-button
              color="primary"
              (click)="addNewNextStep()">
              + Nieuwe stap toevoegen
            </button>
          </ng-container>

          <ng-template #showAddNewNextStep>
            <div class="edit-step__add-new-next-step-container">
              <mat-form-field appearance="fill">
                <input matInput placeholder="Nieuwe naam" [(ngModel)]="newNextStepName" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
              <div class="edit-step__add-new-next-step-buttons">
                <button
                  class="edit-step__add-new-next-step-buttons-cancel"
                  mat-stroked-button
                  color="primary"
                  (click)="cancelCreateNewNextStep()">
                  Annuleren
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  (click)="createNewNextStep()">
                  Toevoegen
                </button>
              </div>
            </div>
          </ng-template>

        </div>
        <ng-container *ngIf="!(this.showNextStep$ | async)" >
          <div class="edit-step-dialog__add-step-container--buttons">
            <button
              mat-flat-button
              class="edit-step-dialog__button"
              (click)="addLinkToNextStep()"
              color="primary">
              Volgende stap toevoegen
            </button>
          </div>
        </ng-container>
      </mat-tab>


    </mat-tab-group>
  </form>

  <ng-container *ngIf="showOptionDetails$ | async">
    <workflow-admin-edit-decision
      [steps]="this.stepsFacade.selectedWorkflowSteps$ | async"
      [currentStepUuid]="stepEditForm.controls.uuid.value"
      [sortedSteps]="this.sortedSteps"
      [decision]="selectedOption$.value"
      (decisionChanged)="onDecisionChanged($event)"
      (addingNewStep)="onAddingNewStep($event)"
      (formValidation)="onFormValidation($event)">
    </workflow-admin-edit-decision>
  </ng-container>

  <ng-container *ngIf="showQuestionDetails$ | async">
    <workflow-admin-edit-question
      [question]="selectedQuestion$.value"
      [isQuestionSet]="true"
      (questionChanged)="onQuestionChanged($event)"></workflow-admin-edit-question>
  </ng-container>
</mat-dialog-content>

<div mat-dialog-actions class="edit-step-dialog__action-container" ><!--    [disabled]="(formValidated$ | async) && (addingNewNextStep$ | async)"-->
  <button
    mat-flat-button
    color="primary"
    class="edit-step-dialog__button"


  (click)="editItem()"><!--  [disabled]="(formValidated$ | async)==false"-->
    Aanpassen
  </button>
</div>

