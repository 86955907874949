import { Operators} from '@workflow-admin/shared/workflow/utils/workflow';

export const operatorLabel = {
  [Operators.Equal]: 'Gelijk aan',
  [Operators.NotEqual]: 'Niet gelijk aan',
  [Operators.GreaterThan]: 'Groter dan',
  [Operators.LessThan]: 'Kleiner dan',
  [Operators.GreaterThanOrEqual]: 'Groter dan of gelijk aan',
  [Operators.LessThanOrEqual]: 'Kleiner dan of gelijk aan',
  [Operators.Contains]: 'Bevat',
  [Operators.NotContains]: 'Bevat niet',
  [Operators.IsSet]: 'Is bepaald',
  [Operators.IsNotSet]: 'Is niet bepaald',
  [Operators.IsValid]: 'Is geldig'
}
