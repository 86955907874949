import { createAction, props } from '@ngrx/store';

import {
  CreateWorkflowStepRequest,
  Decision, DecisionConditionSet,
  DecisionRequest,
  EditWorkflowStepRequest,
  WorkflowStep, WorkflowStepFull
} from '@workflow-admin/shared/workflow/utils/workflow';

export const loadStepsSuccess = createAction(
  '[Selected Workflow => Steps] Load Steps Success',
  props<{ steps: WorkflowStepFull[] }>()
);

export const loadStepsFailure = createAction(
  '[Selected Workflow => Steps] Load Steps Failure',
  props<{ error: any }>()
);

export const selectStep = createAction(
  '[Selected Workflow => Steps] Select Step',
  props<{stepUuid: string}>()
);

export const clearSelectedStep = createAction(  '[Selected Workflow => Steps] Clear Selected Step');

export const clearSteps = createAction('[Selected Workflow => Steps] Clear Steps')

export const createStep = createAction(
  '[Selected Workflow => Steps] Create Step',
  props<{step: CreateWorkflowStepRequest}>()
)

export const createStepSuccess = createAction(
  '[Selected Workflow => Steps] Create Step Success',
  props<{ step: WorkflowStep}>()
)

export const addStepAndUpdateParent = createAction(
  '[Selected Workflow => Steps] Add Step',
  props<{
    step: CreateWorkflowStepRequest,
    newParentStepDecision: DecisionRequest}>()
)

export const addStepFailure = createAction(
  '[Selected Workflow => Steps] Add Step Failure',
  props<{ error: any }>()
)

export const getStep = createAction(
  '[Selected Workflow => Steps] Get Step',
  props<{stepId: string}>()
)

export const getStepSuccess = createAction(
  '[Selected Workflow => Steps] Get Step Success',
  props<{step: WorkflowStep}>()
)

export const getStepFailure = createAction(
  '[Selected Workflow => Steps] Get Step Failure',
  props<{ error: any }>()
)

export const editStep = createAction(
  '[Selected Workflow => Steps] Edit Step',
  props<{ id: string, request: EditWorkflowStepRequest}>()
)

export const editStepSuccess = createAction(
  '[Selected Workflow => Steps] Edit Step Success',
  props<{ step: WorkflowStep}>()
)

export const addStepInBetween = createAction(
  '[Selected Workflow => Steps] Add Step In Between',
  props<{
    step: CreateWorkflowStepRequest,
    parentStepId: string,
    oldDecisionId: string,
    newParentStepDecision: DecisionRequest,
    newStepDecision: Decision}>()
)

export const addStepInBetweenSuccess = createAction(
  '[Selected Workflow => Steps] Add Step In Between Success',
  props<{ step: WorkflowStep}>()
)

export const deleteStep = createAction(
  '[Selected Workflow => Steps] Delete Step',
  props<{id: string}>()
)

export const deleteStepSuccess = createAction(
  '[Selected Workflow => Steps] Delete Step Success',
  props<{id: string}>()
)

export const deleteStepFailure = createAction(
  '[Selected Workflow => Steps] Delete Step Failure',
  props<{ error: any }>()
)

export const createDecision = createAction(
  '[Selected Workflow => Steps] Create Decision',
  props<{ decision: DecisionRequest, stepId: string}>()
)

export const createDecisionSuccess = createAction(
  '[Selected Workflow => Steps] Create Decision Success',
  props<{stepId: string}>()
)

export const createDecisionFailure = createAction(
  '[Selected Workflow => Steps] Create Decision Failure',
  props<{error: any}>()
)

export const deleteDecision = createAction(
  '[Selected Workflow => Steps] Delete Decision',
  props<{stepId: string, decisionId: string}>()
)

export const deleteDecisionSuccess = createAction(
  '[Selected Workflow => Steps] Delete Decision Success',
  props<{stepId: string, decisionId: string}>()
)
export const updateDecisionNextStep = createAction(
  '[Selected Workflow => Steps] Update Decision next step',
  props<{nextStepUuid: string, decision: Decision}>()
)

export const updateDecisions = createAction(
  '[Selected Workflow => Steps] Update Decisions',
  props<{stepId: string, currentDecisions: Decision[], dialogDecisions: Decision[]}>()
)

export const updateDecisionsSuccess = createAction(
  '[Selected Workflow => Steps] Update Decisions Success',
  props<{stepId: string}>()
)

export const updateDecisionConditionSets = createAction(
  '[Selected Workflow => Steps] Update Decision Condition Sets',
  props<{stepId: string, currentDecisions: Decision[], dialogDecisions: Decision[]}>()
)

export const updateDecisionConditionSetsSuccess = createAction(
  '[Selected Workflow => Steps] Update Decision Condition Sets Success',
  props<{stepId: string}>()
)

export const updateDecisionConditions = createAction(
  '[Selected Workflow => Steps] Update Decision Conditions',
  props<{stepId: string, currentConditionSets: DecisionConditionSet[], dialogConditionSets: DecisionConditionSet[]}>()
)

export const updateDecisionConditionsSuccess = createAction(
  '[Selected Workflow => Steps] Update Decision Conditions Success',
  props<{stepId: string}>()
)


