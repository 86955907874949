import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  actionsAdapter,
  ActionsPartialState,
  ACTIONS_FEATURE_KEY,
  State,
} from './actions.reducer';

// Lookup the 'Actions' feature state managed by NgRx
export const getActionsState = createFeatureSelector<
  ActionsPartialState,
  State
>(ACTIONS_FEATURE_KEY);

const { selectAll, selectEntities } = actionsAdapter.getSelectors();

export const getActionsLoaded = createSelector(
  getActionsState,
  (state: State) => state.loaded
);

export const getActionsError = createSelector(
  getActionsState,
  (state: State) => state.error
);

export const getAllActions = createSelector(getActionsState, (state: State) =>
  selectAll(state)
);

export const getActionsEntities = createSelector(
  getActionsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getActionsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getActionsEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getActionById = createSelector(
  getActionsEntities,
  (entities, id: string) => id && entities[id]
)
