<mat-toolbar class="login-container__header"></mat-toolbar>
<div class="login-container">
<mat-card class="login-card">
  <mat-card-content>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <h2>Log In</h2>
      <mat-error *ngIf="this.errorMessage">
        {{ this.errorMessage }}
      </mat-error>
      <mat-form-field>
        <input matInput placeholder="Email" [(ngModel)]="user.email" name="username" required>
        <mat-error>
          Voer een geldig emailadres in
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" placeholder="Password" [(ngModel)]="user.password" name="password" required>
        <mat-error>
          Voer een geldig wachtwoord in
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid">Login</button>
    </form>
  </mat-card-content>
</mat-card>
</div>
<!--<div class="row">-->
<!--  <div class="col-md-4">-->
<!--    <h1>Log in</h1>-->
<!--    <hr><br>-->
<!--    <form (ngSubmit)="onSubmit()" ngNativeValidate>-->
<!--      <div class="form-group">-->
<!--        <label for="email">Email</label>-->
<!--        <input-->
<!--          [(ngModel)]="user.email"-->
<!--          name="email"-->
<!--          type="email"-->
<!--          required-->
<!--          class="form-control"-->
<!--          id="email"-->
<!--          placeholder="enter your email">-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <label for="password">Password</label>-->
<!--        <input-->
<!--          [(ngModel)]="user.password"-->
<!--          name="password"-->
<!--          type="password"-->
<!--          required-->
<!--          class="form-control"-->
<!--          id="password"-->
<!--          placeholder="enter a password">-->
<!--      </div>-->
<!--      <button type="submit" class="btn btn-primary">Submit</button>-->
<!--      <a [routerLink]="['/']" class="btn btn-success">Cancel</a>-->
<!--    </form>-->
<!--    <p>-->
<!--      <span>Don't have an account?&nbsp;</span>-->
<!--      <a [routerLink]="['/sign-up']">Sign up!</a>-->
<!--    </p>-->
<!--  </div>-->
<!--</div>-->
