<div class="step" id="step{{nodeStep.id}}" [ngClass]="[nodeStep.type === stepType.Action ? 'actionairy' : nodeStep.type === stepType.Info ? 'tertairy' : nodeStep.type === stepType.Decision ? 'secondary' : 'primary', nodeStep.isSelected ? 'isActive' : '']">
  <div class="step__header">
    <div class="step__header__text" *ngIf="nodeStep.type; else placeholderType">
      {{nodeStep.type | stepTypePipe}}
      <fa-icon class="step__header__icon" *ngIf="nodeStep.type === stepType.QuestionSet" [icon]="layerIcon"></fa-icon>
    </div>
    <ng-template #placeholderType>
      <div class="step__header__text placeholder">Type stap</div>
    </ng-template>
    <fa-icon class="step__header__icon right" *ngIf="![stepType.Decision, stepType.Action].includes(nodeStep.type)" [icon]="eyeIcon" matTooltip="Deze {{nodeStep.type | stepTypePipe | lowercase}} zie je als pagina terug in de app"></fa-icon>
  </div>
  <div class="step__body">
    <div class="step__body__text" *ngIf="nodeStep.name; else placeholderText">{{nodeStep.name}}</div>
      <ng-template #placeholderText>
        <div class="step__body__text placeholder">Benaming</div>
      </ng-template>
    <div class="step__add-step-button--container">
      <div class="step__add-step-button" (click)="onAddNextStep($event)">
        <fa-icon [icon]="plusIcon"></fa-icon>
      </div>
    </div>
  </div>
</div>
