import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { WorkflowForm } from '@workflow-admin/shared/workflow/utils/workflow';

import { loadFormsForWorkflowSuccess } from './forms.actions';
import * as FormsActions from './forms.actions';

export const FORMS_FEATURE_KEY = 'forms';

export interface State extends EntityState<WorkflowForm> {
  loaded: boolean; // has the Forms list been loaded
  error?: string | null; // last known error (if any)
  selectedId?: string; // which Forms record has been selected
}

export interface FormsPartialState {
  readonly [FORMS_FEATURE_KEY]: State;
}

// TODO: consider using @id here instead of uuid
export const formsAdapter: EntityAdapter<WorkflowForm> = createEntityAdapter<
  WorkflowForm
>({selectId: form => form.uuid});

export const initialState: State = formsAdapter.getInitialState({
  loaded: false,
  error: null,
});

const formsReducer = createReducer(
  initialState,
  on(FormsActions.loadAllForms, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(FormsActions.loadAllFormsSuccess, loadFormsForWorkflowSuccess, (state, { forms }) =>
    formsAdapter.setAll(forms, { ...state, loaded: true, })
  ),
  on(FormsActions.getFormSuccess, (state, { form }) =>
    formsAdapter.setOne(form, state)
  ),
  on(FormsActions.loadAllFormsFailure, FormsActions.getFormFailure,(state, { error }) => ({ ...state, error })),
  on(FormsActions.selectForm, (state, {formUuid}) => {
    return {...state, selectedId: formUuid}
  }),
  on(FormsActions.clearSelectedForm, (state) => ({
    ...state,
    selectedId: null,
  })),
  on(FormsActions.createForm, (state, {form}) =>
    formsAdapter.addOne({ ...form, fields: [] }, state)
  ),
  on(FormsActions.createFormSuccess, (state, {form}) =>
    formsAdapter.setOne(form, state)
  ),
  on(FormsActions.editForm, FormsActions.editFormSuccess, (state, {form}) => {
    const update: Update<WorkflowForm> = {
      id: form.uuid,
      changes: form
    }
    return formsAdapter.updateOne(update, state)
  }),
  on(FormsActions.deleteForm, FormsActions.deleteFormSuccess, (state, {id}) =>
    formsAdapter.removeOne(id, state)
  ),
  on(FormsActions.createQuestions, (state, {questions, formId}) => {
    const update: Update<WorkflowForm> = {
      id: formId,
      changes: {fields: questions}
    }
    return formsAdapter.updateOne(update, state)
  }),
  on(FormsActions.editQuestions, (state, {updatedQuestions, form}) => {
    const update: Update<WorkflowForm> = {
      id: form.split("/").pop(),
      changes: {fields: updatedQuestions}
    }
    return formsAdapter.updateOne(update, state)
  })
);

export function reducer(state: State | undefined, action: Action) {
  return formsReducer(state, action);
}
