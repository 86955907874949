import { QuestionType } from '@workflow-admin/shared/workflow/utils/workflow';

export const questionTypeLabel = {
  [QuestionType.Text]: "Open (tekst)",
  [QuestionType.Integer]: "Geheel getal",
  [QuestionType.Float]: "Decimaal",
  [QuestionType.Radio]: "Radio (kies één)",
  [QuestionType.Boolean]: "Ja/nee",
  [QuestionType.Date]: "Datum",
  [QuestionType.Time]: "Tijd",
  [QuestionType.DateTime]: "Datum/tijd",
  [QuestionType.CheckboxSingle]: "Checkbox (kies één)",
  [QuestionType.CheckboxMultiple]: "Checkbox (kies één of meerdere)",
  [QuestionType.UploadFile]: "Bestand uploaden",
  [QuestionType.UploadImage]: "Foto uploaden",
  [QuestionType.Address]: "Adres",
  [QuestionType.Html]: "Html",
}
