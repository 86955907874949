<form [formGroup]="conditionSetForm" class="edit-condition-set">
  <div class="edit-condition-set-header">
    <span class="edit-condition-set-header__text">Toon deze optie als</span>
    <button
      mat-button
      class="edit-condition-set__remove-item-button"
      matTooltip="Verwijder deze conditie-set"
      (click)="removeConditionSet.emit()">
      <fa-icon class="edit-condition-set__button-icon" [icon]="faTrashAlt"></fa-icon>
    </button>
  </div>

  <div *ngFor="let condition of conditions; index as j" class="edit-condition-set-conditions">
<!--          Consider if this can be div instead of span -->
    <span *ngIf="j > 0" class="edit-condition-set__boolean">
      <span>En</span>
      <button
        mat-button
        matTooltip="Verwijder deze conditie"
        (click)="removeCondition(j)">
        <fa-icon class="edit-condition-set__button-icon" [icon]="faTrashAlt"></fa-icon>
      </button>
    </span>
    <workflow-admin-edit-condition [condition]="condition" (conditionChanged)="onConditionChanged($event, j)"></workflow-admin-edit-condition>
  </div>

  <button mat-stroked-button color="primary" class="edit-condition-set__add-condition-button small" (click)="addCondition()">+ En</button>
</form>
