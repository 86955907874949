import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import { faArrowRight, faFolder,faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {StepsFacade} from "@workflow-admin/shared/flow-chart/data-access/flow-chart";
import {EditStepDialogService} from "@workflow-admin/shared/flow-chart/feature/flow-chart";
import {WorkflowsFacade} from "@workflow-admin/shared/workflow/data-access/workflow";

import {ListComponent} from "../../../../../../../shared/flow-chart/ui/flow-chart/src/lib/components/list/list.component";
import {WorkflowGroupsFacade} from "../../../../../../../shared/workflow/data-access/workflow/src/lib/+state/workflowGroups";

// import {WorkflowGroupsFacade} from "@workflow-admin/shared/workflow/data-access/workflow/src/lib/+state/workflowGroups";
// import { WorkflowGroupsFacade } from '@workflow-admin/shared/workflow/data-access/workflow';


@Component({
  selector: 'workflow-admin-demo-workflow-groups-overview-container',
  templateUrl: './demo-workflow-groups-overview-container.component.html',
  styleUrls: ['./demo-workflow-groups-overview-container.component.scss']
})
export class DemoWorkflowGroupsOverviewContainerComponent implements OnInit, OnDestroy {


  public workflowGroups$ = this.workflowGroupsFacade.allWorkflowGroups$;

  public faArrowRight = faArrowRight;
  public faTrashAlt = faTrashAlt;
  public faFolder = faFolder;
  public faFolderOpen = faFolderOpen;

  public newWorkflowName = new FormControl('');
  public showWorkflowNameEdit = new BehaviorSubject(false);

  private unsubscribe$ = new Subject();

  constructor(
    private workflowsFacade: WorkflowsFacade,
    private workflowGroupsFacade: WorkflowGroupsFacade,
    private route: ActivatedRoute,
    private router: Router,
    private editStepDialogService: EditStepDialogService,
    private matDialog: MatDialog,
  ) {  }

  ngOnInit(): void {
    // console.log('AAA', this.workflowGroupsFacade.loaded$);
    this.workflowGroupsFacade.loaded$.pipe(
      filter(loaded => !loaded),
      takeUntil(this.unsubscribe$)
    ).subscribe(_ => this.workflowGroupsFacade.getAllWorkflowGroups())
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addWorkflowGroup(): void {
    this.showWorkflowNameEdit.next(true);
  }

  public createNewWorkflowGroup(): void {
    this.workflowGroupsFacade.addWorkflowGroup(this.newWorkflowName.value);
    this.showWorkflowNameEdit.next(false);
  }

  public cancelCreate(): void {
    this.showWorkflowNameEdit.next(false);
    this.newWorkflowName.reset();
  }

  public deleteWorkflowGroup(id: string): void {
    this.workflowGroupsFacade.deleteWorkflowGroup(id);
  }

  public itemSelected()
  {

  }


}
