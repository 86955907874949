import { createFeatureSelector, createSelector } from '@ngrx/store';

import {  StepType } from '@workflow-admin/shared/workflow/utils/workflow';

import {
  State,
  stepsAdapter,
  StepsPartialState,
  STEPS_FEATURE_KEY,
} from './steps.reducer';

// Lookup the 'Steps' feature state managed by NgRx
export const getStepsState = createFeatureSelector<StepsPartialState, State>(
  STEPS_FEATURE_KEY
);

const { selectAll, selectEntities } = stepsAdapter.getSelectors();

export const getStepsLoaded = createSelector(
  getStepsState,
  (state: State) => state.loaded
);

export const getStepsError = createSelector(
  getStepsState,
  (state: State) => state.error
);

export const getAllSteps = createSelector(getStepsState, (state: State) =>
  selectAll(state)
);

export const getStepsEntities = createSelector(getStepsState, (state: State) =>
  selectEntities(state)
);

export const getStepsByType = createSelector(
  getAllSteps,
  (steps, type: StepType) => steps.filter(step => step.type === type)
)

export const getSelectedId = createSelector(
  getStepsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getStepsEntities,
  getSelectedId,
  (entities, selectedId, state: State) => {
    console.log('getSelected',[entities,selectedId,state]);
    return entities[selectedId]; }
);

export const getStepById = createSelector(
  getStepsEntities,
  (entities, id) => id && entities[id]
)

export const getDecisionsForStep = createSelector(
  getStepsEntities,
  (steps, selectedStepId ) => steps[selectedStepId]?.decisions
)
