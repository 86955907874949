import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  State,
  workflowsAdapter,
  WorkflowsPartialState,
  WORKFLOWS_FEATURE_KEY,
} from './workflows.reducer';

// Lookup the 'Workflows' feature state managed by NgRx
export const getWorkflowsState = createFeatureSelector<
  WorkflowsPartialState,
  State
>(WORKFLOWS_FEATURE_KEY);

const { selectAll, selectEntities } = workflowsAdapter.getSelectors();

export const getWorkflowsLoaded = createSelector(
  getWorkflowsState,
  (state: State) => state.loaded
);

export const getWorkflowsError = createSelector(
  getWorkflowsState,
  (state: State) => state.error
);

export const getAllWorkflows = createSelector(
  getWorkflowsState,
  (state: State) => selectAll(state)
);

export const getWorkflowsEntities = createSelector(
  getWorkflowsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getWorkflowsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getWorkflowsEntities,
  getSelectedId,
  (entities, selectedId) => {
    // console.log(entities,selectedId);
    return selectedId && entities[selectedId];
  }
);

export const getSelectedWorkflowFirstStepUuid = createSelector(
  getSelected,
  (workflow) => workflow && workflow.firstStepUuid
)

