import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

import {AppState, selectAuthState} from "../../app.states";
import {LogIn} from "../../auth/auth.actions";
import { User } from '../../models/user';

@Component({
  selector: 'workflow-admin-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})



export class LogInComponent implements OnInit {
  loginValid = true;
  user: User = new User();
  getState: Observable<any>;
  errorMessage: string | null;

  constructor(
    private store: Store<AppState>
  ) {
    this.getState = this.store.select(selectAuthState);
  }

  ngOnInit() {
    this.getState.subscribe((state) => {

      this.errorMessage = state.errorMessage;
    });
  }

  onSubmit(): void {
    const payload = {
      email: this.user.email,
      password: this.user.password
    };
    this.store.dispatch(new LogIn(payload));
  }

}
